import React, { useContext } from 'react'
import styled from 'styled-components'
import ButtonLogout from '../components/ButtonLogout'

import ButtonFunction from '../components/ButtonFunction'
import { AuthContext } from "../contexts/AuthProvider";

import databaseIcon from '../images/databaseIcon.svg'
import infosIcon from '../images/InfosIcon.svg'
import userIcon from '../images/user.png'
import feedbackIcon from '../images/feedbackIcon.svg'
import eyeIcon from "../images/eyeIcon.svg"
import csIcon from '../images/csIcon.png'
import chamadoIcon from '../images/chamados.svg';
import ButtonURL from '../components/ButtonURL';
//import groupsIcon from '../images/groups.svg'

function Navbar(props) {

  const { currentUser } = useContext(AuthContext)
  const currentUserRole = process.env.REACT_APP_userRole || JSON.parse(currentUser?.reloadUserInfo?.customAttributes).role;

  return (
    <Container menuState={props.menuState}>
      {
        props.menuState ?
          (
            <>

              <Buttons>
                <ButtonFunction src={infosIcon} text={"Novidades"} linkTo={'/homeNews'} active={window.location.pathname === '/homeNews' ? true : false} />
                <ButtonFunction src={databaseIcon} text={"Extrações"} linkTo={'/homeExtractions'} active={window.location.pathname === '/homeExtractions' ? true : false} />
                {
                  currentUserRole === 'admin' ?
                    (
                      <ButtonFunction src={userIcon} text={"Usuários"} linkTo={'/userRoleController'} active={window.location.pathname === '/userRoleController' ? true : false} />
                    ) : (
                      <></>
                    )
                }
                <ButtonFunction src={feedbackIcon} text={"Feedback"} linkTo={'/feedback'} active={window.location.pathname === '/feedback' ? true : false} />
                <ButtonURL src={chamadoIcon} text={"Abrir Chamado"} linkTo={'https://forms.gle/7HEEajDrQ8JpEqgB7'} active={false} />
                {
                  currentUserRole === 'admin' ?
                    (
                      <ButtonFunction src={eyeIcon} text={"Controle"} linkTo={'/controller'} active={window.location.pathname === '/controller' ? true : false} />
                    ) : (
                      <></>
                    )
                }
                
                {
                  currentUserRole === 'manager' || currentUserRole === 'head' ?
                  (
                    <ButtonFunction src={csIcon} text={"Conformidades"} linkTo={'/cshelper'} active={window.location.pathname === '/cshelper' ? true : false} /> 
                  ) : (
                    <></>
                  )
                }

                <ButtonLogout title="Logout" />
              </Buttons>

            </>
          ) :
          (
            <></>
          )
      }
      <ButtonMenu onClick={() => { props.setMenuState(!props.menuState) }}>
        <Arrow className={props.menuState ? "left" : "right"} />
      </ButtonMenu>
    </Container>
  )
}

export default Navbar

const Container = styled.nav`
  width: ${props => props.menuState ? "20vw" : "50px"};
  height: 90vh;

  border-right: 1px solid #333333;

  margin: 5vh auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
`

const ButtonMenu = styled.div`
  width: 50px;
  height: 50px;

  position: absolute;
  bottom: 0;
  right: 0;

  display: flex;
  justify-content: space-around;
  align-items: center;

`

const Arrow = styled.div`
	position: absolute;
	width: 20px;
	height: 20px;
	background: transparent;
	border-top: 5px solid white;
	border-right: 5px solid white;
	box-shadow: 0 0 0 lightgray;
	transition: all 200ms ease;
	
	&.left {
		transform: translate3d(0,-50%,0) rotate(-135deg);
	}

	&.right {
		transform: translate3d(0,-50%,0) rotate(45deg); 
	}
	
	&:hover {
		border-color: #F6BE27;
		box-shadow: 0.5vmin -0.5vmin 0 white;
	}
`

const Buttons = styled.div`
  height: 85%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #333333;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #242424;
    border-radius: 12px;
  }
`