import { useContext, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import API from "../../../services/api";

import { AuthContext } from "../../../contexts/AuthProvider";
import searchDimensions from "../../../services/searchExtractionDimensions.json";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

import { Form } from "./style";

export default function GAExtractor(props) {
  
  const { currentUser } = useContext(AuthContext);
  const api = new API(currentUser);
  const [filters, setFilters] = useState([])
  const dimensionsSearch = searchDimensions.dimensions;
  const fixedOptions = searchDimensions.dimensions[0];
  const [dimensions, setDimensions] = useState([fixedOptions]);
  const [typeReport, setTypeReport] = useState("")
  const [preDefReport, setPreDefReport] = useState([])

  const dateExpiration = new Date();
  dateExpiration.setMonth(dateExpiration.getMonth() + 6);

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      customers: "",
      nameExtractions: "",
      date: "",
      description: "",
      url: "",
    },
  });

  useEffect(() => {
    reset(
      {
        customers: "",
        nameExtractions: "",
        date: "",
        description: "",
        url: "",
      },
      [clearSelected()]
    );
    setFilters([])
  }, [props.platform, reset]);

  function completeExtraction() {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );

    Array.from(document.querySelectorAll("select")).forEach(
      (option) => (option.value = "")
    );

    Array.from(document.querySelectorAll("textarea")).forEach(
      (textarea) => (textarea.value = "")
    );

    reset();
    clearSelected();
  }

  function platformPayload(bodySearch) {
    return bodySearch;
  }

  function formatWords(word) {
    const wordFormat = word
      .normalize("NFD")
      .replace(/ /g, "_")
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z-_])/g, "")
      .replace("-", "_")
      .toLowerCase();
    return wordFormat;
  }

  const sendExtraction = (event) => {
    props.setModalController("create extraction");
    const bodySearch = {
      metadata: {
        extractionName: formatWords(event.nameExtractions),
        author: currentUser.email,
        customer: formatWords(event.customers),
        creationDate: new Date().toISOString(),
        expirationDate: dateExpiration.toISOString().slice(0, 10),
        consolidationWindow: 5,
        precisionInterval: 30,
        extractorModule: props.platform,
        startDate: event.date,
      },
      queryParams: {
        url: `${event.url}`,
        dimensionFilterGroups: [
          {
            filters: [...filters],
          },
        ],
        dimensions: dimensions,
      },
      destination: {
        projectId: process.env.REACT_APP_DEFAULT_PROJECT_BQ,
        datasetId: formatWords(event.customers),
        tableId: `${props.platform.toLowerCase()}-${formatWords(event.customers)}-${formatWords(event.nameExtractions)}`,
        description: event.description,
      },
    };
    api
      .createDailyExtraction(platformPayload(bodySearch))
      .then(() => {
        props.setModalController("create extraction complete");
        completeExtraction();
      })
      .catch((er) => {
        if (er.request.status === 400) {
          props.setNewError(er.data.error);
          props.setModalController("error campo");
        } else {
          props.setModalController("error report");
        }
      });
  };

  function handleFilterChange(index, event) {
    let data = [...filters]
    data[index][event.target.name] = event.target.value

    setFilters(data);
  }

  const addField = () => {
    let newField = {
      dimension: '',
      expression: '',
      operator: ''
    }
    setFilters([...filters, newField])
  }

  const removeField = (index) => {
    let data = [...filters];
    data.splice(index, 1)
    setFilters(data)
  }

  const clearSelected = () => {
    setDimensions([fixedOptions]);
    setFilters([]);
  };
  function handlePreDef(e) {
    e.preventDefault();
    setTypeReport(e.target.value);
}
  const placeholderChange = (dimensionName) => {
    switch (dimensionName) {
      case 'QUERY':
        return 'ex: shopping'
      case 'DEVICE':
        return 'ex: MOBILE'
      case 'COUNTRY':
        return 'ex: BRA'
      case 'PAGE':
        return 'ex: https://www.zzzzzzzz.com.br/roupas/'
      default:
        return 'Escolha a dimensão a ser filtrada.'
    }
  }
  return (
    <Form onSubmit={(e) => handleSubmit(sendExtraction)(e)}>
      {props?.preDefExtraction.length !== 0 && <><label htmlFor="preDef">Relatórios Predefinidos</label>
            <select
                id="preDef"
                required
                onChange={handlePreDef}
                value={typeReport}
            >
                <option value="">
                    Selecione o tipo de relatório
                </option>
                <option value="personalizado">Relatório personalizado</option>
                {props?.preDefExtraction && props.preDefExtraction.map((option) => {
                    return (
                        <option key={option.id} value={option.name}>
                            {" "}
                            {option.name}{" "}
                        </option>
                    );
                })}
            </select></>}
      <label htmlFor="cliente">Cliente</label>
      <select id="cliente" {...register("customers")} required selected>
        <option value="">Escolha o nome do cliente.</option>
        {props.clients !== undefined &&
          props.clients.map((option) => {
            return (
              <option key={option.id} value={option.name}>
                {" "}
                {option.name}{" "}
              </option>
            );
          })}
      </select>

      <label htmlFor="nameExtractions">Nome da extração</label>
      <input
        required
        id="nameExtractions"
        placeholder="Ex: Cliente_productPerformance"
        name="nameExtractions"
        {...register("nameExtractions")}
      />
      <label htmlFor="urlExtraction">Digite a URL</label>
      <input
        required
        id="urlExtraction"
        placeholder="Ex: https://www.zzzzzzzzzz.com.br/"
        name="urlExtraction"
        {...register("url")}
      />
      <Stack spacing={2}>
        <label htmlFor="dimensions"> Dimensões</label>
        <Controller
          control={control}
          name="dimensions"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              required
              multiple
              id="dimensions"
              options={dimensionsSearch.map((item) => item)}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                onChange(newValue);
                setDimensions([
                  fixedOptions,
                  ...newValue.filter(
                    (option) => fixedOptions.indexOf(option) === -1
                  ),
                ]);
              }}
              value={dimensions}
              getOptionDisabled={() => dimensions.length === 7}
              isOptionEqualToValue={(option, value) => option === value}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option}
                    {...getTagProps({ index })}
                    disabled={fixedOptions.indexOf(option) === 0}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  inputProps={{ disableUnderline: true }}
                  classes={{ root: "MuiTextField-root" }}
                  {...params}
                  placeholder={"Adicione suas dimensões"}
                />
              )}
            />
          )}
        />
      </Stack>
      {(
        <>

          {filters.map((object, index) => {
            return (
              
              <div id="filter" key={index}>
                <label >Filtro {index + 1}</label>
                <label htmlFor="dimensions">Dimensão</label>
                <select
                  name={'dimension'}
                  id={"dimensions"}
                  onChange={(e) => handleFilterChange(index, e)}
                  value={object.dimension}
                  required
                >
                  <option value={""}>Escolha o filtro que deseja!</option>
                  {searchDimensions.filters[0].dimensions.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>

                <label htmlFor="expression">Expressão</label>
                <input name={'expression'}
                  type="text"
                  id={'expression'}
                  className="expression"
                  value={object.expression}
                  onChange={(e) =>
                  handleFilterChange(index, e)}
                  required
                  placeholder={placeholderChange(filters[index].dimension)}/>

                <label htmlFor="operator">Operador</label>
                <select
                  name={'operator'}
                  id={"operator"}
                  onChange={(e) => handleFilterChange(index, e)}
                  value={object.operator}
                  required
                >
                  <option value={""}>Escolha o operador que deseja!</option>
                  {searchDimensions.filters[0].operator.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>

                <input name={index} className="removeFilter" onClick={(e) => {
                  if (filters.length <= 0) return
                  removeField(index)
                }} type='button' value={'Remover Filtro'}></input>
              </div>
            )

          })}
          <input className={'addFilter'} onClick={() => {
            addField()
          }} type='button' value={'Adicionar Filtro'}></input>
        </>
      )}
      <label htmlFor="data">Início da extração:</label>
      <input
        type="date"
        id="data"
        required
        max={new Date().toISOString().split("T")[0]}
        name="date"
        {...register("date")}
      />

      <label htmlFor="descricao">Descrição da extração</label>
      <textarea
        minLength={10}
        id="description"
        name="description"
        placeholder="Descreva brevemente sua extração."
        required
        wrap="true"
        {...register("description")}
      />

      <button type="submit">Enviar Extração</button>
    </Form>
  );
}
