import { Check } from "@mui/icons-material";
import CircularProgress from '@mui/material/CircularProgress';
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

function SaveForm() {
    const [timer, setTimer] = useState(null)
    const [isSaving, setIsSaving] = useState(false)

    const handleStorage = useCallback(() => {
        clearTimeout(timer)
        setIsSaving(true)

        setTimer(
            setTimeout(() => {
                setIsSaving(false)
            }, 1500)
        )
    }, [timer])

    useEffect(() => {
        window.addEventListener('storageChange', handleStorage, false)

        return () => {
            window.removeEventListener('storageChange', handleStorage)
        }
    }, [handleStorage])

    return (
        <Wrapper>
            {
                isSaving && (
                    <>
                        <CircularProgress size={24} color="inherit" />
                        <Text>Salvando...</Text>
                    </>
                )
            }
            
            {
                !isSaving && (
                    <>
                        <Check />
                        <Text>Rascunho salvo</Text>
                    </>
                )
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: fixed;
    top: 145px;
    right: 80px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px;
    border-radius: 32px;
    background-color: #f6be27;
    box-sizing: border-box;

    @media (min-width: 1366px) {
        top: 200px;
        right: 115px;
    }
`

const Text = styled.span`
    display: block;
    font-size: 16px
`

export default SaveForm;