import { useEffect, useState } from "react"
import { LocalStorage } from "../../../services/localStorage"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { FormSectionHeader, FormSectionLabel, Hidden, TitleArea } from "./style"
import ManagerAnswersSection from "./ManagerAnswersSection"

export default function FormSectionCSHelperManager({ managersData, manager, areaName, headName }) {
    const [collapsed, setCollapsed] = useState(false)

    window.addEventListener("storageChange", () => {
        if(window.localStorage.getItem("validating")) {
            setCollapsed(true)
        }
    })

    useEffect(() => {
        LocalStorage.createCollapsedManagersObj(`${headName} : ${areaName}`)
        const local = LocalStorage.getCollapsedManager(`${headName} : ${areaName}`)
        setCollapsed(local)
    }, [])

    function handleCollapse() {
        const local = LocalStorage.setCollapsedManager(`${headName} : ${areaName}`, !collapsed)
        setCollapsed(local)
    }

    return (
        <>
            {
                collapsed ? 
                <ExpandLess style={{color: "#fff", cursor: "pointer", position: "absolute", top: "64px", right: "32px"}} title={"Minimizar"} onClick={handleCollapse} />
                :
                <ExpandMore style={{color: "#fff", cursor: "pointer", position: "absolute", top: "64px", right: "32px"}} title={"Expandir"} onClick={handleCollapse} />
            }
            
            <TitleArea onClick={handleCollapse}>{`${headName} : ${areaName}`}</TitleArea>

            {
                collapsed && (
                    <>

                        <FormSectionHeader key={`header-${headName}-${areaName}`}>
                            <Hidden></Hidden>
                            <FormSectionLabel>VERDE</FormSectionLabel>
                            <FormSectionLabel>AMARELO</FormSectionLabel>
                            <FormSectionLabel>VERMELHO</FormSectionLabel>
                            <Hidden></Hidden>
                        </FormSectionHeader>

                        {
                            managersData[manager][headName][areaName].map((coordNameAndClient) => (
                                <ManagerAnswersSection key={coordNameAndClient} manager={manager} head={headName} area={areaName} coord={coordNameAndClient} />
                            ))
                        }
                    </>
                )
            }
        </>
    )
}