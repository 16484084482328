import React, { useContext, useState } from "react";

import { useForm, Controller } from "react-hook-form";

import API from "../../../services/api";
import { AuthContext } from "../../../contexts/AuthProvider";
import MetaCaralogMetrics from "../../../services/metaCatalogMetrics.json";

import { Form } from "./style";

import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

export default function FBExtractor(props) {
  const { currentUser } = useContext(AuthContext);
  const api = new API(currentUser);

  const dateExpiration = new Date();
  dateExpiration.setMonth(dateExpiration.getMonth() + 6);

  const fieldsMeta = MetaCaralogMetrics.fields;

  const [fields, setFields] = useState([]);
  const [token, setToken] = useState(null)
  const [tokenFinale, setTokenFinale] = useState(null)
  const [profile, setProfile] = useState(null)
  const [, setProvider] = useState('')
  const [typeReport, setTypeReport] = useState("")
  
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      customers: "",
      catalogId: "",
      nameExtraction: "",
      description: "",
      token: ""
    },
  });

  function completeExtraction() {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );

    Array.from(document.querySelectorAll("select")).forEach(
      (option) => (option.value = "")
    );

    Array.from(document.querySelectorAll("textarea")).forEach(
      (textarea) => (textarea.value = "")
    );

    reset();
    clearSelected();
  }

  function formatWords(word) {
    const wordFormat = word
      .normalize("NFD")
      .replace(/ /g, "_")
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z-_])/g, "")
      .replace("-", "_")
      .toLowerCase();
    return wordFormat;
  }

  const sendExtraction = (event) => {
    props.setModalController("create extraction");

    const bodyMetaCatalog = {
      metadata: {
        extractionName: formatWords(event.nameExtraction),
        author: currentUser.email,
        customer: formatWords(event.customers),
        creationDate: new Date().toISOString(),
        expirationDate: dateExpiration.toISOString().slice(0, 10),
        consolidationWindow: -1,
        precisionInterval: 1,
        extractorModule: "metaCatalogExtractor",
        startDate: new Date().toISOString(),
      },
      queryParams: {
        catalogId: event.catalogId,
        fields: fields,
        token: tokenFinale,
      },
      destination: {
        projectId: process.env.REACT_APP_DEFAULT_PROJECT_BQ,
        datasetId: formatWords(event.customers),
        tableId: `${"metaCatalogExtractor"}-${formatWords(event.customers)}-${formatWords(event.nameExtraction)}`,
        description: event.description,
      },
    };
    api
      .tokenManager(profile?.accessToken)
      .then((response) => {
        bodyMetaCatalog.queryParams.token = response.data.token
        api
          .createDailyExtraction(bodyMetaCatalog)
          .then(() => {
            props.setModalController("create extraction complete");
            completeExtraction();
          })
          .catch((error) => {
            if (error.request.status === 400) {
              props.setNewError(error.data.error);
              props.setModalController("error campo");
            } else {
              props.setModalController("error report");
            }
          });
      })
  };

  const clearSelected = () => {
    setFields([]);
    setToken(null)
    setTokenFinale(null)
  };

  function handlePreDef(e) {
    e.preventDefault();
    setTypeReport(e.target.value);
  }

  return (
    <Form onSubmit={handleSubmit(sendExtraction)} backgroundColor={token ? "#3B5998" : "#ff0000"}>
      
      {props?.preDefExtraction.length !== 0 && 
      <>
        <label htmlFor="preDef">Relatórios Predefinidos</label>
          <select
              id="preDef"
              required
              onChange={handlePreDef}
              value={typeReport}
          >
            <option value=""> Selecione o tipo de relatório</option>
            <option value="personalizado">Relatório personalizado</option>
              {props?.preDefExtraction && props.preDefExtraction.map((option) => {
                    return (
                        <option key={option.id} value={option.name}>
                            {" "}
                            {option.name}{" "}
                        </option>
                    );
                })}
          </select>
      </>
      }
      
      <br/>
      <LoginSocialFacebook
        appId="713376869438759"
        scope="public_profile,catalog_management"
        onResolve={({ provider, data }) => {
          setProvider(provider)
          setProfile(data)
          setToken(data.accessToken)
        }}
        onReject={(err) => {
          console.log(err)
        }}
      >
        <FacebookLoginButton/>
      </LoginSocialFacebook>

      <label htmlFor="token">Token</label>
      <input
        id="token"
        value={token ? "Logado no facebook." : "Logue no facebook."}
        name="token"
        {...register("token")}
        disabled
      />

      <label htmlFor="cliente">Cliente</label>
      <select id="cliente" {...register("customers")} required selected>
        <option value="">Escolha o nome do cliente.</option>
        {props.clients !== undefined &&
          props.clients.map((option) => {
            return (
              <option key={option.id} value={option.name}>
                {" "}
                {option.name}{" "}
              </option>
            );
          })}
      </select>

      <label htmlFor="catalogId">{"Catalog ID"}</label>
      <input
        required
        id="catalogId"
        placeholder={"Ex: 1234567890"}
        name="catalogId"
        {...register("catalogId")}
      />

      <label htmlFor="nameExtraction">Nome da extração</label>
      <input
        required
        id="nameExtraction"
        placeholder="Ex: Cliente_productPerformance"
        name="nameExtraction"
        {...register("nameExtraction")}
      />

      <Stack spacing={2}>
      <label htmlFor="fields">Fields</label>
      <Controller
          control={control}
          name="fields"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="fields"
              multiple
              required
              options={fieldsMeta}
              value={fields}
              onChange={(event, values) => {
                onChange(values);
                setFields([...values])
              }}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{ root: "MuiTextField-root" }}
                  placeholder="Adicione seus Fields"
                  onChange={onChange}
                />
              )}
            />
          )}
        />
      </Stack>

      <label htmlFor="description">Descrição da extração</label>
      <textarea
        minLength={10}
        id="description"
        name="description"
        placeholder="Descreva brevemente sua extração."
        required
        wrap="true"
        {...register("description")}
      />

      <button type="submit">Enviar Extração</button>
    </Form>
  );
}
