import React from 'react'
import styled from 'styled-components'

import Logo from '../images/searchIcon.svg'

function Search(props) {
  

  function handleSearch(e){
      e.preventDefault();
      props.setSearch(e.target.value.toLowerCase());
  }
  return (
    <Container>
      <SearchInput onChange={handleSearch} type='text' placeholder={props.text} value={props.search}></SearchInput>
      <SearchImg type='image' src={Logo} />
    </Container>
  )
}

export default Search;

const Container = styled.div`
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  background: #FFFFFF;

  /* field */
  box-sizing: border-box;

  width: 50%;
  height: 48px;

  border: 1px solid #344054;
  border-radius: 400px;

  /* Auto layout */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  gap: 10px;

  /* identical to box height, or 120% */
  display: flex;

  /* default/neutral/700 */
  color: #344054;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  @media(max-width: 1300px){
    height: 35px;
  }

`

const SearchInput = styled.input`
  width: 90%;
  background: transparent;
  border: none;
  font-family: 'Open Sans', sans-serif;

  font-size: 1.25em;

  &:focus{
      outline: none;
  }

  @media(max-width: 1300px) {
    font-size: 1em;
  }

`
const SearchImg = styled.input`
  width: 5%;

  @media(max-width: 1300px) {
    width: 8%;
  }

`