import styled from "styled-components";
import { Check } from "@mui/icons-material";
import RenewIcon from "../images/renewIcon.svg";
import { useState } from "react";
import API from "../services/api";
import { AuthContext } from "../contexts/AuthProvider";
import { useContext } from "react";
import { DotLoader } from "./DotLoader";

export default function ValidationButton(props) {
    const { currentUser } = useContext(AuthContext);
    const api = new API(currentUser);

    const [isFetching, setIsFetching] = useState(false);
    const [isError, setIsError] = useState(false);
    
    const fetchCustomFields = async (event) => {
        try {
            event.preventDefault();
            setIsFetching(true);
            props.setIsFetching(true);
            setIsError(false);
            props.setIsError(false);
        
            const propId = document.getElementById("idCliente").value;
        
            if(propId === "") {
                setIsFetching(false);
                props.setIsFetching(false);
                props.setIsValid(false);
                setIsError(false);
                document.getElementById("idCliente").focus();
                return
            };

            const response = await api
                .listGlossary("ga4", "Custom", propId);

            console.log(response);

            setIsError(false);
            props.setIsError(false);
            props.setIsValid(true);
            setIsFetching(false);
            props.setIsFetching(false);
            props.setIsValid(true);
            props.setCustomDimensions(Object.values(response.data.data.dimensions));
            props.setCustomMetrics(Object.values(response.data.data.metrics));
        } catch (error) {
            setIsError(true);
            props.setIsError(true);
            props.setIsValid(false);
            setIsFetching(false);
            props.setIsFetching(false);
            props.setIsValid(false);
            console.log(error);
        }
    };

    if(isFetching) {
        return (
            <LoadingButton title="Validando..."> 
                <DotLoader />
            </LoadingButton>
        );
    };

    if(props.isValid) {
        return (
            <ButtonSuccess onClick={fetchCustomFields} title={"Validado"}>
                <Check />
            </ButtonSuccess>
        );
    };

    if(isError) {
        return (
            <ErrorButton onClick={fetchCustomFields} title={"Validar novamente"}>
                <img src={RenewIcon} alt={"renew"} />
            </ErrorButton>
        );
    };

    return (
        <Button onClick={fetchCustomFields} title={"Validar"}>
            <Check />
        </Button>
    );
};

const LoadingButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    width: 30px !important;
    height: 30px !important;
    background-color: #F6BE27;
    border-radius: 4px;
    position: absolute;
    bottom: 4px;
    right: 4px;
`

const Button = styled.button`
    width: 32px !important;
    height: 32px !important;
    bottom: 5px;
    right: 5px;
    position: absolute;
`

const ErrorButton = styled.button`
    background-color: #E72121 !important;
    width: 32px !important;
    height: 32px !important;
    bottom: 5px;
    right: 5px;
    position: absolute;
`

const ButtonSuccess = styled.button`
    background-color: #00ff00 !important;
    width: 32px !important;
    height: 32px !important;
    bottom: 5px;
    right: 5px;
    position: absolute;
`