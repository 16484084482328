import React, { useContext } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import AuthPage from '../views/AuthPage';
import HomeNews from '../views/HomeNews'
import HomeExtractions from '../views/HomeExtraction';
import UserRoleController from '../views/UserRoleController';
import Feedback  from '../views/Feedback';
import Controller from '../views/Controller';
import CSHelper from '../views/CSHelper';

import { AuthContext } from '../contexts/AuthProvider';
import AuthProvider from '../contexts/AuthProvider';

function Router() {

  const Private = ({ children }) => {
    const { auth } = useContext(AuthContext)

    if (auth) {
      return children
    }

    return <Navigate to='/' />
  }

  const Admin = ({children}) => {
    const { customClaims } = useContext(AuthContext);

    if (JSON.parse(customClaims).role === 'admin') {
      return children
    }

    return <Navigate to='/' />

  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<AuthPage />} />
          <Route path="/homeNews" element={<Private><HomeNews /></Private>} />
          <Route path="/homeExtractions" element={<Private><HomeExtractions /></Private>} />
          <Route path="/userRoleController" element={<Private><Admin><UserRoleController /></Admin></Private>} />
          <Route path="/feedback" element={<Private><Feedback/></Private>}/>
          <Route path="/controller" element={<Private><Admin><Controller/></Admin></Private>}/>
          <Route path="/cshelper" element={<Private><CSHelper /></Private>}/>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default Router