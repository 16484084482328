import React, { useState, useEffect, useContext } from "react"
import FormSectionCSHelperHead from "../components/Forms/FormSectionCSHelperHead"
import FormSectionCSHelperCood from "../components/Forms/FormSectionCSHelperCoords"
import styled from "styled-components"
import ModalCsHelper from "../components/Modals/ModalCSHelper"
import processCSHelperHeadAnswers from "../services/processCSHelperHeadAnswers"
import processCSHelperCoordAnswers from "../services/processCSHelperCoordAnswers"
import Loading from "../components/Loading"
import apiCSHelperSendData from "../services/apiCSHelperSendData"
import csHelperGetData from "../services/csHelperDataGet"

import InfosIcon from '../images/InfosIcon.svg'
import { AuthContext } from "../contexts/AuthProvider"
import SaveForm from "../components/SaveForm"

function CSHelperFormHead({ setModalDescriptionState }) {
  const [isSending, setIsSending] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [headsData, setHeadsData] = useState(null)
  const [arrayFormsHeads, setArrayFormsHeads] = useState(null)
  const [selectOptions, setSelectOptions] = useState([])
  const [currentArea, setCurrentArea] = useState([])
  const currentUserEmail = useContext(AuthContext).currentUser.email
  const userEmail = process.env.REACT_APP_userEmailHead || currentUserEmail

  useEffect(() => {

    csHelperGetData.getHeadsData(userEmail)
      .then(response => {
        setHeadsData(response)
      });

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    function handleSubmit(event) {
      event.preventDefault()

      window.localStorage.setItem("validating", true)
      window.dispatchEvent(new Event("storageChange"))

      setTimeout(async () => {
        const form = document.getElementById("CSHelperFormHead")
        const isFormValid = form.checkValidity()

        if (isFormValid) {
          event.preventDefault()
          let processed;

          if(currentArea[0] === "Coordenadores") {
            processed = processCSHelperCoordAnswers(currentArea[0])
          } else {
            processed = processCSHelperHeadAnswers(currentArea[0])
          }
          
          try {
            console.log('Enviando respostas!')
            setShowModal(true)
            setIsSending(true)
            let response;

            if(currentArea[0] === "Coordenadores") {
              response = await apiCSHelperSendData.sendAnswersCoord(processed)
            } else {
              response = await apiCSHelperSendData.sendAnswersHead(processed)
            }

            if (response.status === 200) {
              setShowModal(true)
              setIsSending(false)
              setIsSuccess(true)

              if(currentArea[0] === "Coordenadores") {
                window.localStorage.removeItem("coords_forms_answers")
                return
              }

              const headAnswers = JSON.parse(window.localStorage.getItem("head_answers"))
              delete headAnswers[Object.keys(headsData.clients)[0]][currentArea[0]]
              window.localStorage.setItem("head_answers", JSON.stringify(headAnswers));
              window.localStorage.removeItem("validating");
            }
          } catch (error) {
            setIsSending(false)
            setIsError(true)
            console.log(error)
          }
        }

        form.reportValidity()
      }, 500)
    }

    function renderForm() {
      if (headsData === null) return

      let elements = []
      const head = Object.keys(headsData.clients)[0]
      const options = Object.keys(headsData.clients[head])
      const coords = Object.keys(headsData.coords)
      options.push('Coordenadores')

      setSelectOptions(options)

      if (currentArea[0] === 'Coordenadores') {
        coords.forEach(nomeCoord => {
          <div>
          {
            elements.push(
                <ContainerArea>
                  <FormSectionCSHelperCood setModalDescriptionState={setModalDescriptionState} key={nomeCoord} area={headsData.coords[nomeCoord]} coord={nomeCoord} head={head} />
                </ContainerArea>
            )
          }
          </div>
        })
        elements.push(<Submit key={"enviar"} onClick={handleSubmit}>ENVIAR</Submit>)
      } else {
        currentArea.forEach(area => {
          elements.push(
            <div>
              {
                headsData.clients[head][area].map(coord => {
                  console.log(headsData.clients[head][area]);
                  return (
                    <ContainerArea key={Math.random() * 1000}>
                      <FormSectionCSHelperHead setModalDescriptionState={setModalDescriptionState} key={coord} area={area} coord={coord} head={head} />
                    </ContainerArea>
                  )
                })
              }
            </div>
          )

          elements.push(<Submit key={"enviar"} onClick={handleSubmit}>ENVIAR</Submit>)
        })
      }

      setArrayFormsHeads(elements)
    }

    renderForm()
    // eslint-disable-next-line
  }, [headsData, currentArea])

  if (headsData === null) {
    return (
      <Container>
        <Loading />
      </Container>
    )
  }

  return (
    <Container>
      <SaveForm />

      <WellcomeContainer key={"container"}>
        <WellcomeTitleWrapper>
          <InfoIcon src={InfosIcon} alt="Ícone de informação" />
          <WellcomeTitle>{`Olá ${Object.keys(headsData.clients)[0].split(" ")[0]}!`}</WellcomeTitle>
        </WellcomeTitleWrapper>
        <p>
          Sejam bem-vindos ao conformidades! Objetivo dele é facilitar e agilizar o preenchimento de informações sobre a saúde de cada cliente/time, prevendo riscos de churn, sinalizando possíveis upsells e mais! Em caso de qualquer dúvida ou necessidade de ajuste, acione o time de Projetos Internos de Sorocaba:&nbsp;
          <EmailLink target='_blank' href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=projetos-internos@rocky.ag">projetos-internos@rocky.ag </EmailLink>
          😀.
        </p>
        <DashLink href={`${process.env.REACT_APP_CSHELPER_DASH_URL_HEAD}`} target="_blank" rel="noreferrer">Acessar dash</DashLink>
        <DashLink href={`${process.env.REACT_APP_LINK_ATUALIZAR_PESSOAS}`} target="_blank" rel="noreferrer">Atualizar Pessoas</DashLink>
      </WellcomeContainer>

      <FormWrapper id="CSHelperFormHead">

        <SelectionArea required onChange={(event) => {
          event.preventDefault()
          if (event.target.value !== "") {
            setCurrentArea([event.target.value])
          }
        }
        }>
          <option value={''} defaultChecked={true}>Selecione a area</option>
          {
            selectOptions.map((area) => <option key={area} value={area}>{area}</option>)
          }
        </SelectionArea>

        {
          arrayFormsHeads
        }

      </FormWrapper>

      <ModalCsHelper
        showModal={showModal}
        isSending={isSending}
        setShowModal={setShowModal}
        isError={isError}
        isSuccess={isSuccess}
        setIsError={setIsError}
      ></ModalCsHelper>


    </Container>
  )
}

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 30px;
    gap: 32px;
    
    &::-webkit-scrollbar {
      width: 7px;
    }
  
    &::-webkit-scrollbar-track {
      background: #333333;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #242424;
      border-radius: 12px;
    }
`;

const ContainerArea = styled.div`
      background-color: #242424;
      padding: 24px;
      margin: 24px 0;
      border-radius: 8px;
`

const WellcomeContainer = styled.div`
      width: 87%;
      background-color: #242424;
      padding: 24px;
      color: #FFFFFF;
      border-radius: 8px;
      margin-top: 24px;
`;

const WellcomeTitleWrapper = styled.div`
      display: flex;
      gap: 24px;
`;

const WellcomeTitle = styled.span`
      font-size: 24px;
      font-family: "HelveticaNowforMonksMedium";
      font-weight: bold;
`;

const InfoIcon = styled.img`
    width: 25px;
`;

const EmailLink = styled.a`
    color: #ffffff;
`;

const DashLink = styled.a`
  font-family: 'Space Grotesk';
  font-style: normal;
  font-size: 16px;

  // display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  gap: 12px;
  text-align: center;
  cursor: pointer;
  width: fit-content;
  justify-content:center;
  height: 32px;
  color: #000;
  text-decoration: none;

  margin-right: 20px;

  /* secondary/500 */

  background: #F6BE27;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;   
  border: none;

  &:hover{
    background-color: #fdad17;
    transition: 0.5s;
  }
`;

const Submit = styled.button`
    display: block;
    font-family: "HelveticaNowforMonksMedium";
    margin: 32px auto 32px auto;
    width: 100%;
    border-radius: 8px;
    background-color: #f6be27;
    padding: 16px 8px;  
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
    &:hover {
        background-color: #daa209;
    }
`;

const FormWrapper = styled.form`
    width: 90%;
    position: relative;
    box-sizing: border-box;

    @media (min-width: 1366px) {
      width: 50%;
    }
`;

const SelectionArea = styled.select`
    min-width: 100%;  
    padding: 16px 8px;
    background-color: #242424;
    border: none;
    color: #fff;
    border-radius: 8px;
`;

export default CSHelperFormHead