import styled from 'styled-components'


function PageTitle(props) {
  return (
    <Text>{props.text}</Text>
  )
}

export default PageTitle;

const Text = styled.p`
  font-family: 'HelveticaNowforMonksExtraBold', sans-serif;
  font-size: 2.5em;

  display: flex;
  align-items: center;

  color: #F6BE27;
  margin: 0;

  @media(max-width: 1500px) {
    font-size: 1.7rem;
  }

`
