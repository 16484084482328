import React from 'react';
import ReactDOM from 'react-dom/client';

import './config/firebase'

import './index.css';
import './fonts/HelveticaNowforMonksMedium.otf'
import './fonts/HelveticaNowforMonksBold.otf'
import './fonts/HelveticaNowforMonksExtraBold.otf'

import Router from './routes/Router';

import { createTheme } from '@mui/material/styles';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider'

const theme = createTheme({
  typography: {
    allVariants: {
      color: "#FFF"
    },
  },
  palette: {
    primary: {
      main: "#F6BE27",
      contrastText: "#fff"
    },
    secondary: {
      main: "#000",
      contrastText: "#fff"
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#fff",
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#333 !important",
          fontFamily: "Space Grotesk, sans-serif",
          border: "1px solid rgba(255, 255, 255, 0.38)",
          boxShadow: `
            0px 2px 1px -1px rgba(0,0,0,0.2), 
            0px 1px 1px 0px rgba(0,0,0,0.14), 
            0px 1px 3px 0px rgba(0,0,0,0.12)
          `,
        }
        
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "#fff"
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          backgroundColor: "#FFF !important",
          color: "#333 !important"
        }
      }
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <Router />
    </MuiThemeProvider>
  </React.StrictMode>
);
