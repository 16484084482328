import styled from "styled-components";
import errorIcon from "../images/errorIcon.png";
import successIcon from "../images/successIcon.png";
export default function WarningModal(props) {
  function handleWarning() {
    switch (props.modalController) {
      case "report null":
        return (
          <>
            <img src={errorIcon} alt="Icone de erro"></img>
            <textarea disabled value={"Preencha o campo de report!"} />
          </>
        );
      case "report":
        return (
          <>
            <textarea disabled value={"Reportando extração..."} />
            <span className="loader"></span>
          </>
        );
      case "report complete":
        return (
          <>
            <img src={successIcon} alt="icone de concluído"></img>
            <textarea disabled value={"Extração reportada!"} />
          </>
        );
      case "email null":
        return (
          <>
            <img src={errorIcon} alt="Icone de erro"></img>
            <textarea
              disabled
              value={"necessário email @rocky.ag ou @mediamonks.com"}
            />
          </>
        );
      case "transfer":
        return (
          <>
            <textarea
              className="transfer"
              disabled
              value={`Deseja transferir?`}
            />
            <textarea
              className="transfer"
              disabled
              value={`Extraction: ${props.extractionName}`}
            />
            <textarea
              className="transfer"
              disabled
              value={`autor antigo: ${props.previousAuthor}`}
            />
            <textarea
              className="transfer"
              disabled
              value={`Novo autor: ${props.text}`}
            />
          </>
        );
      case "transfer done":
        return (
          <>
            <textarea disabled value={"Transfererindo extração..."} />
            <textarea
              className="transfer"
              disabled
              value={`Extraction: ${props.extractionName}`}
            />
            <textarea
              className="transfer"
              disabled
              value={`author antigo: ${props.previousAuthor}`}
            />
            <textarea
              className="transfer"
              disabled
              value={`Novo author: ${props.text}`}
            />
            <span className="loader"></span>
          </>
        );
      case "transfer complete":
        return (
          <>
            <img src={successIcon} alt="icone de concluído"></img>
            <textarea disabled value={"Transferência concluída."} />
          </>
        );
      case "disable":
        return (
          <>
            <textarea
              disabled
              value={"Deseja desativar essa extração?"}
            ></textarea>
            <textarea disabled value={props.extractionName} />
          </>
        );

      case "disable done":
        return (
          <>
            <textarea disabled value={"Desativando extração..."}></textarea>
            <textarea disabled value={props.extractionName} />
            <span className="loader"></span>
          </>
        );

      case "disable complete":
        return (
          <>
            <img src={successIcon} alt="icone de concluído"></img>
            <textarea disabled value={"Extração desativada."}></textarea>;
            <textarea disabled value={props.extractionName} />
          </>
        );
      case "renew":
        return (
          <>
            <textarea
              disabled
              value={"Deseja renovar essa extração?"}
            ></textarea>
            <textarea disabled value={props.extractionName}></textarea>
            <textarea
              disabled
              value={`Sua extração será renovada para: ${props.renewDate}`}
            ></textarea>
          </>
        );
      case "renew done":
        return (
          <>
            <textarea disabled value={"Renovando extração..."}></textarea>
            <textarea disabled value={props.extractionName}></textarea>
            <span className="loader"></span>
          </>
        );

      case "renew complete":
        return (
          <>
            <img src={successIcon} alt="icone de concluído"></img>
            <textarea disabled value={"extração renovada!"}></textarea>
            <textarea disabled value={props.extractionName}></textarea>
            <textarea
              disabled
              value={`Sua extração foi renovada para: ${props.renewDate}`}
            ></textarea>
          </>
        );
      case "active":
        return (
          <>
            <textarea
              disabled
              value={"Deseja ativar essa extração?"}
            ></textarea>
            <textarea disabled value={props.extractionName} />
          </>
        );

      case "active done":
        return (
          <>
            <textarea disabled value={"Ativando extração..."}></textarea>
            <textarea disabled value={props.extractionName} />
            <span className="loader"></span>
          </>
        );

      case "active complete":
        return (
          <>
            <img src={successIcon} alt="icone de concluído"></img>
            <textarea disabled value={"Extração ativada!"}></textarea>
            <textarea disabled value={props.extractionName} />
          </>
        );

      case "delete":
        return (
          <>
            <textarea
              disabled
              value={"Deseja deletar essa extração?"}
            ></textarea>
            <textarea disabled value={props.extractionName}></textarea>
          </>
        );
      case "delete done":
        return (
          <>
            <textarea disabled value={"Deletando extração..."}></textarea>
            <textarea disabled value={props.extractionName}></textarea>
            <span className="loader"></span>
          </>
        );
      case "delete complete":
        return (
          <>
            <img src={successIcon} alt="icone de concluído"></img>
            <textarea disabled value={"extração deletada!"}></textarea>
            <textarea disabled value={props.extractionName}></textarea>
          </>
        );

      case "error report":
        return (
          <>
            <img src={errorIcon} alt="Icone de erro"></img>
            <textarea disabled value={"Erro desconhecido"}></textarea>
            <textarea
              disabled
              value={"Favor informar os responsáveis."}
            ></textarea>
          </>
        );
      case "error delete":
        return (
          <>
            <textarea
              disabled
              value={"Extração se encontra ativa, favor desativar!"}
            ></textarea>
          </>
        );
      case "error":
        return (
          <>
            <img src={errorIcon} alt="Icone de erro"></img>
            <textarea disabled value={"Erro desconhecido"}></textarea>
            <textarea disabled value={"Favor reportar extração."}></textarea>
          </>
        );
      case "create extraction":
        return (
          <>
            <textarea disabled value={"Validando extração!"}></textarea>
            <textarea
              disabled
              value={"isso pode levar alguns minutos."}
            ></textarea>
            <span className="loader"></span>
          </>
        );
      case "create extraction complete":
        return (
          <>
            <img src={successIcon} alt="icone de concluído"></img>
            <textarea disabled value={"Sua extração foi criada!"}></textarea>
            <textarea
              disabled
              value={"Os dados começarão a cair nos próximos dias!"}
            ></textarea>
          </>
        );
      case "active extraction":
        return (
          <>
            <img src={errorIcon} alt="Icone de erro"></img>
            <textarea
              disabled
              value={"Desative sua extração antes de deletar!"}
            ></textarea>
          </>
        );
      case "error campo":
        return (
          <>
            <img src={errorIcon} alt="Icone de erro"></img>
            <textarea disabled value={"Erro ao validar!"}></textarea>
            <textarea
              id="error-campo"
              disabled
              value={props.newError.split(".", 2).join()}
            ></textarea>
          </>
        );
      default:
        break;
    }
  }
  function clickButton() {
    switch (props.modalController) {
      case "transfer":
        props.setModalController("transfer done");
        props.setAction("transfer");
        break;
      case "active":
        props.setModalController("active done");
        props.setAction("active");
        break;
      case "disable":
        props.setModalController("disable done");
        props.setAction("disable");
        break;
      case "renew":
        props.setModalController("renew done");
        props.setAction("renew");
        break;
      case "delete":
        props.setModalController("delete done");
        props.setAction("delete");
        break;

      default:
        break;
    }
  }

  function buttonText() {
    switch (props.modalController) {
      case "transfer":
      case "active":
      case "disable":
      case "renew":
      case "delete":
        return (
          <>
            <button
              onClick={() => {
                props.setModalController("");
              }}
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                clickButton();
              }}
            >
              {props.modalController === "transfer"
                ? "Transferir"
                : props.modalController === "active"
                ? "Ativar"
                : props.modalController === "disable"
                ? "Desativar"
                : props.modalController === "renew"
                ? "Renovar"
                : props.modalController === "delete"
                ? "Deletar"
                : "Concluir"}
            </button>
          </>
        );
      case "report":
      case "transfer done":
      case "active done":
      case "disable done":
      case "renew done":
      case "delete done":
      case "create extraction":
        break;

      default:
        return (
          <button
            onClick={() => {
              props.setModalController("");
              props.setAction("");
              props.setText("");
            }}
          >
            {" "}
            Ok{" "}
          </button>
        );
    }
  }
  return (
    <Container>
      <ContainerModal>
        <Modal modalController={props.modalController}>
          {handleWarning()}
          <BoxButton>{buttonText()}</BoxButton>
        </Modal>
      </ContainerModal>
    </Container>
  );
}

const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 25px;
  width: 550px;
  height: 270px;
  padding-bottom: 10px;
  padding-top: 5px;
  left: 0;
  gap: ${(props) =>
    props.modalController === "transfer"
      ? 0
      : props.doneController === "transfer done"
      ? 10
      : props.modalController === "transfer done"
      ? 0
      : props.modalController === "renew"
      ? 15
      : props.modalController === "renew complete"
      ? 5
      : props.modalController.includes("error")
      ? 10
      : props.modalController === "disable complete"
      ? 0
      : 23}px;
  top: 0;
  z-index: 2;
  background-color: #333333;
  img {
    width: ${(props) =>
      props.modalController.includes("error")
        ? 50
        : props.modalController === "email null"
        ? 50
        : props.modalController === "report null"
        ? 50
        : props.modalController === "active extraction"
        ? 50
        : 44}px;
    margin-bottom: 10px;
  }
  textarea {
    display: flex;
    text-align: center;
    font-family: "HelveticaNowforMonksExtraBold", sans-serif;
    width: 90%;
    outline: none;
    border: none;
    background: transparent;
    color: #f6be27;
    font-size: 16px;
    margin-bottom: 10px;
    resize: none;
    word-break: break-all;
    overflow: hidden;
  }
  #error-campo {
    height: 160px;
    font-size: 16px;
  }
  button {
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    border-radius: 5px;
    background: #242424;

    border: none;

    cursor: pointer;

    &:hover {
      background-color: #0d5eff;
      transition: 0.5s;
    }

    font-family: "HelveticaNowforMonksMedium";
    font-style: normal;
    font-size: 16px;
    color: #ffffff;

    @media (max-width: 1300px) {
      font-size: 13px;
    }
  }
  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const Container = styled.div`
  .closed {
    opacity: 0;
    transition-delay: 2s;
  }
  .open {
    opacity: 1;
  }
`;

const ContainerModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 15;
  background-color: rgba(255, 255, 255, 0.4);
  animation: init;
  animation-duration: 1s;
  @keyframes init {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const BoxButton = styled.div`
  display: flex;
  gap: 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
  button {
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    border-radius: 5px;
    background: #242424;

    border: none;

    cursor: pointer;

    &:hover {
      background-color: #0d5eff;
      transition: 0.5s;
    }

    font-family: "HelveticaNowforMonksMedium";
    font-style: normal;
    font-size: 16px;
    color: #ffffff;

    @media (max-width: 1300px) {
      font-size: 13px;
    }
  }
`;
