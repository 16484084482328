import styled from "styled-components"
import Logo from '../images/bellIcon.svg';
export default function Notify() {
  return (
    <ImageContainer>
      <Img type='image' src={Logo} />
    </ImageContainer>
  )
}

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;
`

const Img = styled.input`
  width: 50%;
  height: 50%;

  @media(max-width: 1300px){
    width: 30%;
    height: 30%;
  }

`