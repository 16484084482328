import styled from "styled-components";

export default function DescriptionExtraction(props) {
    return (
        <DescriptionBox>Descrição da extração: <br></br> {props.description}</DescriptionBox>
    )
}


const DescriptionBox = styled.p`
    /* M3rd/Paragraph 1/Normal */

font-family: 'Space Grotesk';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 36px;
word-break: break-all;
/* or 200% */

display: flex;
align-items: center;

/* neutral/200 */

color: #E4E7EC;


/* Inside auto layout */

flex: none;
order: 2;
align-self: stretch;
flex-grow: 0;
`