/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import API from "../../../services/api";
import { AuthContext } from "../../../contexts/AuthProvider";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

import { Form } from "./style";
import Loading from "../../Loading";
import ValidationButton from "../../ValidationButton";

export default function GA4Extractor(props) {
  const { currentUser } = useContext(AuthContext);
  const api = new API(currentUser);

  const dateExpiration = new Date();
  dateExpiration.setMonth(dateExpiration.getMonth() + 6);

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      customers: "",
      nameExtractions: "",
      date: "",
      description: "",
      idCliente: "",
    },
  });

  const fixedOptions = [{
    uiName: "Data",
    apiName: "date",
    description: "A data em que um evento foi coletado."
  }];

  const [metrics, setMetrics] = useState([]);
  const [dimensions, setDimensions] = useState([...fixedOptions]);
  const [typeReport, setTypeReport] = useState("")
  const [preDefReport, setPreDefReport] = useState([]);
  const [glossary, setGlossary] = useState(null);
  const [customMetrics, setCustomMetrics] = useState([]);
  const [customDimensions, setCustomDimensions] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const fetchGlossary = async (platform, lang, id) => {
    try {
      const response = await api.listGlossary(platform, lang, id)
      return response
    } catch (error) {
      console.log(error)
    }
  }

  const handlePropIdChange = () => {
    setIsValid(false);
    clearSelected()
    setTypeReport("")
  };

  useEffect(() => {
    fetchGlossary("ga4", "PT")
      .then(response => setGlossary(response.data.data))
  }, [])

  function completeExtraction() {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );

    Array.from(document.querySelectorAll("select")).forEach(
      (option) => (option.value = "")
    );

    Array.from(document.querySelectorAll("textarea")).forEach(
      (textarea) => (textarea.value = "")
    );

    reset();
    clearSelected();
  }
  
  useEffect(() => {
    if(props.preDefExtraction !== []) {
      const extractionFiltered =  props.preDefExtraction?.filter((extraction) =>  extraction.name === typeReport)
        setPreDefReport(extractionFiltered)
    }
  }, [typeReport])

  useEffect(() => {
    if(preDefReport.length !== 0) {
        const predefMetrics =  preDefReport[0].queryParams.metrics.map(metric => Object.values(glossary.metrics).find(item => item.apiName === metric))
        const predefDimensions =  preDefReport[0].queryParams.dimensions.map(dimensions => Object.values(glossary.dimensions).find(item => item.apiName === dimensions))
        setMetrics(predefMetrics);
        setDimensions(predefDimensions);
    }
  }, [preDefReport])

  function handlePreDef(e) {
    e.preventDefault();
    setTypeReport(e.target.value);
  }

  function platformPayload(bodyGA) {
    return bodyGA;
  }

  function formatWords(word) {
    const wordFormat = word
      .normalize("NFD")
      .replace(/ /g, "_")
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z-_])/g, "")
      .replace("-", "_")
      .toLowerCase();
    return wordFormat;
  }

  const sendExtraction = (event) => {
    props.setModalController("create extraction");
    const bodyGA = {
      metadata: {
        extractionName: formatWords(event.nameExtractions),
        author: currentUser.email,
        customer: formatWords(event.customers),
        creationDate: new Date().toISOString(),
        expirationDate: dateExpiration.toISOString().slice(0, 10),
        consolidationWindow: 2,
        precisionInterval: 30,
        extractorModule: "ga4Extractor",
        startDate: event.date,
      },
      queryParams: {
        id: event.idCliente,
        metrics: metrics.map(metric => metric.apiName),
        dimensions: dimensions.map(dimension => dimension.apiName),
      },
      destination: {
        projectId: process.env.REACT_APP_DEFAULT_PROJECT_BQ,
        datasetId: formatWords(event.customers),
        tableId: `ga4Extractor-${formatWords(event.customers)}-${formatWords(event.nameExtractions)}`,
        description: event.description,
      },
    };

    api
      .createDailyExtraction(platformPayload(bodyGA))
      .then(() => {
        props.setModalController("create extraction complete");
        completeExtraction();
      })
      .catch((er) => {
        if (er.request.status === 400) {
          props.setNewError(er.data.error);
          props.setModalController("error campo");
        } else {
          props.setModalController("error report");
        }
      });
  };

  useEffect(() => {
    reset(
      {
        customers: "",
        nameExtractions: "",
        date: "",
        description: "",
        idCliente: "",
      },
      [clearSelected()]
    );
  }, [props.platform, reset]);

  const clearSelected = () => {
    setMetrics([]);
    setDimensions([]);
  };

  if(!glossary) {
    return <div style={{ width: '100%', minHeight: '250px', display: "grid", placeItems: "center" }}>
      <Loading />
    </div>
  }

  return (
    <Form onSubmit={(e) => handleSubmit(sendExtraction)(e)}>
      <label htmlFor="cliente">Cliente</label>
      <select id="cliente" {...register("customers")} required selected>
        <option value="">Escolha o nome do cliente.</option>
        {props.clients !== undefined &&
          props.clients.map((option) => {
            return (
              <option key={option.id} value={option.name}>
                {" "}
                {option.name}{" "}
              </option>
            );
          })}
      </select>
      
      <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
        <label htmlFor="idCliente">ID da Propriedade</label>
        <ValidationButton isValid={isValid} setIsFetching={setIsFetching} setCustomMetrics={setCustomMetrics} setCustomDimensions={setCustomDimensions} setIsValid={setIsValid} setIsError={setIsError} />
        <input
          required
          id="idCliente"
          placeholder={"ex: 123456789"}
          name="idCliente"
          
          {...register("idCliente", {
            onChange: handlePropIdChange
          })}
        />
      </div>

      {
        (!isValid && !isFetching && !isError) && (
          <p id="info_id_ga4">
            Valide seu ID de propriedade antes de prosseguir
          </p>
        )
      }

      {
        isFetching && (
          <p id="info_id_ga4">
            Aguarde enquanto validamos seu ID de propriedade...
          </p>
        )
      }

      {
        isError && (
          <p id="erro_id_ga4">
            ID de propriedade inválido, verifique-o e tente novamente.
          </p>
        )
      }

      {
        (isValid && !isFetching) && (
          <>
            <label htmlFor="preDef">Relatórios Predefinidos</label>
            <select
                id="preDef"
                required
                onChange={handlePreDef}
                value={typeReport}
            >
                <option value="">
                    Selecione o tipo de relatório
                </option>
                <option value="personalizado">Relatório personalizado</option>
                {props?.preDefExtraction && props.preDefExtraction.map((option) => {
                    return (
                        <option key={option.id} value={option.name}>
                            {" "}
                            {option.name}{" "}
                        </option>
                    );
                })}
            </select>

            <label htmlFor="nameExtractions">Nome da extração</label>
            <input
              required
              id="nameExtractions"
              placeholder="Ex: Cliente_productPerformance"
              name="nameExtractions"
              {...register("nameExtractions")}
            />

            <Stack spacing={2}>
              <label htmlFor="metrics">Métricas</label>
              <Controller
                control={control}
                name="metrics"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    id="metrics"
                    required
                    multiple
                    options={customMetrics.concat(Object.values(glossary.metrics))}
                    value={metrics}
                    onChange={(_event, newMetric) => {
                      onChange(newMetric);
                      setMetrics([...newMetric]);
                      if(typeReport !== "") {
                        setTypeReport("personalizado")
                    }
                    }}
                    isOptionEqualToValue={(option, value) => option.apiName === value.apiName}
                    getOptionDisabled={() => metrics.length === 10}
                    getOptionLabel={(option) => option.uiName}  
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.uiName}
                          {...getTagProps({ index })}
                          title={option.description} 
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        classes={{ root: "MuiTextField-root" }}
                        placeholder="Adicione suas Métricas"
                        onChange={onChange}
                      />
                    )}
                  />
                )}
              />

              <label htmlFor="dimensions">Dimensões</label>
              <Controller
                control={control}
                name="dimensions"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    required
                    multiple
                    id="dimensions"
                    options={customDimensions.concat(Object.values(glossary.dimensions))}
                    getOptionLabel={(option) => option.uiName}
                    onChange={(event, newValue) => {
                      onChange(newValue);
                      setDimensions([
                        ...fixedOptions,
                        ...newValue.filter(
                          (option) => fixedOptions.findIndex(item => item.apiName === option.apiName) === -1
                        ),
                      ]);
                      if(typeReport !== "") {
                        setTypeReport("personalizado")
                    }
                    }}
                    value={dimensions}
                    getOptionDisabled={() => dimensions.length === 7}
                    isOptionEqualToValue={(option, value) => option.apiName === value.apiName}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.uiName}
                          {...getTagProps({ index })}
                          disabled={fixedOptions.findIndex(item => item.apiName === option.apiName) === 0}
                          title={option.description}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        inputProps={{ disableUnderline: true }}
                        classes={{ root: "MuiTextField-root" }}
                        {...params}
                        placeholder={"Adicione suas dimensões"}
                      />
                    )}
                  />
                )}
              />
            </Stack>

            <label htmlFor="data">Início da extração:</label>
            <input
              type="date"
              id="data"
              required
              max={new Date().toISOString().split("T")[0]}
              name="date"
              {...register("date")}
            />

            <label htmlFor="descricao">Descrição da extração</label>
            <textarea
              minLength={10}
              id="description"
              name="description"
              placeholder="Descreva brevemente sua extração."
              required
              wrap="true"
              {...register("description")}
            />

            <button disabled={!isValid} type="submit">Enviar Extração</button>
          </>
        )
      }
    </Form>
  );
}
