import axios from 'axios'

export default class APIAccessControl {
  constructor(currentUser) {
    this.currentUser = currentUser;
    this.api = axios.create({
      baseURL: process.env.REACT_APP_USER_API,
      // baseURL: 'http://localhost:8000',
    })
  }

  async listUsers() {
    return this.api.get('/users/list')
  }

  async setCustomClaim(localId, group_email, role, tester) {
    return this.api.put('/users/setrole', {
      localId,
      group_email,
      role,
      tester
    })
  }

  async listGroups() {
    const response = await this.api.get('/groups/list')
    return response
  }

  async listGroupMembers(groupKey) {
    if (groupKey === '') {
      return []
    }
    const response = await this.api.get(`/groups/member/list/${groupKey}`)
    return response
  }

  async removeMember(groupKey, memberKey) {
    const response = await this.api.delete(`/groups/member/delete/${groupKey}/${memberKey}`)
    return response
  }

  async inserMember(member) {
    const response = await this.api.post('/groups/member/insert', member)
    return response
  }

  async listGroupClients(groupKey) {
    if (groupKey === '') {
      return []
    }
    const response = await this.api.get(`/clients/list/${groupKey}`)
    return response
  }

  async removeClient(groupKey, clientId) {
    const response = this.api.delete(`/clients/remove/${groupKey}/${clientId}`)
    return response
  }

  async revokeDatasetAccess(dataAccess) {
    const response = this.api.put('/dataset/revoke', dataAccess)
    return response
  }

  async grantDatasetAccess(dataAccess) {
    const response = this.api.put('/dataset/grant', dataAccess)
    return response
  }

  async addClient(groupKey, client) {
    const response = this.api.post(`/clients/insert/${groupKey}`, client)
    return response
  }

  async createGroup(groupKey, groupName, userEmail, members) {
    const response = await this.api.post('/groups/create', {
      group: {
        email: groupKey,
        name: groupName
      },
      owner: {
        groupKey: groupKey,
        email: userEmail,
        role: "OWNER"
      },
      members: members
    })
  }

  async setGroupEmail(localId, groupKey, role, tester){
    const response = await this.api.put('/users/setgroupemail', {
      uid: localId,
      group_email: groupKey,
      role,
      tester
    })
    return response
  }

}