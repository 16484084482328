import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../contexts/AuthProvider";
import API from '../services/api'

import Navbar from "../sessions/Navbar"
import HeaderSession from "../sessions/HeaderSession"
import Loading from "../components/Loading";

import FeedbackTable from '../components/FeedbackTable'
import ModalControllerFeedback from "../sessions/ModalControllerFeedback";

import ReportsTable from '../components/ReportsTable'
import ModalControllerReports from "../sessions/ModalControllerReports";

import styled from "styled-components"

import reportsIcon from '../images/reportsIcon.svg'
import feedbackIcon from '../images/feedbackIconBlack.svg'

export default function Controller() {
  const [modalFeedbackState, setModalFeedbackState] = useState(false)
  const [modalFeedbackData, setModalFeedbackData] = useState('')
  const [feedbacks, setFeedbacks] = useState([])
  const [isShownFeedbacks, setIsShownFeedbacks] = useState(true)

  const [reports, setReports] = useState([])
  const [modalReportState, setModalReportState] = useState(false)
  const [modalReportData, setModalReportData] = useState('')
  const [isShownReports, setIsShownReports] = useState(false)

  const [menuState, setMenuState] = useState(true);
  const [extractioLoad, setExtractionLoad] = useState(true)
  const [search, setSearch] = useState("");
  const [clicked, setClicked] = useState('');

  const { currentUser } = useContext(AuthContext);
  const api = new API(currentUser)

  const sortDataFeedback = () => {
    return [...feedbacks].sort((a, b) => {
      return (
        Date.parse(a["createdAt"]) === Date.parse(b["createdAt"])
          ? 0
          : Date.parse(a["createdAt"]) > Date.parse(b["createdAt"])
            ? -1
            : Date.parse(a["createdAt"]) < Date.parse(b["createdAt"])
              ? 1
              : 0
      )
    });
  };

  const filterFeedback = sortDataFeedback()?.filter((feedback) => {
    return feedback.author.toLowerCase().includes(search)
  })

  const sortDataReports = () => {
    return [...reports].sort((a, b) => {
      return (
        Date.parse(a["createdAt"]) === Date.parse(b["createdAt"])
          ? 0
          : Date.parse(a["createdAt"]) > Date.parse(b["createdAt"])
            ? -1
            : Date.parse(a["createdAt"]) < Date.parse(b["createdAt"])
              ? 1
              : 0
      )
    });
  };

  const filterReports = sortDataReports()?.filter((report) => {
    return report.author.toLowerCase().includes(search)
  })


  function fetchFeedbacks() {
    api
      .listFeedbacks()
      .then((response) => {
        setFeedbacks(response.data.data)
        setExtractionLoad(false)
      })
      .catch((error) => console.log(error))
  }

  function fetchReports() {
    api
      .listReports()
      .then((response) => {
        setReports(response.data.data)
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    fetchFeedbacks()
    fetchReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (e) => {
    setIsShownReports(current => !current)
    setIsShownFeedbacks(current => !current)
    setModalFeedbackState(false)
    setModalReportState(false)
    setSearch('')
    setClicked('')
  }

  function handleText() {
    switch (isShownFeedbacks === true && isShownReports === true) {
      case isShownFeedbacks:
        return 'Feedbacks'
      case isShownReports:
        return 'Reports'
      default:
        break;
    }
  }

  function handleImg() {
    switch (isShownFeedbacks === true && isShownReports === true) {
      case isShownFeedbacks:
        return feedbackIcon
      case isShownReports:
        return reportsIcon
      default:
        break;
    }
  }

  return (
    <Container>
      <Navbar
        menuState={menuState}
        setMenuState={setMenuState} />

      <Main>
        <HeaderSession
          text="Controle"
          setSearch={setSearch}
          search
        />

        <MainButton>
          <Button onClick={() => handleClick()}>
            <Img src={handleImg()} alt="" />
            {handleText()}
          </Button>
        </MainButton>

        {
          isShownFeedbacks && (
            extractioLoad ?
              (
                <Loading />
              ) :
              (
                <FeedbackTable
                  feedback={filterFeedback}
                  clicked={clicked}
                  setClicked={setClicked}
                  setMenuState={setMenuState}
                  setModalFeedbackState={setModalFeedbackState}
                  setModalFeedbackData={setModalFeedbackData}
                />
              )
          )
        }
        {isShownFeedbacks && !extractioLoad && filterFeedback.length === 0 && (
          <BoxInfo>
            <TextInfo>Não há feedbacks para exibir!</TextInfo>
          </BoxInfo>
        )}

        {
          isShownReports && (
            extractioLoad ?
              (
                <Loading />
              ) :
              (
                <ReportsTable
                  report={filterReports}
                  clicked={clicked}
                  setClicked={setClicked}
                  setMenuState={setMenuState}
                  setModalReportData={setModalReportData}
                  setModalReportState={setModalReportState}
                />
              )
          )
        }
        {isShownReports && !extractioLoad && filterReports.length === 0 && (
          <BoxInfo>
            <TextInfo>Não há reports para exibir!</TextInfo>
          </BoxInfo>
        )}
      </Main>

      <ModalControllerFeedback
        clicked={clicked}
        setClicked={setClicked}
        modalFeedbackState={modalFeedbackState}
        setModalFeedbackState={setModalFeedbackState}
        modalFeedbackData={modalFeedbackData}
        setMenuState={setMenuState}
      />

      <ModalControllerReports
        clicked={clicked}
        setClicked={setClicked}
        setMenuState={setMenuState}
        modalReportState={modalReportState}
        setModalReportState={setModalReportState}
        modalReportData={modalReportData}
      />

    </Container>
  )
}

const Container = styled.div`
  height: 100vh;

  background-color: #242424;

  display: flex;
`;

const Main = styled.div`
  width: ${(props) => {
    if (
      props.menuState &&
      (props.modalReportState || props.modalFeedbackState)
    ) {
      return "50vw";
    }

    if (
      !props.menuState &&
      (props.modalReportState || props.modalFeedbackState)
    ) {
      return "70vw";
    }

    if (
      props.menuState &&
      (!props.modalReportState || !props.modalFeedbackState)
    ) {
      return "80vw";
    }

    if (
      !props.menuState &&
      (!props.modalReportState || !props.modalFeedbackState)
    ) {
      return "100vw";
    }
  }};

  height: 90vh;

  background-color: #333333;

  margin: auto 2vw;

  border-radius: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

const MainButton = styled.div`
  width: 95%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  right: 35px;
  bottom: 50px;

  @media (max-width: 1500px) {
    bottom: 20px;
    right: 30px;
  }

`

const Button = styled.button`
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: bold;
  font-size: 15px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 14px;
  gap: 12px;
  text-align: center;
  cursor: pointer;

  justify-content:center;

  width: 170px;
  height: 38px;

  /* secondary/500 */

  background: #F6BE27;
  border-radius: 4px;
  border: none;

  &:hover{
    background-color: #fdad17;
    transition: 0.5s;
  }

`

const Img = styled.img`
  width: 22px;
  height: 22px;

  @media (max-width: 1500px) {
    width: 15px;
    height: 15px
  }
`

const BoxInfo = styled.div`
    height: 200%;
    justify-content: center;
    align-items: center; 
`

const TextInfo = styled.p`
    opacity: 0.4;
    color: #FFF;
    font-size: 16px;
    font-family: HelveticaNowforMonksBold;
`