import React from 'react'
import styled from 'styled-components'
//import { useNavigate } from "react-router-dom";

import InfosIcon from '../images/InfosIcon.svg'

import InfoButton from './InfoButton'

function Card(props) {

  //const navigate = useNavigate()

  const rules = {
    noticia: {
      text: "Notícia",
      backgroundColor: "#1F82E4"
    },
    importante: {
      text: "Importante",
      backgroundColor: "#F6BE27",

    },
    critico: {
      text: "Crítico",
      backgroundColor: "#E72121"
    }
  }

  return (
    <Container
      key={props.id}
      setMenuState={props.setMenuState}
      setClicked={props.setClicked}
      clicked={props.clicked}
      setModalNewsState={props.setModalNewsState}
      setModalNewsData={props.setModalNewsData}
      filterNews={props.filterNews}

      onClick={() => {
        props.setMenuState(false)
        props.setModalNewsState(true)
        props.setModalNewsData(props.filterNews)
        props.setClicked(props.id)
        props.setModalExtractionFormState(false)
      }}
    >
      <CardHeader>
        <Img src={InfosIcon}/>
        <CardTitle>{props.principalTitle}</CardTitle>

      </CardHeader>
      <CardFooter>
        <CardText>{props.title}</CardText>
        <CardSectionInfos>
          <CardText>{props.date}</CardText>
          <InfoButton
            text={rules[props.text]?.text}
            backgroundColor={rules[props.backgroundColor]?.backgroundColor}
            fontColor={'#242424'}
            fontSize={'14px'}
          />
        </CardSectionInfos>
      </CardFooter>
    </Container>
  )
}

export default Card;

const Container = styled.div`
  height: 100%;

  background-color: #242424;

  border-radius: 20px;

  flex: auto;

  padding: 8px;

  cursor: pointer;
`

const CardHeader = styled.div`

  width: 600px;

  margin-top: 1%;
  margin-left: 2.5%;
  margin-bottom: 0.5%;
  display: flex;
  align-items: center;


  @media(max-width: 1300px){
    width: auto;
    margin-top: 0.3%;
    margin-bottom: -1%;
  }

`

const Img = styled.img`
  //object-fit: cover;  

  width: 25px;
  height: 25px;

  //border-radius: 50%;

  @media(max-width: 1300px){
    width: 20px;
    height: 20px;
  }

`

const CardTitle = styled.h1`
  /* M3rd/Title 5/Heavy */
  font-family: 'HelveticaNowforMonksExtraBold', sans-serif;
  font-size: 17px;

  color: #ACACAC;

  margin-left: 20px;

  @media(max-width: 1300px){
    font-size: 15px;
  }
`

const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CardSectionInfos = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  right: 30px;
  bottom: 25px;
  gap: 8px;

  @media(max-width: 1300px){
    right: 30px;
    bottom: 16px;
    gap: 8px;

  }
`

const CardText = styled.div`
  color: #FFFFFF;
  font-family: 'HelveticaNowforMonksBold';
  font-size: 16px;
  text-align: justify;

  margin: 10px 32px 0px;

  @media(max-width: 1300px){
    margin: 10px 22px 0px;
    font-size: 14px;
  }
`
