import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import APIAccessControl from '../services/apiAccessControl';

import { auth } from "../config/firebase";

const provider = new GoogleAuthProvider();

provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
provider.addScope("https://www.googleapis.com/auth/userinfo.email");
provider.setCustomParameters({ hd: "rocky.ag" });

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const api = new APIAccessControl('')

  const [currentUser, setCurrentUser] = useState(null);

  const login = async () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
//        console.log(result)
        if (!result.user.reloadUserInfo.customAttributes) {
          await api.setCustomClaim(result.user.uid, '', 'user', 'false')
          navigate("/");
        } else {
          setCurrentUser(result.user);
          navigate("/homeNews");
        }
      })
      .catch ((error) => {
  console.log(error);
});
  };

const logout = async () => {
  signOut(getAuth())
    .then(() => {
      navigate("/");
    })
    .catch((error) => {
      console.log(error);
    });
};

return (
  <AuthContext.Provider
    value={{
      auth: !!currentUser,
      customClaims: currentUser?.reloadUserInfo?.customAttributes,
      currentUser,
      login,
      logout,
    }}
  >
    {children}
  </AuthContext.Provider>
);
};

export default AuthProvider;