import styled from "styled-components";
export default function ActionButton(props) {
  function handleSetClick() {
    if (props.setButtonActive) {
      if (props.buttonActive === props.buttonId) {
        props.setButtonActive("");
      } else {
        props.setButtonActive(props.buttonId);
      }
    }

    if (props.buttonId === "submitTransfer") {
      if (
        !props.textInput.includes("@mediamonks.com") &&
        !props.textInput.includes("@rocky.ag")
      ) {
        props.setModalController("email null");
      } else {
        props.setModalController("transfer");

        props.setExtractionId(props.extractionId);
        props.setExtractionName(props.extractionName);
        props.setPreviousAuthor(props.author);
        props.setText(props.textInput);
        props.setButtonActive("");
      }
    } else if (props.buttonId === "submitReport") {
      if (props.textInput === "") {
        props.setModalController("report null");
      } else {
        props.setExtractionId(props.extractionId);
        props.setAuthor(props.author);
        props.setText(props.textInput);
        props.setModalController("report");
        props.setAction("report");
        props.setButtonActive("");
      }
    } else if (props.buttonId === "active") {
      props.setModalController("active");
      props.setExtractionId(props.extractionId);
      props.setExtractionName(props.extractionName);
    } else if (props.buttonId === "disable") {
      props.setModalController("disable");
      props.setExtractionId(props.extractionId);
      props.setExtractionName(props.extractionName);
    } else if (props.buttonId === "renew") {
      props.setModalController("renew");
      const dateRenew = new Date(props.expirationDate);
      dateRenew.setMonth(dateRenew.getMonth() + 3);
      props.setNewDate(dateRenew.toISOString().slice(0, 10));
      props.setExtractionId(props.extractionId);
    } else if (props.buttonId === "delete") {
      if (props.activeDeactive) {
        props.setModalController("active extraction");
      } else {
        props.setModalController("delete");
        props.setExtractionId(props.extractionId);
        props.setExtractionName(props.extractionName);
      }
    } else if (props.buttonId === "view") {
      window.open(`${props.linkTo}`, "_blank");
      props.setButtonActive("");
    }
  }
  function handleColorButton() {
    if (props.buttonActive === props.buttonId) {
      return "#0d5eff";
    } else {
      if (props.text === "Renovar") {
        return "#FEC84B";
      } else if (props.text === "Excluir") {
        return "#F04438";
      } else {
        return "#242424";
      }
    }
  }
  return (
    <Button
      onClick={() => {
        handleSetClick();
      }}
      backgroundColor={handleColorButton}
      report={props.report}
      colorHover={props.colorHover}
    >
      {props.icon && <Img src={props.icon}></Img>}
      <ButtonText color={props.color}>{props.text}</ButtonText>
    </Button>
  );
}

const Button = styled.button`
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  gap: 12px;

  background: ${(props) => props.backgroundColor};
  border-radius: 4px;

  border: none;

  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.colorHover ? props.colorHover : "#0d5eff"};
    transition: 0.3s;
  }

  @media (max-width: 1300px) {
    height: 30px;
    gap: 7px;
  }
`;

const ButtonText = styled.p`
  font-family: "HelveticaNowforMonksMedium";
  font-style: normal;
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : "#FFFFFF")};

  @media (max-width: 1300px) {
    font-size: 13px;
  }
`;

const Img = styled.img`
  width: 20px;
  position: relative;

  @media (max-width: 1300px) {
    width: 15px;
  }
`;
