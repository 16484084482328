import React, { useContext } from "react";
import styled from "styled-components";

import wave from "../images/wave.svg";
import curve from "../images/curve.svg";

import { AuthContext } from "../contexts/AuthProvider";

function AuthPage() {
  const { login } = useContext(AuthContext);

  return (
    <Container onClick={login}>
      <TextContainer>
        <Title>VOLDEMORT</Title>
        <SubTitle>CLIQUE NA TELA PARA LOGAR</SubTitle>
      </TextContainer>

      <Img src={wave} />
      <Img src={curve} />
    </Container>
  );
}

export default AuthPage;

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;

  background-color: #f6be27;
  padding: 0;
  position: relfative;

  cursor: pointer;
`;

const TextContainer = styled.div``;

const Title = styled.h1`
  font-family: HelveticaNowforMonksExtraBold;
  font-size: 8rem;

  margin-top: 25vh;
  margin-bottom: 0;
`;

const SubTitle = styled.p`
  font-family: HelveticaNowforMonksExtraBold;
  font-size: 1.25rem;

  text-align: right;

  margin-top: 0vh;
`;

const Img = styled.img`
  position: absolute;
  bottom: 0;
`;
