import styled from "styled-components";
import hourIcon from '../images/hourIcon.svg'
export default function DateCheck(props) {
	return (
		<DateBox>
			<DateImg src={hourIcon} />
			<DateText>{props.date} {props.info}</DateText>
		</DateBox>
	)
}

const DateBox = styled.div`
	display: flex;
	gap: 11.15px;

	@media (max-width: 1300px) {
		gap: 8px;
  }

`

const DateText = styled.p`
	/* M3rd/Paragraph 1/Strong */
	font-family: 'Space Grotesk';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 36px;
	/* identical to box height, or 200% */

	display: flex;
	align-items: center;

	/* shades/0 */

	color: #FFFFFF;


	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;

@media (max-width: 1300px) {
    font-size: 14px;
  }
`

const DateImg = styled.img`
  width: 17.71px;

  @media (max-width: 1300px) {
    width: 13px;
  }
`