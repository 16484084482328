import styled from 'styled-components'

export const ContainerCenter = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  max-width: 100%;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 4px;
`;

export const FormSectionHeader = styled.div`
  display: grid;
  grid-template-columns: 6fr 4fr 4fr 1fr;
  place-items: center;
  font-weight: bold;
  padding: 16px;
`;

export const FormSectionRow = styled.div`
  display: grid;
  grid-template-columns: 6fr 4fr 4fr 1fr;
  background-color: lightgrey;
  border-radius: 4px;
  padding: 16px;
  font-weight: bold;
  align-items: center;
`;

export const Hidden = styled.span`
  visibility: hidden;
`;

export const FormSectionLabel = styled.span`
  text-align: center;
  color: #fff;
`

export const Spacer = styled.span`
  width: 72px;
  text-align: center;
  color: #fff;
`

export const FormSectionTitle = styled.span`
  max-width: 90%;
  font-weight: bold;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
`

export const Comment = styled.textarea`
  background-color: lightgrey;
  border-radius: 4px;
  grid-column: 1/-1;
  padding: 8px;
  font-family: "HelveticaNowforMonksMedium";
  font-size: 16px;
  resize: vertical;
  margin-top: 16px;
  height: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #242424;
    border-radius: 12px;
  }
`

export const Radio = styled.input`
    width: 24px;
    height: 24px;
    justify-self: center;
    align-self: center;
    cursor: pointer;
`

export const ButtonOpen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;

  width:25px;
  height:25px;

  cursor: pointer;

  border-radius: 100%;
  border: 2px solid white;
`
export const ButtonComment = styled.button`
  width: 72px;
  display: flex;
  font-weight: bold;
  color: #fff;
  padding: 16px;
  border-radius: 8px;
  background-color: #f6be27;
  border: none;
  &:hover {
    background-color: #daa209; 
  };
  transition: all 0.3s ease-out;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 20px;
`

export const Icon = styled.img`
  width: 24px;
  pointer-events: none;
`