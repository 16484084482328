export class LocalStorage {
    static getHeadAnswers() {
        const item = "head_answers"
        const local = JSON.parse(window.localStorage.getItem(item))
        return local
    }

    static getManagerAnswers() {
        const item = "manager_answers"
        const local = JSON.parse(window.localStorage.getItem(item))
        return local
    }

    static getCoordAnswers() {
        const item = "coords_forms_answers"
        const local = JSON.parse(window.localStorage.getItem(item))
        return local
    }

    static createHeadObjectModel(head, area, coord) {
        const item = "head_answers"
        const local = JSON.parse(window.localStorage.getItem(item))

        if (local === null) {
            window.localStorage.setItem(item, JSON.stringify({}))
        }

        if (JSON.parse(window.localStorage.getItem(item))[head] === undefined) {
            window.localStorage.setItem(item, JSON.stringify({
                ...JSON.parse(window.localStorage.getItem(item)),
                [head]: {}
            }))
        }

        if (JSON.parse(window.localStorage.getItem(item))[head][area] === undefined) {
            window.localStorage.setItem(item, JSON.stringify({
                ...JSON.parse(window.localStorage.getItem(item)),
                [head]: {
                    ...JSON.parse(window.localStorage.getItem(item))[head],
                    [area]: {}
                }
            }))
        }

        if (JSON.parse(window.localStorage.getItem(item))[head][area][coord] === undefined) {
            window.localStorage.setItem(item, JSON.stringify({
                ...JSON.parse(window.localStorage.getItem(item)),
                [head]: {
                    ...JSON.parse(window.localStorage.getItem(item))[head],
                    [area]: {
                        ...JSON.parse(window.localStorage.getItem(item))[head][area],
                        [coord]: {
                            "resultados": ['', ''],
                            "comunicacao_com_o_cliente": ['', ''],
                            "satisfacao_do_cliente": ['', ''],
                            "comunicacao_liderado_cliente": ['', ''],
                            "comunicacao_lideranca_cliente": ['', ''],
                            "gargalos_nosso_lado_que_impactam_no_trabalho": ['', ''],
                            "gargalos_lado_cliente_que_impactam_no_trabalho": ['', ''],
                            "escopo_e_operacao": ['', ''],
                            "novas_oportunidades": ['', ''],
                            "apresenta_risco_de_saida": ['', '']
                        }
                    }
                }
            }))
        }
    }

    static createCoordsFormsObjectModel(head, area, coord) {
        const item = "coords_forms_answers"
        const local = JSON.parse(window.localStorage.getItem(item))

        if (local === null) {
            window.localStorage.setItem(item, JSON.stringify({}))
        }

        if (JSON.parse(window.localStorage.getItem(item))[head] === undefined) {
            window.localStorage.setItem(item, JSON.stringify({
                ...JSON.parse(window.localStorage.getItem(item)),
                [head]: {}
            }))
        }

        if (JSON.parse(window.localStorage.getItem(item))[head][area] === undefined) {
            window.localStorage.setItem(item, JSON.stringify({
                ...JSON.parse(window.localStorage.getItem(item)),
                [head]: {
                    ...JSON.parse(window.localStorage.getItem(item))[head],
                    [area]: {}
                }
            }))
        }

        if (JSON.parse(window.localStorage.getItem(item))[head][area][coord] === undefined) {
            window.localStorage.setItem(item, JSON.stringify({
                ...JSON.parse(window.localStorage.getItem(item)),
                [head]: {
                    ...JSON.parse(window.localStorage.getItem(item))[head],
                    [area]: {
                        ...JSON.parse(window.localStorage.getItem(item))[head][area],
                        [coord]: {
                            "time_esta_bem": ["", ""],
                            "riscos_relacionados_a_pessoas": ["", ""],
                            "minimos_de_gestao_estao_sendo_cumpridos": ["", ""]
                        }
                    }
                }
            }))
        }
    }

    static createManagerObjectModel(manager, head, area, coord) {
        const item = "manager_answers"
        const local = JSON.parse(window.localStorage.getItem(item))

        if (local === null) {
            window.localStorage.setItem(item, JSON.stringify({}))
        }

        if (JSON.parse(window.localStorage.getItem(item))[manager] === undefined) {
            window.localStorage.setItem(item, JSON.stringify({
                ...JSON.parse(window.localStorage.getItem(item)),
                [manager]: {}
            }))
        }

        if (JSON.parse(window.localStorage.getItem(item))[manager][head] === undefined) {
            window.localStorage.setItem(item, JSON.stringify({
                ...JSON.parse(window.localStorage.getItem(item)),
                [manager]: {
                    ...JSON.parse(window.localStorage.getItem(item))[manager],
                    [head]: {}
                }
            }))
        }

        if (JSON.parse(window.localStorage.getItem(item))[manager][head][area] === undefined) {
            window.localStorage.setItem(item, JSON.stringify({
                ...JSON.parse(window.localStorage.getItem(item)),
                [manager]: {
                    ...JSON.parse(window.localStorage.getItem(item))[manager],
                    [head]: {
                        ...JSON.parse(window.localStorage.getItem(item))[manager][head],
                        [area]: {}
                    }
                }
            }))
        }

        if (JSON.parse(window.localStorage.getItem(item))[manager][head][area][coord] === undefined) {
            window.localStorage.setItem(item, JSON.stringify({
                ...JSON.parse(window.localStorage.getItem(item)),
                [manager]: {
                    ...JSON.parse(window.localStorage.getItem(item))[manager],
                    [head]: {
                        ...JSON.parse(window.localStorage.getItem(item))[manager][head],
                        [area]: {
                            ...JSON.parse(window.localStorage.getItem(item))[manager][head][area],
                            [coord]: {
                                "comentario": ""
                            }
                        }
                    }
                }
            }))
        }
    }

    static createShowManagerCommentsObjectModel(coord) {
        const item = "show_manager_comments"
        let local = JSON.parse(window.localStorage.getItem(item))

        if (!local) {
            window.localStorage.setItem(item, JSON.stringify({ show: {} }))
            local = JSON.parse(window.localStorage.getItem(item))
        }

        if (!local.show[coord]) {
            window.localStorage.setItem(item, JSON.stringify({
                ...local,
                show: {
                    ...local.show,
                    [coord]: false
                }
            }))
        }
    }

    static createShowHeadCommentsObjectModel(coord) {
        const item = "show_head_comments"
        let local = JSON.parse(window.localStorage.getItem(item))


        if (!local) {
            window.localStorage.setItem(item, JSON.stringify({ show: {} }))
            local = JSON.parse(window.localStorage.getItem(item))
        }

        if (!local.show.hasOwnProperty(coord)) {
            window.localStorage.setItem(item, JSON.stringify({
                ...local,
                show: {
                    ...local.show,
                    [coord]: {
                        "resultados": false,
                        "comunicacao_com_o_cliente": false,
                        "satisfacao_do_cliente": false,
                        "comunicacao_liderado_cliente": false,
                        "comunicacao_lideranca_cliente": false,
                        "gargalos_nosso_lado_que_impactam_no_trabalho": false,
                        "gargalos_lado_cliente_que_impactam_no_trabalho": false,
                        "escopo_e_operacao": false,
                        "novas_oportunidades": false,
                        "apresenta_risco_de_saida": false
                    }
                }
            }))
        }
    }

    static createShowCoordCommentsObjectModel(coord) {
        const item = "show_coord_comments"
        let local = JSON.parse(window.localStorage.getItem(item))

        if (!local) {
            window.localStorage.setItem(item, JSON.stringify({ show: {} }))
            local = JSON.parse(window.localStorage.getItem(item))
        }

        if (!local.show.hasOwnProperty(coord)) {
            window.localStorage.setItem(item, JSON.stringify({
                ...local,
                show: {
                    ...local.show,
                    [coord]: {
                        "time_esta_bem": false,
                        "riscos_relacionados_a_pessoas": false,
                        "minimos_de_gestao_estao_sendo_cumpridos": false
                    }
                }
            }))
        }
    }

    static setNewHeadAnswer(head, area, coord, question, answer) {
        const local = JSON.parse(window.localStorage.getItem("head_answers"))

        window.localStorage.setItem("head_answers", JSON.stringify({
            ...local,
            [head]: {
                ...local[head],
                [area]: {
                    ...local[head][area],
                    [coord]: {
                        ...local[head][area][coord],
                        [question]: [answer, local[head][area][coord][question][1]]
                    }
                }
            }
        }))

        window.dispatchEvent(new Event("storageChange"))
    }

    static setNewCoordAnswer(head, area, coord, question, answer) {
        const local = JSON.parse(window.localStorage.getItem("coords_forms_answers"))

        window.localStorage.setItem("coords_forms_answers", JSON.stringify({
            ...local,
            [head]: {
                ...local[head],
                [area]: {
                    ...local[head][area],
                    [coord]: {
                        ...local[head][area][coord],
                        [question]: [answer, local[head][area][coord][question][1]]
                    }
                }
            }
        }))

        window.dispatchEvent(new Event("storageChange"))
    }

    static setNewManagerAnswer(manager, head, area, coord, answer) {
        const item = "manager_answers"

        window.localStorage.setItem(item, JSON.stringify({
            ...JSON.parse(window.localStorage.getItem(item)),
            [manager]: {
                ...JSON.parse(window.localStorage.getItem(item))[manager],
                [head]: {
                    ...JSON.parse(window.localStorage.getItem(item))[manager][head],
                    [area]: {
                        ...JSON.parse(window.localStorage.getItem(item))[manager][head][area],
                        [coord]: {
                            ...JSON.parse(window.localStorage.getItem(item))[manager][head][area][coord],
                            status: answer
                        }
                    }
                }
            }
        }))

        window.dispatchEvent(new Event("storageChange"))
    }

    static setHeadComment(head, area, coord, question, comment) {
        const local = JSON.parse(window.localStorage.getItem("head_answers"))

        window.localStorage.setItem("head_answers", JSON.stringify({
            ...local,
            [head]: {
                ...local[head],
                [area]: {
                    ...local[head][area],
                    [coord]: {
                        ...local[head][area][coord],
                        [question]: [local[head][area][coord][question][0], comment]
                    }
                }
            }
        }))

        window.dispatchEvent(new Event("storageChange"))
    }

    static setCoordComment(head, area, coord, question, comment) {
        const local = JSON.parse(window.localStorage.getItem("coords_forms_answers"))

        window.localStorage.setItem("coords_forms_answers", JSON.stringify({
            ...local,
            [head]: {
                ...local[head],
                [area]: {
                    ...local[head][area],
                    [coord]: {
                        ...local[head][area][coord],
                        [question]: [local[head][area][coord][question][0], comment]
                    }
                }
            }
        }))

        window.dispatchEvent(new Event("storageChange"))
    }

    static setManagerComment(manager, head, area, coord, comment) {
        const local = JSON.parse(window.localStorage.getItem("manager_answers"))

        window.localStorage.setItem("manager_answers", JSON.stringify({
            ...local,
            [manager]: {
                ...local[manager],
                [head]: {
                    ...local[manager][head],
                    [area]: {
                        ...local[manager][head][area],
                        [coord]: {
                            ...local[manager][head][area][coord],
                            "comentario": comment
                        }
                    }
                }
            }
        }))

        window.dispatchEvent(new Event("storageChange"))
    }

    static setShowComment(coord, boolean) {
        const item = "show_manager_comments"
        const local = JSON.parse(window.localStorage.getItem(item))

        window.localStorage.setItem(item, JSON.stringify({
            ...local,
            show: {
                ...local.show,
                [coord]: boolean
            }
        }))

        window.dispatchEvent(new Event("storageChange"))
        return JSON.parse(window.localStorage.getItem(item))
    }

    static setShowHeadComments(coord, question, boolean) {
        const item = "show_head_comments"
        const local = JSON.parse(window.localStorage.getItem(item))

        window.localStorage.setItem(item, JSON.stringify({
            ...local,
            show: {
                ...local.show,
                [coord]: {
                    ...local.show[coord],
                    [question]: boolean
                }
            }
        }))

        window.dispatchEvent(new Event("storageChange"))
        return JSON.parse(window.localStorage.getItem(item))
    }

    static setShowCoordComments(coord, question, boolean) {
        const item = "show_coord_comments"
        const local = JSON.parse(window.localStorage.getItem(item))

        window.localStorage.setItem(item, JSON.stringify({
            ...local,
            show: {
                ...local.show,
                [coord]: {
                    ...local.show[coord],
                    [question]: boolean
                }
            }
        }))

        window.dispatchEvent(new Event("storageChange"))
        return JSON.parse(window.localStorage.getItem(item))
    }

    static createCollapsedHeadObj(coord) {
        const item = "collapsed_heads"
        let local = JSON.parse(window.localStorage.getItem(item))

        if (!local) {
            window.localStorage.setItem(item, JSON.stringify({}))
            local = JSON.parse(window.localStorage.getItem(item))
        }

        if (!local.hasOwnProperty(coord)) {
            window.localStorage.setItem(item, JSON.stringify({
                ...local,
                [coord]: false
            }))
        }
    }

    static createCollapsedManagersObj(head) {
        const item = "collapsed_managers"
        let local = JSON.parse(window.localStorage.getItem(item))

        if (!local) {
            window.localStorage.setItem(item, JSON.stringify({}))
            local = JSON.parse(window.localStorage.getItem(item))
        }

        if (!local.hasOwnProperty(head)) {
            window.localStorage.setItem(item, JSON.stringify({
                ...local,
                [head]: false
            }))
        }
    }

    static setCollapsedHead(coord, boolean) {
        window.localStorage.removeItem("validating")
        const item = "collapsed_heads"
        const local = JSON.parse(window.localStorage.getItem(item))

        if (!local[coord]) {
            window.localStorage.setItem(item, JSON.stringify({
                ...local,
                [coord]: false
            }))
        }

        window.localStorage.setItem(item, JSON.stringify({
            ...local,
            [coord]: boolean
        }))

        window.dispatchEvent(new Event("storageChange"))
        return JSON.parse(window.localStorage.getItem(item))[coord]
    }

    static getCollapsedHead(coord) {
        const item = "collapsed_heads"
        return JSON.parse(window.localStorage.getItem(item))[coord]
    }

    static setCollapsedManager(head, boolean) {
        window.localStorage.removeItem("validating")
        const item = "collapsed_managers"
        let local = JSON.parse(window.localStorage.getItem(item))

        if (!local.hasOwnProperty(head)) {
            window.localStorage.setItem(item, JSON.stringify({
                ...local,
                [head]: false
            }))
            local = JSON.parse(window.localStorage.getItem(item))
        }

        window.localStorage.setItem(item, JSON.stringify({
            ...local,
            [head]: boolean
        }))

        window.dispatchEvent(new Event("storageChange"))
        return JSON.parse(window.localStorage.getItem(item))[head]
    }

    static getCollapsedManager(head) {
        const item = "collapsed_managers"
        return JSON.parse(window.localStorage.getItem(item))[head]
    }
}