import { useContext, useState, useEffect } from "react";

import { useForm, Controller  } from 'react-hook-form'

import API from "../../../services/api";
import { AuthContext } from "../../../contexts/AuthProvider";

import { Form } from "./style";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

import amazonMetrics from '../../../services/amazonAdsMetrics.json'

export default function AmazonAdsExtractor(props) {
    
    const [metrics, setMetrics] = useState([])
    const [groupBy, setgroupBy] = useState([])
    const [typeReport, setTypeReport] = useState("")
    const [preDefReport, setPreDefReport] = useState([])
    const fixedOptions = ['date']

    const { register, watch, resetField, handleSubmit, reset, control, setValue } = useForm({
        defaultValues: {
            customers: '',
            nomeExtraction: '',
            reportType: '',
            groupBy: '',
            date: '',
            description: '',
            adProduct: 'SPONSORED_PRODUCTS'
        }
    })

    const { currentUser } = useContext(AuthContext);
    const api = new API(currentUser);

    const dateExpiration = new Date()
    dateExpiration.setMonth(dateExpiration.getMonth() + 6);

    const minDate = new Date()
    minDate.setMonth(minDate.getMonth() - 3)
    useEffect(() => {
        if(props.preDefExtraction !== []) {
          const extractionFiltered =  props.preDefExtraction?.filter((extraction) =>  extraction.name === typeReport)
            setPreDefReport(extractionFiltered)
        }

    }, [typeReport])
    useEffect(() => {
        if(preDefReport.length !== 0) {
            setMetrics(preDefReport[0].queryParams.configuration.columns)
            setValue("adProduct", preDefReport[0].queryParams.configuration.adProduct)
        }
    }, [preDefReport])
    function selectGroupBy(reportType,value, adProduct = 'SPONSORED_PRODUCTS') {
        let keys = amazonMetrics['reportType'][reportType][adProduct]['groupBy']
        keys.forEach((group) => {
            let groupKey = amazonMetrics['reportType'][reportType][adProduct][group]
            if(groupBy.includes(group)) return
            if(groupKey.includes(value)) {
                setgroupBy([...groupBy, group])
            } 
        })
    }

    function handleReportType() {
        let arrayReport = [];
        Object.keys(amazonMetrics['reportType']).forEach((item) => arrayReport.push(item))
        return arrayReport
    }
    
    function handleReportyTypeId(reportType, adProduct = 'SPONSORED_PRODUCTS') {
        let keys = amazonMetrics['reportType'][reportType][adProduct]['reportTypeId']
        return keys
    }

    function handleMetrics(reportType, adProduct = 'SPONSORED_PRODUCTS') { 
        let keys = amazonMetrics['reportType'][reportType][adProduct]['columns']
        return keys
    }

    function handleGroupBy(reportType, adProduct = 'SPONSORED_PRODUCTS') {
        let keys = amazonMetrics['reportType'][reportType][adProduct]['groupBy']
        return keys
    }

    function handleAdProduct(reportType, adProduct) {
        let keys = Object.keys(amazonMetrics['reportType'][reportType])
        return keys
    }

    function completeExtraction() {
        Array.from(document.querySelectorAll("input"))
            .forEach((input) => (input.value = ""))

        Array.from(document.querySelectorAll("select"))
            .forEach((option) => (option.value = ""))

        Array.from(document.querySelectorAll("textarea"))
            .forEach((textarea) => (textarea.value = ""))

        reset()
    }

    function platformPayload(bodyAmazonAds) {
        return bodyAmazonAds
    }

    function formatWords(word) {
        const wordFormat = word
          .normalize("NFD")
          .replace(/ /g, "_")
          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z-_])/g, "")
          .replace("-", "_")
          .toLowerCase();
        return wordFormat;
    }

    const sendExtraction = event => {
        props.setModalController("create extraction")

        const bodyAmazonAds = {
            metadata: {
                extractionName: formatWords(event.nomeExtraction),
                author: currentUser.email,
                customer: formatWords(event.customers),
                creationDate: new Date().toISOString(),
                expirationDate: dateExpiration.toISOString().slice(0, 10),
                consolidationWindow: 1,
                precisionInterval: 1,
                extractorModule: 'amazonAdsExtractor',
                startDate: event.date,
            },
            queryParams: {
                profileId: event.profileId,
                name: event.nomeExtraction.replace(/-/g, ""),
                startDate: event.date,
                endDate:  new Date(),
                configuration: {
                    adProduct: event.adProduct,
                    groupBy: groupBy,
                    columns: metrics,
                    reportTypeId: handleReportyTypeId(watch('reportType'), watch('adProduct')),
                    timeUnit: "DAILY",
                    format: "GZIP_JSON"
                }
            },
            destination: {
                projectId: process.env.REACT_APP_DEFAULT_PROJECT_BQ,
                datasetId: formatWords(event.customers),
                tableId: `${'amazonAdsExtractor'}-${(formatWords(event.customers))}-${formatWords(event.nomeExtraction)}`,
                description: event.description,
            },
        };

        if(groupBy.length === 0) {
            bodyAmazonAds.queryParams.configuration.groupBy = [handleGroupBy(watch('reportType'), watch('adProduct'))[0]] 
        }

        api
            .createDailyExtraction(platformPayload(bodyAmazonAds))
            .then(() => {
                props.setModalController("create extraction complete")
                completeExtraction()
            })
            .catch((er) => {
                if (er.request.status === 400) {
                    props.setNewError(er.data.error);
                    props.setModalController("error campo");
                } else {
                    props.setModalController("error report");
                }
            });
    }

    useEffect(() => {
        reset({
            customers: '',
            nomeExtraction: '',
            reportType: '',
            adProduct: 'SPONSORED_PRODUCTS',
            groupBy: '',
            columns: '',
            date: '',
            description: ''
        }, [clearSelected()])
    }, [props.platform, reset])

    useEffect(() => {
        resetField('adProduct')
        clearSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('reportType')])

    useEffect(() => {
        clearSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('adProduct')])

    const clearSelected = () => {
        setMetrics([]);
        setgroupBy([])
    };
    function handlePreDef(e) {
        e.preventDefault();
        setTypeReport(e.target.value);
    }
    return (
        <Form onSubmit={handleSubmit(sendExtraction)}>
           {props?.preDefExtraction.length !== 0 && <><label htmlFor="preDef">Relatórios Predefinidos</label>
            <select
                id="preDef"
                required
                onChange={handlePreDef}
                value={typeReport}
            >
                <option value="">
                    Selecione o tipo de relatório
                </option>
                <option value="personalizado">Relatório personalizado</option>
                {props?.preDefExtraction && props.preDefExtraction.map((option) => {
                    return (
                        <option key={option.id} value={option.name}>
                            {" "}
                            {option.name}{" "}
                        </option>
                    );
                })}
            </select></>}
            <label htmlFor="cliente">Cliente</label>
            <select id="cliente"
                {...register("customers")}
                required
            >
                <option value="">
                    Escolha o nome do cliente.
                </option>
                {props.clients !== undefined && props.clients.map((option) => {
                    return (
                        <option key={option.id} value={option.name}
                        >
                            {" "}
                            {option.name}{" "}
                        </option>
                    );
                })}
            </select>

            <label htmlFor="profileId">Profile ID</label>
            <input
                required
                id="profileId"
                placeholder="Ex: 1234567890"
                name="profileID"
                {...register("profileId")}
            />

            <label htmlFor="nomeExtraction">Nome da extração</label>
            <input
                required
                id="nomeExtraction"
                placeholder="Ex: Cliente_productPerformance"
                name="nomeExtraction"
                {...register("nomeExtraction")}
            />

            <label htmlFor="reportType">Tipo de Relatório</label>
            <select 
                id="reportType" 
                {...register("reportType")} 
                required>
                <option value="">Escolha o tipo de relatório</option>
               {
                handleReportType(watch('reportType')).map((option) => {
                    return (
                        <option key={option}>{option}</option>
                    )
                })
               }
            </select>

           { 
           watch('reportType') !== ''  &&
           <>
            {watch('reportType') === 'purchased_product' && <><label htmlFor="adProduct">Ad Product</label>
            <select 
                id="adProduct" 
                {...register("adProduct")} 
                required>
                <option value="">Escolha o tipo de ad product</option>
               {
                handleAdProduct(watch('reportType'))?.map((option) => {
                    return (
                        <option key={option} value={option}>{option}</option>
                    )
                })
               }
            </select></>}

           {watch('adProduct') !== '' && 
           <>
                <Stack spacing={2}>
                    <label htmlFor="metrics">Métricas</label>
                    <Controller
                        control={control}
                        name="metrics"
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                id="metrics"
                                required
                                multiple
                                options={watch('reportType') === 'purchased_product' ? handleMetrics(watch('reportType'), watch('adProduct')): handleMetrics(watch('reportType'))}
                                value={metrics}
                                onChange={(event, newMetric) => {
                                    onChange(newMetric);
                                    selectGroupBy(watch('reportType'), event.target.innerHTML, watch('adProduct'))
                                    setMetrics([
                                        ...fixedOptions,
                                        ...newMetric.filter((option) => fixedOptions.indexOf(option) === -1)
                                    ]);
                                }}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option}
                                            {...getTagProps({ index })}
                                            disabled={fixedOptions.indexOf(option) === 0}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        classes={{ root: "MuiTextField-root" }}
                                        placeholder="Adicione seus Métricas"
                                        onChange={onChange}
                                    />
                                )}
                            />
                        )}
                    />
                </Stack>

                <label htmlFor="date">Início da extração:</label>
                <input
                    type="date"
                    id="data"
                    required
                    min = {minDate.toISOString().split("T")[0]}
                    max={new Date().toISOString().split("T")[0]}
                    name="date"
                    {...register("date")}
                />

                <label htmlFor="description">Descrição da extração</label>
                <textarea
                    minLength={10}
                    id="description"
                    name="description"
                    placeholder="Descreva brevemente sua extração."
                    required
                    wrap="true"
                    {...register("description")}
                />

                <button type="submit">Enviar Extração</button> </> }
            </>
        }
        </Form>
    )
}