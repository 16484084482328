import React, { useState, useEffect, useContext } from 'react'
import { Box, CircularProgress, Fab } from '@mui/material'
import { Check, Save } from '@mui/icons-material'
import { amber } from '@mui/material/colors'

import { AuthContext } from '../../contexts/AuthProvider';
import APIAccessControl from '../../services/apiAccessControl';

function UsersActions({ user, rowId, setRowId, setOnEdit, selectedRole, setSelectedRole }) {

  const { currentUser } = useContext(AuthContext);

  const customClaims = JSON.parse(currentUser.reloadUserInfo.customAttributes)

  const api = new APIAccessControl(currentUser)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    const { localId } = user._data;
    const role = selectedRole;

    const usersRoleMap = {
      'admin': 'Administrador',
      'manager': 'Gerente',
      'coordinator': 'Coordenador(a)',
      'user': 'Usuário',
      'head': 'Head'
    }

    const group_email = customClaims.group_email ? customClaims.group_email : ""

    const result = await api.setCustomClaim(localId, group_email, role, 'false')

    if (result.status === 200) {
      user.role = usersRoleMap[selectedRole];
      setSuccess(true)
      setRowId(null)
      setOnEdit({
        status: false,
        id: null
      })
      setSelectedRole(null)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (rowId === user._data.localId && success) {
      setSuccess(false)
    }
  }, [rowId])

  return (
    <Box
      sx={{
        m: 1,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {success ? (
        <Fab
          color='primary'
          sx={{
            width: 40,
            height: 40,
            bgcolor: amber[500],
            '&:hover': { bgcolor: amber[600] }
          }}
        >
          <Check />
        </Fab>
      ) : (
        <Fab
          color='primary'
          sx={{
            width: 40,
            height: 40,
            bgcolor: amber[500],
            '&:hover': { bgcolor: amber[600] }
          }}
          disabled={user._data.localId !== rowId || loading}
          onClick={handleSubmit}
        >
          <Save />
        </Fab>

      )}
      {loading && (
        < CircularProgress
          size={52}
          sx={{
            color: amber[500],
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            top: -6,
            zIndex: 1
          }}
        />
      )}
    </Box>
  )
}

export default UsersActions