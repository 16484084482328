import React from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
//import Global from "../images/global.svg"

function ButtonFunction(props) {

  const navigate = useNavigate()

  return (
    <ButtonContainer>
      <Button onClick={() => navigate(props.linkTo)} className={props.active ? 'active' : ''}>
      <img src={props.src} alt="icon" />
      {props.text}
    </Button>
    </ButtonContainer >
  );
}

export default ButtonFunction;


const ButtonContainer = styled.div``

const Button = styled.button`
    /*style button*/
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 16px;
    gap: 20px;

    margin: 1vw;

    width: 210px;
    height: 52px;

    background: #242424;
    border: none;
    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s;

    /*style text*/
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;

  &:hover {
    border-right: 4px solid #F6BE27;
    box-sizing: border-box;
    background: #333333;
    padding: 10px 20px 10px 10px;
  }
  
  &.active {
    background: #333333;
  }

  img {
    width: 20px;
  }

  @media (max-width: 1300px) {
      width: 175px;
      font-size: 15px;
  }

`