import React, { useEffect, useState } from "react"
import { Container, Comment, FormSectionRow, ButtonComment, Radio, Icon } from './style'

import feedbackIcon from '../../../images/feedbackIcon.svg'
import { LocalStorage } from "../../../services/localStorage"

const arePropsEqual = (prevProps, nextProps) => {
    return prevProps.head === nextProps.head
}

const ManagerAnswersSection = React.memo(({ manager, head, area, coord }) => {
    const [showComments, setShowComments] = useState({show:{}})
    const [comment, setComment] = useState("")
    const [currentAnswer, setCurrentAnswer] = useState("")

    useEffect(() => {
        LocalStorage.createManagerObjectModel(manager, head, area, coord)
        LocalStorage.createShowManagerCommentsObjectModel(coord)

        function loadLocalAnswers() {
            const answer = LocalStorage.getManagerAnswers()[manager][head][area][coord]["status"]
            const comment = LocalStorage.getManagerAnswers()[manager][head][area][coord]["comentario"]
            const showComments = JSON.parse(window.localStorage.getItem("show_manager_comments"))
                
            if(answer !== undefined) {
                setCurrentAnswer(answer)
            }

            if(comment !== "") {
                setComment(comment)
            }

            if(showComments !== null) {
                setShowComments(showComments)
            }
            
            return
        }

        loadLocalAnswers()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        function handleComment(comment) {
            LocalStorage.setManagerComment(manager, head, area, coord, comment)
        }
        handleComment(comment)
        // eslint-disable-next-line
    }, [comment])

    function handleAnswer(event) {
        const answer = event.target.value
        
        setCurrentAnswer(answer)

        LocalStorage.setNewManagerAnswer(manager, head, area, coord, answer)
    }      

    function handleCommentToggle(event) {
        event.preventDefault()

        if(event.target.title === "Abrir comentário") {
            const show = LocalStorage.setShowComment(event.target.name, true)
            setShowComments(show)
            return
        }
        
        // setComment("")
        const show = LocalStorage.setShowComment(event.target.name, false)
        setShowComments(show)
    }

    return(
            <Container>                
                    <FormSectionRow>
                        <span>{coord}</span>
                        
                        <Radio style={{accentColor: "#00aa00"}} checked={currentAnswer === "verde"} required name={`${coord} - Status`} title="Verde" onChange={handleAnswer} value="verde" type="radio" />
                        <Radio style={{accentColor: "#ffff00"}} checked={currentAnswer === "amarelo"} required name={`${coord} - Status`} title="Amarelo" onChange={handleAnswer} value="amarelo" type="radio" />
                        <Radio style={{accentColor: "#ff0000"}} checked={currentAnswer === "vermelho"} required name={`${coord} - Status`} title="Vermelho" onChange={handleAnswer} value="vermelho" type="radio" />
                        
                        {
                            showComments.show[coord] 
                            ? 
                            <ButtonComment name={coord} onClick={handleCommentToggle} title="Fechar comentário">-
                                <Icon src={feedbackIcon} />
                            </ButtonComment> 
                            :
                            <ButtonComment name={coord} onClick={handleCommentToggle} title="Abrir comentário">+
                                <Icon src={feedbackIcon} />
                            </ButtonComment>
                        }

                        {
                            showComments.show[coord] && 
                            <Comment value={comment} key={coord} name={coord} placeholder="Comentario (opcional)" rows={2} onChange={(event) => setComment(event.target.value)}></Comment> 
                        }   
                    </FormSectionRow>          
            </Container>
    )
})

export default React.memo(ManagerAnswersSection, arePropsEqual);