import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import ModalCsHelper from '../components/Modals/ModalCSHelper'
import Loading from '../components/Loading'
import csHelperGetData from '../services/csHelperDataGet'
import apiCSHelperSendData from "../services/apiCSHelperSendData"
import processCSHelperManagerAnswers from '../services/processCSHelperManagerAnswers'

import InfosIcon from '../images/InfosIcon.svg'
import { AuthContext } from '../contexts/AuthProvider'
import SaveForm from '../components/SaveForm'
import FormSectionCSHelperManager from '../components/Forms/FormSectionCSHelperManager'

function CSHelperFormManager() {
  const [isSending, setIsSending] = useState(false)
  const [showModal, setShowModal] = useState()
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [managersData, setManagersData] = useState(null)
  const [arrayFormsManagers, setArrayFormsManagers] = useState(null)
  const [selectOptionHeads, setSelectioOptionHeads] = useState([])
  const [currentHead, setCurrentHead] = useState([])
  const currentUserEmail = useContext(AuthContext).currentUser.email
  const userEmail = process.env.REACT_APP_userEmailManager || currentUserEmail

  useEffect(() => {

    csHelperGetData.getManagersData(userEmail)
      .then(response => setTimeout(() => {
        setManagersData(response)
      }, 500))

  }, [])

  useEffect(() => {
    function handleSubmit(event) {
      event.preventDefault()
  
      window.localStorage.setItem("validating", true)
      window.dispatchEvent(new Event("storageChange"))
  
      setTimeout(async () => {
        const form = document.getElementById("CSHelperFormManager")
        const isFormValid = form.checkValidity()
  
        if (isFormValid) {
          event.preventDefault()
          const processed = processCSHelperManagerAnswers(currentHead[0])
    
          try {
            console.log('Enviando respostas!')
            setShowModal(true)
            setIsSending(true)
            const response = await apiCSHelperSendData.sendAnswersManager(processed)
            if (response.status === 200) {
              setShowModal(true)
              setIsSending(false)
              setIsSuccess(true)
              const managerAnswers = JSON.parse(window.localStorage.getItem("manager_answers"))
              delete managerAnswers[Object.keys(managersData)[0]][currentHead[0]]
              window.localStorage.setItem("manager_answers", JSON.stringify(managerAnswers));
              window.localStorage.removeItem("validating");
            }
          } catch (error) {
            console.log(error)
            setIsSending(false)
            setIsError(true)
          }
        }
    
        form.reportValidity()
      }, 500)
    }

    function renderFormManager() {
      if (managersData === null) {
        return
      }
  
      const elements = []
      const manager = Object.keys(managersData)[0]
      const heads = Object.keys(managersData[manager])
  
      setSelectioOptionHeads(heads)
  
      currentHead.forEach((headName) => {
        const areasName = Object.keys(managersData[manager][headName])
        areasName.forEach((areaName) => {
          elements.push(
            <ContainerHead key={`${headName}-${areaName}`}>
              <FormSectionCSHelperManager
                managersData={managersData}
                manager={manager}
                areaName={areaName}
                headName={headName}
              />
            </ContainerHead>
          )
        });
        
        elements.push(<Submit key={"enviar"} onClick={handleSubmit}>ENVIAR</Submit>)
      });
  
      setArrayFormsManagers(elements)
    }

    renderFormManager()
    // eslint-disable-next-line
  }, [managersData, currentHead])

  if (managersData === null) {
    return (
      <Container>
        <Loading />
      </Container>
    )
  }

  return (
    <Container>
      <SaveForm />

      <WellcomeContainer>
        <WellcomeTitleWrapper>
          <InfoIcon src={InfosIcon} alt="Ícone de informação" />
          <WellcomeTitle>{`Olá ${Object.keys(managersData)[0].split(" ")[0]}!`}</WellcomeTitle>
        </WellcomeTitleWrapper>
        <p>
          Sejam bem-vindos ao conformidades! Objetivo dele é facilitar e agilizar o preenchimento de informações sobre a saúde de cada cliente/time, prevendo riscos de churn, sinalizando possíveis upsells e mais! Em caso de qualquer dúvida ou necessidade de ajuste, acione o time de Projetos Internos de Sorocaba:&nbsp;
          <EmailLink target='_blank' href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=projetos-internos@rocky.ag">projetos-internos@rocky.ag </EmailLink>
          😀.
        </p>
        <DashLink href={`${process.env.REACT_APP_CSHELPER_DASH_URL_MANAGER}`} target="_blank" rel="noreferrer">Acessar dash</DashLink>
        <DashLink href={`${process.env.REACT_APP_LINK_ATUALIZAR_PESSOAS}`} target="_blank" rel="noreferrer">Atualizar Pessoas</DashLink>
      </WellcomeContainer>

      <FormWrapper id="CSHelperFormManager">

        

        <SelectionArea required onChange={(event) => {
          event.preventDefault()
          if (event.target.value !== "") {
            setCurrentHead([event.target.value])
          }
        }
        }>
          <option value={''} defaultChecked={true}>Selecione Head</option>
          {
            selectOptionHeads.map((head) => <option key={head} value={head}>{head}</option>)
          }
        </SelectionArea>

        {
          arrayFormsManagers
        }
      </FormWrapper>

      <ModalCsHelper
        showModal={showModal}
        isSending={isSending}
        isError={isError}
        setShowModal={setShowModal}
        isSuccess={isSuccess}
        setIsError={setIsError}
      ></ModalCsHelper>
    </Container>
  )
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 32px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;

    &::-webkit-scrollbar {
        width: 7px;
      }
    
      &::-webkit-scrollbar-track {
        background: #333333;
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: #242424;
        border-radius: 12px;
      }
`;

const ContainerHead = styled.div`
      background-color: #242424;
      padding: 24px;
      margin-bottom: 24px;
      border-radius: 8px;
      margin-top: 24px;
      position: relative;
`;

const WellcomeContainer = styled.div`
      width: 87%;
      background-color: #242424;
      padding: 24px;
      color: #FFFFFF;
      border-radius: 8px;
      margin-top: 24px;
`;

const WellcomeTitleWrapper = styled.div`
      display: flex;
      gap: 24px;
`;

const WellcomeTitle = styled.span`
      font-size: 24px;
      font-family: "HelveticaNowforMonksMedium";
      font-weight: bold;
`;

const Submit = styled.button`
    width: 100%;
    display: block;
    font-family: "HelveticaNowforMonksMedium";
    margin: 32px auto 32px auto;
    border-radius: 8px;
    background-color: #f6be27;
    padding: 16px 8px;  
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
    &:hover {
        background-color: #daa209;
    };
`;

const FormWrapper = styled.form`
    width: 90%;
    box-sizing: border-box;

    @media (min-width: 1366px) {
      width: 50%;
    }
`;

const InfoIcon = styled.img`
    width: 25px;
`;

const EmailLink = styled.a`
    color: #ffffff;
`;

const DashLink = styled.a`
  font-family: 'Space Grotesk';
  font-style: normal;
  font-size: 16px;

  // display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  gap: 12px;
  text-align: center;
  cursor: pointer;
  width: fit-content;
  justify-content:center;
  height: 32px;
  color: #000;
  text-decoration: none;

  margin-right: 20px;

  /* secondary/500 */

  background: #F6BE27;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;   
  border: none;

  &:hover{
    background-color: #fdad17;
    transition: 0.5s;
  }
`;

const SelectionArea = styled.select`
    min-width: 100%;
    padding: 16px 8px;
    background-color: #242424;
    border: none;
    color: #fff;
    border-radius: 8px;
`;


export default CSHelperFormManager
