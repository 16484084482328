import styled from 'styled-components'

export const Table = styled.table`
  width: 90%;
  height: 100%;

  margin: 2% auto;

  @media(max-width: 1300px) {
    width: 90%;
    height: 100%;
  }

`

export const TableHead = styled.thead`
  display: block;
  margin-right: 10px;
  border-bottom: 1px solid gray;
`

export const TableBody = styled.tbody`
  display: block;
  height: 85%;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #242424;
    border-radius: 20px;
  }

  @media(max-width: 1300px) {
    height: 75%;
  }

`

export const TableRow = styled.tr`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  height: 43px;
  cursor: pointer;
  align-items: center;
  border-radius: 8px;
  padding: 8px;

  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  color: #ffffff;

  &:hover {
    background: #242424;
    transition-delay: 50ms;
  }

  &.active {
    background: #242424;
    
  }

  @media (max-width: 1300px) {
    font-size: 15px;
  }

`
export const TableData = styled.td`

`

export const TableRowHeader = styled(TableRow)`
  &:hover{
    background-color: #333333;
  }
`

export const TableHeadData = styled.th`
`

export const IMG = styled.img`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
`