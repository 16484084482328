import { AuthContext } from "../contexts/AuthProvider";
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import Loading from "../components/Loading";
import HeaderSession from "../sessions/HeaderSession";
import Navbar from "../sessions/Navbar";
import ModalExtractitionForm from "../sessions/ModalExtractionForm";
import ModalExtractionInfo from "../sessions/ModalExtractionInfo";
import API from "../services/api";
import WarningModal from "../sessions/WarningModal";
import ExtractionsTable from '../components/ExtractionsTable';

function HomeExtractions() {
  const [menuState, setMenuState] = useState(true);
  const [modalExtractionFormState, setModalExtractionFormState] = useState(false);
  const [modalExtractionInfoState, setModalExtractionInfoState] = useState(false);
  const [modalExtractionInfoData, setModalExtractionInfoData] = useState("");
  const [extractionList, setExtractionList] = useState([]);
  const [search, setSearch] = useState("");
  const [warningModal, setWarningModal] = useState(false);
  const [modalController, setModalController] = useState("");
  const [text, setText] = useState("");
  const [extractionName, setExtractionName] = useState("");
  const [previousAuthor, setPreviousAuthor] = useState("");
  const [actionController, setActionController] = useState("");
  const [extractionId, setExtractionId] = useState("");
  const [author, setAuthor] = useState("");
  const [newDate, setNewDate] = useState();
  const [newError, setNewError] = useState();
  const [extractioLoad, setExtractionLoad] = useState(true);
  const [clients, setClients] = useState([]);
  const [clicked, setClicked] = useState("");
  const [extractionState, setExtractionState] = useState(false);
  const [extractionFilter, setExtractionFilter] = useState([]);

  const { currentUser, customClaims } = useContext(AuthContext);

  const api = new API(currentUser);

  async function fetchClients() {
    const api = new API(currentUser);
    await api.listClients().then((response) => {
      setClients([response.data.data])

    });
  }
  const parsedCustomClaims = JSON.parse(customClaims);

  async function fetchData() {
    setExtractionLoad(true);
    if ((parsedCustomClaims.role === 'admin') || (parsedCustomClaims.role === 'manager')) {
      await api
        .listDailyExtraction()
        .then((response) => {
          setExtractionList(response.data.data);
          setExtractionFilter(response.data.data);
          setExtractionLoad(false);
          setExtractionState(!extractionState);

        })
        .catch(() => console.log("deu errado"));
    } else {
      await api
        .listDailyExtraction(currentUser.email)
        .then((response) => {
          setExtractionList(response.data.data);
          setExtractionFilter(response.data.data);
          setExtractionLoad(false);
          setExtractionState(!extractionState);

        })
        .catch(() => console.log("deu errado"));
    }
  }


  useEffect(() => {
    fetchData();
    fetchClients();
  }, []);

  useEffect(() => {
    setExtractionFilter(extractionList.filter((extraction) =>
      extraction.metadata.extractionName.toLowerCase().includes(search)));
  }, [search]);

  useEffect(() => {
    if (
      modalController === "transfer complete" ||
      modalController === "disable complete" ||
      modalController === "active complete" ||
      modalController === "delete complete" ||
      modalController === "create extraction complete" ||
      modalController === "renew complete"
    ) {
      fetchData();
    }
  }, [modalController]);
  useEffect(() => {
    switch (actionController) {
      case "transfer":
        api
          .transferDailyExtraction(extractionId, text)
          .then(() => {
            setModalController("transfer complete");
            setModalExtractionInfoState(false);
            setClicked("");
          })
          .catch(() => setModalController("error"));
        break;
      case "report":
        api
          .createReport(author, extractionId, text)
          .then(() => {
            setModalController("report complete");
          })
          .catch(() => setModalController("error report"));
        break;
      case "active":
        api
          .deactivateExtraction(extractionId, true)
          .then(() => {
            setModalController("active complete");
            setModalExtractionInfoState(false);
            setClicked("");
          })
          .catch(() => setModalController("error"));
        break;
      case "disable":
        api
          .deactivateExtraction(extractionId, false)
          .then(() => {
            setModalController("disable complete");
            setModalExtractionInfoState(false);
            setClicked("");
          })
          .catch(() => setModalController("error"));
        break;
      case "renew":
        api
          .renewExtraction(extractionId, newDate)
          .then(() => {
            setModalController("renew complete");
            setModalExtractionInfoState(false);
            setClicked("");
          })
          .catch(() => setModalController("error"));
        break;
      case "delete":
        api
          .deleteDailyExtraction(extractionId)
          .then(() => {
            setModalController("delete complete");
            setModalExtractionInfoState(false);
            setClicked("");
          })
          .catch((e) => {
            setModalController("error")
          });
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionController]);


  return (
    <ContainerMain>
      {modalController !== "" && (
        <WarningModal
          newError={newError}
          renewDate={newDate}
          setExtractionId={setExtractionId}
          previousAuthor={previousAuthor}
          text={text}
          setExtractionName={setExtractionName}
          setText={setText}
          setAction={setActionController}
          setModalExtractionInfoState={setModalExtractionInfoState}
          extractionName={extractionName}
          warningModal={warningModal}
          modalController={modalController}
          setModalController={setModalController}
          setWarningModal={setWarningModal}
        ></WarningModal>
      )}
      <Navbar menuState={menuState} setMenuState={setMenuState} />
      <Main>
        <HeaderSession
          search
          setClicked={setClicked}
          text={"Minhas Extrações"}
          setModalExtractionFormState={setModalExtractionFormState}
          setModalExtractionInfoState={setModalExtractionInfoState}
          setMenuState={setMenuState}
          setSearch={setSearch}
          enableButton
        />

        {/* <CardSession /> */}

        {extractioLoad ? (
          <Loading />
        ) : (
          <ExtractionsTable
            extractionState={extractionState}
            setModalExtractionFormState={setModalExtractionFormState}
            setModalExtractionInfoState={setModalExtractionInfoState}
            setModalExtractionInfoData={setModalExtractionInfoData}
            setMenuState={setMenuState}
            extractionList={extractionFilter}
            clicked={clicked}
            setClicked={setClicked}
            search={search}
          />
        )}
        {!extractioLoad && extractionFilter.length === 0 && (
          <BoxInfo>
            <TextInfo>Você ainda não tem extrações para exibir!</TextInfo>
          </BoxInfo>
        )}
      </Main>

      <ModalExtractitionForm
        clients={clients}
        setNewError={setNewError}
        modalController={modalController}
        setModalController={setModalController}
        modalExtractionFormState={modalExtractionFormState}
        setModalExtractionFormState={setModalExtractionFormState}
      />

      <ModalExtractionInfo
        setNewDate={setNewDate}
        setAction={setActionController}
        setAuthor={setAuthor}
        setExtractionId={setExtractionId}
        setPreviousAuthor={setPreviousAuthor}
        setExtractionName={setExtractionName}
        setText={setText}
        text={text}
        setModalController={setModalController}
        ModalController={modalController}
        modalExtractionInfoState={modalExtractionInfoState}
        setModalExtractionInfoState={setModalExtractionInfoState}
        modalExtractionInfoData={modalExtractionInfoData}
        setWarningModal={setWarningModal}
        warningModal={warningModal}
        setClicked={setClicked}
      ></ModalExtractionInfo>
    </ContainerMain>
  );
}

export default HomeExtractions;

const ContainerMain = styled.div`
  height: 100vh;

  background-color: #242424;

  display: flex;
`;

const Main = styled.div`
  width: ${(props) => {
    if (
      props.menuState &&
      (props.modalNewsState || props.modalExtractionFormState)
    ) {
      return "50vw";
    }

    if (
      !props.menuState &&
      (props.modalNewsState || props.modalExtractionFormState)
    ) {
      return "70vw";
    }

    if (
      props.menuState &&
      (!props.modalNewsState || !props.modalExtractionFormState)
    ) {
      return "80vw";
    }

    if (
      !props.menuState &&
      (!props.modalNewsState || !props.modalExtractionFormState)
    ) {
      return "100vw";
    }
  }};

  height: 90vh;

  background-color: #333333;

  margin: auto 2vw;

  border-radius: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;
`;
const BoxInfo = styled.div`
  height: 90vh;
  justify-content: center;
  align-items: center;
`;

const TextInfo = styled.p`
  opacity: 0.4;
  color: #fff;
  font-size: 16px;
  font-family: HelveticaNowforMonksBold;
`;
