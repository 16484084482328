import { useContext } from "react";

import { useForm } from 'react-hook-form'
import API from "../../../services/api";
import { AuthContext } from "../../../contexts/AuthProvider";

import { Form } from "./Style";

export default function VtexExtractor(props) {

    const { register, handleSubmit, reset } = useForm({
        defaultValues: {
            customers: '',
            AccountName: '',
            nomeExtraction: '',
            AppKey: '',
            AppToken: '',
            data: '',
            descricao: ''
        }
    })

    const { currentUser } = useContext(AuthContext);
    const api = new API(currentUser);

    const dateExpiration = new Date()
    dateExpiration.setMonth(dateExpiration.getMonth() + 6);

    function completeExtraction() {
        Array.from(document.querySelectorAll("input"))
            .forEach((input) => (input.value = ""))

        Array.from(document.querySelectorAll("select"))
            .forEach((option) => (option.value = ""))

        Array.from(document.querySelectorAll("textarea"))
            .forEach((textarea) => (textarea.value = ""))

        reset()
    }

    function platformPayload(bodyVtex) {
        return bodyVtex
    }
    function formatWords(word) {
        const wordFormat = word
          .normalize("NFD")
          .replace(/ /g, "_")
          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z-_])/g, "")
          .replace("-", "_")
          .toLowerCase();
        return wordFormat;
      }
    const sendExtraction = event => {
        props.setModalController("create extraction")

        const bodyVtex = {
            metadata: {
                extractionName: formatWords(event.nomeExtraction),
                author: currentUser.email,
                customer: formatWords(event.customers),
                creationDate: new Date().toISOString(),
                expirationDate: dateExpiration.toISOString().slice(0, 10),
                consolidationWindow: 30,
                precisionInterval: 30,
                extractorModule: 'vtexOrdersExtractor',
                startDate: event.data,
            },
            queryParams: {
                accountName: event.AccountName.replace(/-/g, ""),
                appKey: event.AppKey,
                appToken: event.AppToken,
            },
            destination: {
                projectId: process.env.REACT_APP_DEFAULT_PROJECT_BQ,
                datasetId: formatWords(event.customers),
                tableId: `${'vtexOrdersExtractor'}-${(formatWords(event.customers))}-${formatWords(event.nomeExtraction)}`,
                description: event.descricao,
            },
        };

        api
            .createDailyExtraction(platformPayload(bodyVtex))
            .then(() => {
                props.setModalController("create extraction complete")
                completeExtraction()
            })
            .catch((er) => {
                if (er.request.status === 400) {
                    props.setNewError(er.data.error);
                    props.setModalController("error campo");
                } else {
                    props.setModalController("error report");
                }
            });
    }

    return (
        <Form onSubmit={handleSubmit(sendExtraction)}>
            <label htmlFor="cliente">Cliente</label>
            <select id="cliente"
                {...register("customers")}
                required
            >
                <option selected value="">
                    Escolha o nome do cliente.
                </option>
                {props.clients !== undefined && props.clients.map((option) => {
                    return (
                        <option key={option.id} value={option.name}
                        >
                            {" "}
                            {option.name}{" "}
                        </option>
                    );
                })}
            </select>

            <label htmlFor="AccountName">{"Account Name"}</label>
            <input
                required
                id="AccountName"
                placeholder={'ex: nomecliente'}
                name="AccountName"
                {...register("AccountName")}
            />

            <label htmlFor="nomeExtraction">Nome da extração</label>
            <input
                required
                id="nomeExtraction"
                placeholder="Ex: Cliente_productPerformance"
                name="nomeExtraction"
                {...register("nomeExtraction")}
            />


            <label htmlFor="AppKey">App Key</label>
            <input
                required
                id="AppKey"
                placeholder="Ex: vtexappkey-nomeCLiente-AAAAAA"
                name="AppKey"
                {...register("AppKey")}
            />

            <label htmlFor="AppToken">App Token</label>
            <input
                required
                id="AppToken"
                placeholder="Ex: QWERTYUUIOPLKASDFGHLHKZXCV"
                name="AppToken"
                {...register("AppToken")}
            />

            <label htmlFor="data">Início da extração:</label>
            <input
                type="date"
                id="data"
                required
                max={new Date().toISOString().split("T")[0]}
                name="data"
                {...register("data")}
            />

            <label htmlFor="descricao">Descrição da extração</label>
            <textarea
                minLength={10}
                id="descricao"
                name="descricao"
                placeholder="Descreva brevemente sua extração."
                required
                wrap="true"
                {...register("descricao")}
            />

            <button type="submit">Enviar Extração</button>
        </Form>
    )
}