import React, { useState, useEffect, useCallback } from 'react'
import UsersActions from './UsersActions';

import { Table, TableHead, TableBody, TableRow, TableHeadData, TableData, TableRowHeader, Select, IMG } from './styles'

function UserTable(props) {

  const [rowId, setRowId] = useState(null)
  const [selectedRole, setSelectedRole] = useState(null)
  const [onEdit, setOnEdit] = useState({
    status: false,
    id: null
  })

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setOnEdit(false)
      setRowId(null)
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  return (
    <Table>
      <TableHead>
        <TableRowHeader>
          <TableHeadData style={{ textAlign: 'center' }}></TableHeadData>
          <TableHeadData style={{ textAlign: 'left' }}>NOME</TableHeadData>
          <TableHeadData style={{ textAlign: 'left' }}>EMAIL</TableHeadData>
          <TableHeadData style={{ textAlign: 'left' }}>PAPEL</TableHeadData>
          <TableHeadData style={{ textAlign: 'center' }}>AÇÃO</TableHeadData>
        </TableRowHeader>
      </TableHead>
      <TableBody>
        {
          props.users.map((user) => (
            <TableRow key={user._data.localId}>
              <TableData><IMG src={user._data.photoUrl}/></TableData>
              <TableData>{user._data.displayName}</TableData>
              <TableData>{user._data.email}</TableData>
              <TableData onDoubleClick={() => {
                setOnEdit({
                  status: true,
                  id: user._data.localId
                })
              }}>
                {
                  onEdit.status && onEdit.id === user._data.localId ? 
                    (
                      <Select onChange={(event) => {
                        setSelectedRole(event.target.value)
                        setRowId(user._data.localId)
                      }}>
                        <option value={'admin'}>Administrador(a)</option>
                        <option value={'manager'}>Gerente</option>
                        <option value={'head'}>Head</option>
                        <option value={'coordinator'}>Coordenador(a)</option>
                        <option value={'user'}>Usuário(a)</option>
                      </Select>
                    ) : user.role
                }
              </TableData>
              <UsersActions {...{ user, rowId, setRowId, setOnEdit, selectedRole, setSelectedRole }} />
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  )
}

export default UserTable;