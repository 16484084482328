import styled from 'styled-components'

export const Table = styled.table`
  width: 90%;
  height: 100%;

  margin: 2% auto;

  @media(max-width: 1300px) {
    width: 90%;
    height: 100%;
  }

`

export const TableHead = styled.thead`
  display: block;
  margin-right: 10px;
  border-bottom: 1px solid gray;
`

export const TableBody = styled.tbody`
  display: block;
  height: 74%;
  overflow-y: auto;
  overflow-x: hidden;


  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #242424;
    border-radius: 20px;
  }

  @media(max-width: 1300px) {
    height: 70%;
  }
`

export const TableRow = styled.tr`
  display: grid;
  grid-template-columns: 10fr 1.50fr 1fr ;

  height: 43px;
  cursor: pointer;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  margin: 5px;
  font-family: HelveticaNowforMonksBold;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;

  color: #ffffff;

  &:hover {
    background: #242424;
    transition-delay: 50ms;
  }

  &.active {
    background: #242424; 
  }

  @media (max-width: 1300px) {
    font-size: 15px;
    margin: 5px;
    grid-template-columns: 10fr 2.50fr 1fr ;
  }

`

export const TableData = styled.td`
`

export const TableRowHeader = styled(TableRow)`
  &:hover{
    background-color: #333333;
  }
`

export const TableHeadData = styled.th`
  &:hover {
    color: #f6be27;
  }

  &.activeColor {
    color: #f6be27;
  }

`
