import { useState, useContext, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form'

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import API from "../../../services/api";
import Chip from "@mui/material/Chip";

import { AuthContext } from "../../../contexts/AuthProvider";
import metricsAds from '../../../services/adsMetrics.json'

import { Form } from "./styles";

export default function NewExtractionForm(props) {

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      customers: '',
      idCliente: '',
      nomeExtraction: '',
      date: '',
      description: ''
    }
  })

  const fixedOptions = ['date']
  const [metrics, setMetrics] = useState([]);
  const [fields, setFields] = useState([]);
  const [resource, setResource] = useState('');
  const [segments, setSegments] = useState([...fixedOptions]);
  const [typeReport, setTypeReport] = useState("")
  const [preDefReport, setPreDefReport] = useState([]);


  const { currentUser } = useContext(AuthContext);

  const dateExpiration = new Date();
  dateExpiration.setMonth(dateExpiration.getMonth() + 6);

  useEffect(() => {
    if(props.preDefExtraction !== []) {
      const extractionFiltered =  props.preDefExtraction?.filter((extraction) =>  extraction.name === typeReport)
        setPreDefReport(extractionFiltered)
    }

}, [typeReport])
useEffect(() => {
    if(preDefReport.length !== 0) {
        setResource(preDefReport[0].queryParams.resource)
        setMetrics(preDefReport[0].queryParams.metrics)
        setFields(preDefReport[0].queryParams.fields)
        setSegments(preDefReport[0].queryParams.segments)
    }
}, [preDefReport])
function handlePreDef(e) {
  e.preventDefault();
  setTypeReport(e.target.value);
}
  const fieldsFilter = () => {
    return fields.map((field) => {
      if (!field.includes('-')) {
        return `${resource}-${field}`
      } else {
        return field
      }
    })
  }

  const resourcesAds = () => {
    let arrayAds = [];
    Object.keys(metricsAds).forEach(function (item) {
      if (metricsAds[item]['metrics'] && metricsAds[item]['segments']) {
        arrayAds.push(item);
      }
    });
    return arrayAds;
  };

  const fieldsAds = () => {
    if (resource !== "") {
      return metricsAds[resource][resource];
    }
  };

  const segmentsAds = () => {
    if (resource !== "") {
      return metricsAds[resource]["segments"];
    }
  };

  const metricsAdsFilter = () => {
    if (resource !== "") {
      return metricsAds[resource]["metrics"];
    }
  };

  const resourceData = (resourceName) => {
    return metricsAds[resourceName][resourceName].map((item) => {
      return `${resourceName}-` + item
    })
  }

  const resourceFilter = () => {
    if (resource !== '') {
      switch (resource) {
        case "user_location_view":
          return fieldsAds().concat(resourceData("campaign"), resourceData("ad_group"), resourceData("customer"))
        case "geographic_view":
          return fieldsAds().concat(resourceData("campaign"), resourceData("ad_group"), resourceData("customer"))
        case "shopping_performance_view":
          return fieldsAds().concat(resourceData("campaign"), resourceData("ad_group"), resourceData("customer"))
        case "campaign":
          return fieldsAds().concat(resourceData("accessible_bidding_strategy"), resourceData("bidding_strategy"), resourceData("campaign_budget"), resourceData("customer"), resourceData("campaign_group"))
        case "age_range_view":
          return fieldsAds().concat(resourceData("accessible_bidding_strategy"), resourceData("ad_group"), resourceData("ad_group_criterion"), resourceData("bidding_strategy"), resourceData("campaign"), resourceData("customer"))
        case "product_group_view":
          return fieldsAds().concat(resourceData("ad_group"), resourceData("ad_group_criterion"), resourceData("campaign"), resourceData("customer"))
        case "gender_view":
          return fieldsAds().concat(resourceData("ad_group"), resourceData("ad_group_criterion"), resourceData("campaign"), resourceData("customer"), resourceData("bidding_strategy"))
        case "search_term_view":
          return fieldsAds().concat(resourceData("campaign"), resourceData("ad_group"), resourceData("customer"), resourceData("ad_group_ad"))
        case "video":
          return fieldsAds().concat(resourceData("campaign"), resourceData("ad_group"), resourceData("customer"), resourceData("ad_group_ad"))
        case "ad_group_ad":
          return fieldsAds().concat(resourceData("campaign"), resourceData("ad_group"), resourceData("customer"))
        default:
          return fieldsAds()
      }
    }
  }

  function completeExtraction() {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    Array.from(document.querySelectorAll("select")).forEach((option) => {
      option.value = "";
    });
    Array.from(document.querySelectorAll("textarea")).forEach((textarea) => {
      textarea.value = "";
    });

    reset()
    clearSelected()
  }

  function platformPayload(bodyAds) {
    return bodyAds
  }

  function formatWords(word) {
    const wordFormat = word
      .normalize("NFD")
      .replace(/ /g, "_")
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z-_])/g, "")
      .replace("-", "_")
      .toLowerCase();
    return wordFormat;
}

  const sendExtraction = (event) => {
    props.setModalController("create extraction");

    const api = new API(currentUser);
    const bodyAds = {
      metadata: {
        extractionName: formatWords(event.nomeExtraction),
        author: currentUser.email,
        customer: formatWords(event.customers),
        creationDate: new Date().toISOString(),
        expirationDate: dateExpiration.toISOString().slice(0, 10),
        consolidationWindow: 5,
        precisionInterval: 30,
        extractorModule: 'adsExtractor',
        startDate: event.date,
      },
      queryParams: {
        customerId: event.idCliente.replace(/-/g, ""),
        metrics: event.metrics,
        segments: segments,
        fields: fieldsFilter(),
        resource: resource,
        extras: []
      },
      destination: {
        projectId: process.env.REACT_APP_DEFAULT_PROJECT_BQ,
        datasetId: formatWords(event.customers),
        tableId: `${'adsExtractor'}-${formatWords(event.customers)}-${formatWords(event.nomeExtraction)}`,
        description: event.description,
      },
    };
    api
      .createDailyExtraction(platformPayload(bodyAds))
      .then(() => {
        props.setModalController("create extraction complete");
        completeExtraction();
      })
      .catch((error) => {
        if (error.request.status === 400) {
          props.setNewError(error.data.error);
          props.setModalController("error campo");
        } else {
          props.setModalController("error report");
        }
      });
  };

  function handleResource(e) {
    e.preventDefault();
    setResource(e.target.value);
  }

  const clearSelected = () => {
    setMetrics([]);
    setFields([])
    setSegments([])
    setResource('')
  };

  return (
    <Form onSubmit={handleSubmit(sendExtraction)}>
      <label htmlFor="preDef">Relatórios Predefinidos</label>
            <select
                id="preDef"
                required
                onChange={handlePreDef}
                value={typeReport}
            >
                <option value="">
                    Selecione o tipo de relatório
                </option>
                <option value="personalizado">Relatório personalizado</option>
                {props?.preDefExtraction && props.preDefExtraction.map((option) => {
                    return (
                        <option key={option.id} value={option.name}>
                            {" "}
                            {option.name}{" "}
                        </option>
                    );
                })}
            </select>
      <label htmlFor="cliente">Cliente</label>
      <select
        id="cliente"
        required
        selected
        {...register("customers")}
      >
        <option value="">Escolha o nome do cliente.</option>
        {props.clients !== undefined && props.clients.map((option) => {
          return (
            <option key={option.id} value={option.name}>
              {" "}
              {option.name}{" "}
            </option>
          );
        })}
      </select>

      <label htmlFor="resource">Recurso</label>
      <select id="resource" onChange={handleResource} required value={resource}>
        <option value="">Escolha o recurso.</option>
        {resourcesAds()?.map((option) => {
          return (
            <option key={option} value={option}>
              {" "}
              {option}{" "}
            </option>
          );
        })}
      </select>

      <label htmlFor="idCliente">{'ID Cliente'}</label>
      <input
        required
        id="idCliente"
        placeholder={'Ex: 123456789'}
        nome="idCliente"
        {...register('idCliente')}
      />

      <label htmlFor="nomeExtraction">Nome da extração</label>
      <input
        required
        id="nomeExtraction"
        placeholder="Ex: Cliente_productPerformance"
        name="nomeExtraction"
        {...register('nomeExtraction')}
      />

      {resource !== '' &&
        <>
          <Stack spacing={2}>
            {metricsAdsFilter() && (
              <>
                <label htmlFor="metrics">Métricas</label>
                <Controller
                  control={control}
                  name="metrics"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      required
                      multiple
                      id="metrics"
                      options={metricsAdsFilter()}
                      getOptionLabel={(option) => option}
                      filterSelectedOptions
                      value={metrics}
                      onChange={(_event, newMetric) => {
                        onChange(newMetric);
                        setMetrics([...newMetric]);
                        if(typeReport !== "") {
                          setTypeReport("personalizado")
                      }
                      }}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderInput={(params) => (
                        <TextField
                          classes={{ root: "MuiTextField-root" }}
                          {...params}
                          placeholder={"Adicione suas Métricas"}
                        />
                      )}
                    />
                  )}
                />
              </>
            )}

            <label htmlFor="fields"> Fields</label>
            <Controller
              control={control}
              name="fields"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  required
                  multiple
                  id="fields"
                  options={resourceFilter()}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  onChange={(_event, newDimension) => {
                    onChange(newDimension)
                    setFields([...newDimension]);
                    if(typeReport !== "") {
                      setTypeReport("personalizado")
                  }
                  }}
                  value={fields}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      inputProps={{ disableUnderline: true }}
                      classes={{ root: "MuiTextField-root" }}
                      {...params}
                      placeholder={"Adicione seus fields"}
                    />
                  )}
                />
              )}
            />

            {segmentsAds() && (
              <>
                <label htmlFor="segments">Segments</label>
                <Controller
                  control={control}
                  name="segments"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      required
                      multiple
                      id="segments"
                      options={segmentsAds()}
                      getOptionLabel={(option) => option}
                      filterSelectedOptions
                      onChange={(_event, newDimension) => {
                        onChange(newDimension);
                        setSegments([
                          ...fixedOptions,
                          ...newDimension.filter((option) => fixedOptions.indexOf(option) === -1)
                        ])
                        if(typeReport !== "") {
                          setTypeReport("personalizado")
                      }
                      }}
                      value={segments}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option}
                            {...getTagProps({ index })}
                            disabled={fixedOptions.indexOf(option) === 0}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          inputProps={{ disableUnderline: true }}
                          classes={{ root: "MuiTextField-root" }}
                          {...params}
                          placeholder={"Adicione seus segments"}
                        />

                      )}
                    />
                  )}
                />
              </>
            )}
          </Stack>
        </>}

      <label htmlFor="data">Início da extração:</label>
      <input
        type="date"
        id="data"
        required
        max={new Date().toISOString().split("T")[0]}
        name="date"
        {...register("date")}
      />

      <label htmlFor="descricao">Descrição da extração</label>
      <textarea
        minLength={10}
        id="description"
        name="description"
        placeholder="Descreva brevemente sua extração."
        required
        wrap="true"
        {...register("description")}
      ></textarea>

      <button type="submit">Enviar Extração</button>
    </Form>
  );
}

