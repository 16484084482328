import styled from "styled-components"

function Loading() {
    return (
        <Load>
            <LoadCircle/>
        </Load>
    )
}

export default Loading

const Load = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
`

const LoadCircle = styled.div`

    & {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        position: relative;
        animation: rotate 0.7s linear infinite
      }

      &::before , &::after {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 5px solid #FFF;
        animation: prixClipFix 2s linear infinite ;
      }
      
      &::after{
        transform: rotate3d(90, 90, 0, 180deg );
        border-color:#F6BE27;
      }

      @keyframes rotate {
        0%   {transform: rotate(0deg)}
        100%   {transform: rotate(360deg)}
      }

      @keyframes prixClipFix {
          0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
          50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
          75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      }
`