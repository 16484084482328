import axios from 'axios'

class APICSHelperData {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_CSHELPERCF,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }

  async sendAnswersHead(answers) {
    const response = this.api.post('/?destinationTable=new_headsAnswers', { "data": answers })
    return response
  }

  async sendAnswersManager(answers) {
    const response = this.api.post('/?destinationTable=new_managersAnswers', { "data": answers })
    return response
  }

  async sendAnswersCoord(answers) {
    const response = this.api.post('/?destinationTable=new_coordAnswers', { "data": answers })
    return response
  }

}

export default new APICSHelperData()