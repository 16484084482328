import React, { useContext } from "react";
import LogoutIcon from '../images/logoutIcon.svg'
import styled from "styled-components";

import { AuthContext } from "../contexts/AuthProvider";

function ButtonLogout(props) {

  const { logout } = useContext(AuthContext)

  return (
      <Button title={props.title} onClick={logout} className={props.active ? 'active' : ''}>
        <Img src={LogoutIcon} alt="logout Icon" />
      </Button>
  )
}

export default ButtonLogout;

const Button = styled.button`
  width: 50px;
  height: 50px;

  position: absolute;
  bottom: -25px;
  left: 10px;

  background-color: transparent;
  border: none;

  cursor: pointer;
`

const Img = styled.img`
  width: 30px;
  height: 30px;

  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;

  &:hover {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  } 
`