import React from "react";
import styled from "styled-components";
import { StyledEngineProvider } from "@mui/material/styles";

import NewExtractionForm from "../components/NewExtractionForm";

import closeIcon from "../images/closeIcon.svg";

function ModalExtractitionForm(props) {
  if (props.modalExtractionFormState) {
    return (
      <Container>
        <Img
          src={closeIcon}
          onClick={() => props.setModalExtractionFormState(false)}
        ></Img>
        <SubContainer>
          <ContentContainer>
            <Title>Nova extração</Title>
            <CardInfo>
              <p>
                Antes de você adicionar as suas extrações tem três informações
                IMPORTANTES que você precisa saber:
              </p>
              <p>1° As extrações demoram 1 dia para cair;</p>
              <p>
                2° As extrações inseridas serão validadas, se validadas
                corretamente então serão incluídas no banco de dados;
              </p>
              <p>
                3° LEIA os guias de usuários dos respectivos extratores antes de fazer uma extração.
              </p>
            </CardInfo>
            <StyledEngineProvider injectFirst>
              <NewExtractionForm
                clients={props.clients}
                setNewError={props.setNewError}
                setModalController={props.setModalController}
              ></NewExtractionForm>
            </StyledEngineProvider>
          </ContentContainer>
        </SubContainer>
      </Container>
    );
  }
}

export default ModalExtractitionForm;

const Container = styled.div`
  width: 35vw;
  max-width: 455px;
  height: 90vh;

  background-color: #333333;

  margin: auto 2vw auto 0;

  border-radius: 20px;

  position: relative;

  @media (max-width: 1300px) {
    max-width: 300px;
  }
`;

const Img = styled.img`
  width: 30px;
  height: 30px;

  position: absolute;
  right: 15px;
  top: 15px;

  cursor: pointer;
`;

const SubContainer = styled.div`
  margin: 15px;
  margin-top: 60px;
  height: 90%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #333333;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #242424;
    border-radius: 12px;
  }

  @media (max-width: 1300px) {
    height: 85%;
  }

`;

const ContentContainer = styled.div`
  text-align: justify;
  margin: 10px;
`;

const Title = styled.h1`
  margin-top: 0;

  font-family: "HelveticaNowforMonksExtraBold", sans-serif;
  font-size: 2.5em;

  color: #f6be27;

  @media (max-width: 1500px) {
    font-size: 1.7rem;
  }
`;

const CardInfo = styled.div`
  color: #e2e2e2;
  font-size: 14.5px;
  font-family: "Open Sans", sans-serif;

  background-color: #242424;
  border-radius: 5px;
  padding: 10px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;
