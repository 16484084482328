import React, { useState } from 'react'

import styled from 'styled-components';
import Navbar from '../sessions/Navbar'
import FormFeedback from '../components/Forms/FormFeedback';
import HeaderSession from '../sessions/HeaderSession';


export default function Feedback() {
    const [menuState, setMenuState] = useState(true);

    return (
        <ContainerMain>
            <Navbar menuState={menuState} setMenuState={setMenuState} />
            <Main>
                <HeaderSession text="Feedback" visibility="hidden"/>
                <FormFeedback/>
            </Main>
        </ContainerMain>
    )
}

const ContainerMain = styled.div`
  height: 100vh;

  background-color: #242424;

  display: flex;
`;

const Main = styled.div`
  width: ${(props) => {
        if (
            props.menuState &&
            (props.modalNewsState || props.modalExtractionFormState)
        ) {
            return "50vw";
        }

        if (
            !props.menuState &&
            (props.modalNewsState || props.modalExtractionFormState)
        ) {
            return "70vw";
        }

        if (
            props.menuState &&
            (!props.modalNewsState || !props.modalExtractionFormState)
        ) {
            return "80vw";
        }

        if (
            !props.menuState &&
            (!props.modalNewsState || !props.modalExtractionFormState)
        ) {
            return "100vw";
        }
    }};

  height: 90vh;

  background-color: #333333;

  margin: auto 2vw;

  border-radius: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;
`