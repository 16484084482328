import React from "react";

import { Table, TableBody, TableData, TableHead, TableHeadData, TableRow, TableRowHeader } from "./style";

import InfoButton from "../InfoButton";

export default function NewsTable(props) {

    const rules = {
        noticia: {
            text: "Notícia",
            backgroundColor: "#1F82E4"
        },
        importante: {
            text: "Importante",
            backgroundColor: "#F6BE27",

        },
        critico: {
            text: "Crítico",
            backgroundColor: "#E72121"
        }
    }

    return (
        <Table>
            <TableHead>
                <TableRowHeader>
                    <TableHeadData style={{ textAlign: 'left' }}>TÍTULO</TableHeadData>
                    <TableHeadData style={{ textAlign: 'left' }}>DATA</TableHeadData>
                    <TableHeadData style={{ textAlign: 'center' }}>STATUS</TableHeadData>
                </TableRowHeader>
            </TableHead>
            <TableBody>
                {
                    props.news.map((news) => (
                        <TableRow
                            key={news.id}
                            setMenuState={props.setMenuState}
                            setClicked={props.setClicked}
                            clicked={props.clicked}
                            news={news}
                            setModalNewsState={props.setModalNewsState}
                            setModalNewsData={props.setModalNewsData}

                            onClick={() => {
                                props.setModalExtractionFormState(false)
                                props.setMenuState(false)
                                props.setModalNewsState(true)
                                props.setModalNewsData(news)
                                props.setClicked(news.id)
                            }} className={props.clicked === news.id ? 'active' : ''}
                        >
                            <TableData>{news.title}</TableData>
                            <TableData>{news.createdAt}</TableData>
                            <TableData>
                                <InfoButton
                                    text={rules[news.priority].text}
                                    backgroundColor={rules[news.priority].backgroundColor}
                                    fontColor={'#242424'}
                                    fontSize={'14px'}
                                />
                            </TableData>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    )
}