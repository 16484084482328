function processCSHelperManagerAnswers(currentHead) {
    const data = JSON.parse(window.localStorage.getItem("manager_answers"))
    let processed = []
    const manager = Object.keys(data)[0]

    for(let head in data[manager]) {
        if(head === currentHead) {
            for (let area in data[manager][head]){
                for(let coord in data[manager][head][area]) {
                    const auxObj = {
                        "date": new Date().toISOString().slice(0,10),
                        "gerente": manager,
                        "head": head,
                        "area": area,
                        "coordenador": coord.split(" : ")[0],
                        "cliente": coord.split(" : ")[1],
                        ...data[manager][head][area][coord]
                    }

                    if(auxObj.status !== "") {
                        processed.push(auxObj)
                    }
                }
            }
        }
    };

    return processed
}

export default processCSHelperManagerAnswers