import { getDoc, doc } from "firebase/firestore";
import { db } from '../config/firebase'

class CSHelperGetData {

  constructor() {
    this.allHeadsData = {
      coords: {},
      clients: {}
    }
    this.allManagersData = {}
  }
    
  async getHeadsData(headEmail) {
    const headDataRef = doc(db, 'heads_coordenadores', headEmail)
    const snapshot = await getDoc(headDataRef)
    const data = snapshot.data()

    this.allHeadsData = {
      coords: {},
      clients: {}
    }

    for (let key of Object.keys(data)) {
      let [nome, area] = key.split(' - ')

      if (area !== 'Mídias') {
        area = area.split(" : ")[1]
      }

      if (!this.allHeadsData.clients.hasOwnProperty(nome)) {
        this.allHeadsData.clients[nome] = {}
      }

      data[key].forEach((coordAndClient) => {
        const [coord, client] = coordAndClient.split(" : ")

        if (coord !== client) {
          if (Array.isArray(this.allHeadsData.clients[nome][area])) {
            this.allHeadsData.clients[nome][area].push(coordAndClient)
          } else {
            this.allHeadsData.clients[nome][area] = []
            this.allHeadsData.clients[nome][area].push(coordAndClient)
          }

        }

        if (!this.allHeadsData.coords.hasOwnProperty(coord)) {
          this.allHeadsData.coords[coord] = area
        }
      })

    }

    return this.allHeadsData
  }

  async getManagersData(managerEmail) {
    const headDataRef = doc(db, 'gerentes_heads', managerEmail)
    const snapshot = await getDoc(headDataRef)

    const headsNameObject = snapshot.data()
    const managerName = Object.keys(headsNameObject);

    headsNameObject[managerName].forEach(async (headEmail) => {
      const headsData = await this.getHeadsData(headEmail)
      this.allManagersData[managerName] = {
        ...this.allManagersData[managerName],
        ...headsData.clients,
      };
    })

    return this.allManagersData;
  }
}

export default new CSHelperGetData();
