import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";

import Navbar from "../sessions/Navbar";
import UserTable from "../components/UserTable";
import Loading from "../components/Loading";
import HeaderSession from "../sessions/HeaderSession";

import { AuthContext } from "../contexts/AuthProvider";
import APIAccessControl from "../services/apiAccessControl";

function UserRoleController() {
  const { currentUser } = useContext(AuthContext);

  const api = new APIAccessControl(currentUser);

  const [menuState, setMenuState] = useState(true);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [search, setSearch] = useState("");
  const [userLoading, setUserLoading] = useState(true);

  function fetchData() {
    return api.listUsers();
  }

  useEffect(() => {
    fetchData()
      .then((response) => {
        console.log(response)
        const usersRoleMap = {
          admin: "Administrador",
          manager: "Gerente",
          coordinator: "Coordenador(a)",
          user: "Usuário",
          head: "Head",
        };

        let users = response.data;
        const usersMod = [];

        users.forEach((user) => {
          const role = JSON.parse(user._data.customAttributes).role;
          user.role = role ? usersRoleMap[role] : "Sem Papel";
          usersMod.push(user);
        });

        setUsers(usersMod);
        setFilteredUsers(usersMod);
        setUserLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setFilteredUsers(
      users.filter((user) =>
        user._data.displayName.toLowerCase().includes(search)
      )
    );
  }, [search]);

  return (
    <ContainerMain>
      <Navbar menuState={menuState} setMenuState={setMenuState} />
      <Main>
        <HeaderSession
          search
          setSearch={setSearch}
          text={"Controle de Usuários"}
        />
        {userLoading ? <Loading /> : <UserTable users={filteredUsers} />}
      </Main>
    </ContainerMain>
  );
}

export default UserRoleController;

const ContainerMain = styled.div`
  height: 100vh;

  background-color: #242424;

  display: flex;
`;

const Main = styled.div`
  width: ${(props) => {
    if (
      props.menuState &&
      (props.modalNewsState || props.modalExtractionFormState)
    ) {
      return "50vw";
    }

    if (
      !props.menuState &&
      (props.modalNewsState || props.modalExtractionFormState)
    ) {
      return "70vw";
    }

    if (
      props.menuState &&
      (!props.modalNewsState || !props.modalExtractionFormState)
    ) {
      return "80vw";
    }

    if (
      !props.menuState &&
      (!props.modalNewsState || !props.modalExtractionFormState)
    ) {
      return "100vw";
    }
  }};

  height: 90vh;

  background-color: #333333;

  margin: auto 2vw;

  border-radius: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;
`;
