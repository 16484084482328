/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";

import { useForm, Controller } from "react-hook-form";
import API from "../../../services/api";
import { AuthContext } from "../../../contexts/AuthProvider";

import { Form } from "./style";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Loading from "../../Loading";

export default function TiktokExtractor(props) {
  const { currentUser } = useContext(AuthContext);
  const api = new API(currentUser);

  const dateExpiration = new Date();
  dateExpiration.setMonth(dateExpiration.getMonth() + 6);
  
  const [glossary, setGlossary] = useState(null);
  const [metrics, setMetrics] = useState([]);
  const [customMetrics, setCustomMetrics] = useState([]);
  const [dimensions, setDimensions] = useState([]);
  const [dataLevel, setDataLevel] = useState("AUCTION_AD");

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      customers: "",
      advertiserId: "",
      nameExtraction: "",
      date: "",
      description: "",
    },
  });

  const fetchGlossary = async () => {
    try {
      const response = await api.listGlossary("tiktok", "PT");
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    fetchGlossary()
      .then(response => setGlossary(response.data.data))
  }, [])

  function completeExtraction() {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );

    Array.from(document.querySelectorAll("select")).forEach(
      (option) => (option.value = "")
    );

    Array.from(document.querySelectorAll("textarea")).forEach(
      (textarea) => (textarea.value = "")
    );

    reset();
    clearSelected();
  }

  function platformPayload(bodyTiktok) {
    return bodyTiktok;
  }

  function formatWords(word) {
    const wordFormat = word
      .normalize("NFD")
      .replace(/ /g, "_")
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z-_])/g, "")
      .replace("-", "_")
      .toLowerCase();
    return wordFormat;
  }

  const sendExtraction = (event) => {
    props.setModalController("create extraction");

    const bodyTiktok = {
      metadata: {
        extractionName: formatWords(event.nameExtraction),
        author: currentUser.email,
        customer: formatWords(event.customers),
        creationDate: new Date().toISOString(),
        expirationDate: dateExpiration.toISOString().slice(0, 10),
        consolidationWindow: 1,
        precisionInterval: 1,
        extractorModule: "tiktokExtractor",
        startDate: event.date,
      },
      queryParams: {
        advertiser_id: event.advertiserId,
        service_type: "AUCTION",
        report_type: "BASIC",
        data_level: dataLevel,
        dimensions: dimensions.map(dimension => dimension.apiName),
        metrics: metrics.map(metric => metric.apiName),
      },
      destination: {
        projectId: process.env.REACT_APP_DEFAULT_PROJECT_BQ,
        datasetId: formatWords(event.customers),
        tableId: `${"tiktokExtractor"}-${formatWords(event.customers)}-${formatWords(event.nameExtraction)}`,
        description: event.description,
      },
    };
    
    api
      .createDailyExtraction(platformPayload(bodyTiktok))
      .then(() => {
        props.setModalController("create extraction complete");
        completeExtraction();
      })
      .catch((error) => {
        if (error.request.status === 400) {
          props.setNewError(error.data.error);
          props.setModalController("error campo");
        } else {
          props.setModalController("error report");
        }
      });
  };

  if(!glossary) {
  return <div style={{ width: '100%', minHeight: '250px', display: "grid", placeItems: "center" }}>
      <Loading/>
    </div>
  };

  const updateFields = (dataLevel) => {
    const updatedDimensions = dimensions
      .map(dimension => dimension.apiName === "country_code" ? dimension : null)
      .filter(item => item !== null)
    const newDimension = Object.values(glossary["basic"][dataLevel]["dimensions"]).filter(obj => obj.apiName !== "country_code")[0];
    updatedDimensions.push(newDimension);
    setDimensions(updatedDimensions);

    const attributeMetrics = Object.values(glossary["basic"][dataLevel]["metrics"]);
    setCustomMetrics(attributeMetrics);
    setMetrics([]);
  };

  const getDimensionDataLevelMap = () => {
    const map = {}
    const reportTypes = Object.keys(glossary).filter(key => key !== "id" && key !== "active");

    reportTypes.forEach(reportType => {
      const dataLevels = Object.keys(glossary[reportType]).filter(dataLevel => dataLevel !== "basic_metrics");
      dataLevels.forEach(dataLevel => {
        const property = Object.keys(glossary[reportType][dataLevel]["dimensions"]).filter(key => key !== "Código do país")[0]
        map[property] = dataLevel
      });
    });

    return map;
  };

  const getAllDimensions = () => {
    const allDimensions = []
    const reportTypes = Object.keys(glossary).filter(key => key !== "id" && key !== "active");
    
    reportTypes.forEach(reportType => {
      const dataLevels = Object.keys(glossary[reportType]).filter(dataLevel => dataLevel !== "basic_metrics");
      dataLevels.forEach(dataLevel => {
        const properties = Object.keys(glossary[reportType][dataLevel]["dimensions"])
        properties.forEach(property => {
          allDimensions.push(glossary[reportType][dataLevel]["dimensions"][property])
        });
      });
    });

    const allUniqueDimensions = [...new Map(allDimensions.map(item => [item["apiName"], item])).values()]
    return allUniqueDimensions;
  };

  const clearSelected = () => {
    setDimensions([])
    setMetrics([])
  };

  return (
    <Form onSubmit={handleSubmit(sendExtraction)}>

      <label htmlFor="cliente">Cliente</label>
      <select id="cliente" {...register("customers")} required selected>
        <option value="">Escolha o nome do cliente.</option>
        {props.clients !== undefined &&
          props.clients.map((option) => {
            return (
              <option key={option.id} value={option.name}>
                {" "}
                {option.name}{" "}
              </option>
            );
          })}
      </select>

      <label htmlFor="advertiserId">ID do Anunciante</label>
      <input
        required
        id="advertiserId"
        placeholder={"Ex: 123456789"}
        name="advertiserId"
        {...register("advertiserId")}
      />

      <label htmlFor="nameExtraction">Nome da extração</label>
      <input
        required
        id="nameExtraction"
        placeholder="Ex: Cliente_productPerformance"
        name="nameExtraction"
        {...register("nameExtraction")}
      />

      <Stack spacing={2}>
        <label htmlFor="dimension">Dimensões</label>
        <Controller
          control={control}
          name="dimension"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="dimension"
              multiple
              options={getAllDimensions()}
              value={dimensions}              
              getOptionLabel={(option) => option.uiName}
              isOptionEqualToValue={(option, value) => option.apiName === value.apiName}
              onChange={(event, values) => {
                if(event.target.innerHTML.startsWith("ID ")) {
                  setDataLevel(getDimensionDataLevelMap()[event.target.innerHTML])
                  updateFields(getDimensionDataLevelMap()[event.target.innerHTML])
                  onChange(values);
                  return
                };
                setMetrics([]);
                onChange(values);
                setDimensions([...values])
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.uiName}
                    {...getTagProps({ index })}
                    title={option.description} 
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{ root: "MuiTextField-root" }}
                  placeholder="Adicione suas dimensões"
                  onChange={onChange}
                />
              )}
            />
          )}
        />
         <p id="infos_tiktok">
          * Só é possível inserir uma dimensão com "ID" por vez. 
        </p>

        <label htmlFor="metrics">Métricas</label>
        <Controller
          control={control}
          name="metrics"
          render={({ field: { onChange, values } }) => (
            <Autocomplete
              multiple
              id="metrics"
              required
              options={customMetrics.concat(Object.values(glossary.basic.basic_metrics))}
              value={metrics}
              onChange={(event, newValue) => {
                onChange(newValue);
                setMetrics([...newValue])
              }}
              getOptionLabel={(option) => option.uiName}  
              isOptionEqualToValue={(option, value) => option.apiName === value.apiName}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.uiName}
                    {...getTagProps({ index })}
                    title={option.description} 
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{ root: "MuiTextField-root" }}
                  placeholder="Adicione suas métricas"
                  onChange={onChange}
                />
              )}
            />
          )}
        />
        <p id="infos_tiktok">
          * As métricas e dimensões são filtradas automaticamente para garantir a compatibilidade da sua extração, para mais informações acesse o Guia do Usuário de TikTok.
        </p>
      </Stack>

      <label htmlFor="date">Início da extração:</label>
      <input
        type="date"
        id="date"
        required
        max={new Date().toISOString().split("T")[0]}
        name="date"
        {...register("date")}
      />

      <label htmlFor="description">Descrição da extração</label>
      <textarea
        minLength={10}
        id="description"
        name="description"
        placeholder="Descreva brevemente sua extração."
        required
        wrap="true"
        {...register("description")}
      />

      <button type="submit">Enviar Extração</button>
    </Form>
  );
}
