import React from 'react'
import styled from 'styled-components';
import Card from '../components/Card';

function CardSession(props) {
  return (
    <CardContainer>
      <Card
        principalTitle={props.principalTitle}
        title={props.title}
        date={props.date}
        text={props.text}
        backgroundColor={props.backgroundColor}

        setMenuState={props.setMenuState}
        setClicked={props.setClicked}
        clicked={props.clicked}
        setModalNewsState={props.setModalNewsState}
        setModalNewsData={props.setModalNewsData}
        setModalExtractionFormState={props.setModalExtractionFormState}
        filterNews={props.filterNews}
      />
    </CardContainer>
  )
}

export default CardSession;

const CardContainer = styled.div`
  width: 90%;
  height: 18%;


  margin: 1% auto auto;

  @media(max-width: 1300px){
    height: 15%;
    margin: 4% auto auto;
  }
`