import React, {useState, useEffect} from "react";
import styled from "styled-components";

import closeIcon from '../images/closeIcon.svg'
import sadIcon from '../images/sadIcon.svg'
import neutraulIcon from '../images/neutralIcon.svg'
import happyIcon from '../images/happyIcon.svg'
export default function ModalControllerFeedback(props) {
  function faceFeedback(faceIcon) {
    switch (faceIcon) {
        case "1":
            return sadIcon;
        case "2":
            return neutraulIcon;
        case "3":
            return happyIcon;
        default:
            break;
    }
}
  const [textoComQuebraDeLinha, setTextoComQuebraDeLinha] = useState("");
  const date = new Date(props?.modalFeedbackData?.createdAt).toLocaleString('pt-br')
  const dateFinally = date.split(',')
  useEffect(() => {
    const description = props?.modalReportData?.report?.split('.');
    const descriptionFinally = description?.join(".<br>");
    setTextoComQuebraDeLinha(descriptionFinally)
  }, [props])
  if (props.modalFeedbackState) {
    return (
      <Container>
        <Img src={closeIcon} onClick={() => {
          props.setModalFeedbackState(false)
          props.setClicked('')
        }} />

        <Main>
          <ContentMain>
            <ImgBox>
            <ImgFace src={faceFeedback(props.modalFeedbackData.faceFeedback)}></ImgFace>
            </ImgBox>
            <LabelTitle>Author</LabelTitle>
            <Text>{props.modalFeedbackData.author}</Text>
            <LabelTitle>Data</LabelTitle>
            <Text>{dateFinally[0]}</Text>
            <LabelTitle>{props.modalFeedbackData.option}</LabelTitle>
            <Text>{props.modalFeedbackData.message}</Text>
          </ContentMain>
        </Main>
      </Container>
    )
  }

}

const Container = styled.div`  
  width: 35vw;
  max-width: 455px;
  height: 90vh;

  background-color: #333333;

  margin: auto 2vw auto 0;

  border-radius: 20px;

  position: relative;

  @media (max-width: 1300px) {
    max-width: 300px;
  }

`
const LabelTitle = styled.div`
  font-family: "HelveticaNowforMonksExtraBold";
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 10px;
`;
const ImgBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ImgFace = styled.img`
  width: 30px;
  height: 30px;`
const Img = styled.img`
  width: 30px;
  height: 30px;

  position: absolute;
  right: 15px;
  top: 15px;

  cursor: pointer;
`

const Main = styled.div`
  margin: 15px;
  margin-top: 60px;  
  height: 80%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #333333;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #242424;
    border-radius: 12px;
  }

`

const ContentMain = styled.div`
  text-align: justify;
  margin: 10px;
  overflow-x: hidden;
`

const Title = styled.h1`
  margin-top: 0;

  font-family: 'HelveticaNowforMonksExtraBold', sans-serif;
  font-size: 2.5em;

  color: #F6BE27;

  @media(max-width: 1500px) {
    font-size: 1.7rem;
  }
`

const Text = styled.p`
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #F6BE27;
  word-break: break-all;

  @media (max-width: 1300px){
    font-size: 15px;
  }
`