import React, { useEffect, useState } from "react"
import { Container, FormSectionHeader, FormSectionRow, FormSectionLabel, FormSectionTitle, Hidden, Comment, Radio, ButtonOpen, ContainerCenter, ButtonComment, Icon, Spacer } from "./style"
import { LocalStorage } from "../../../services/localStorage"
import Loading from '../../Loading'

import feedbackIcon from '../../../images/feedbackIcon.svg'
import { ExpandLess, ExpandMore } from "@mui/icons-material"

const questions = [
    ["Time está bem?", "time_esta_bem"],
    ["Riscos relacionados a pessoas?", "riscos_relacionados_a_pessoas"],
    ["Mínimos de gestão estão sendo cumpridos?", "minimos_de_gestao_estao_sendo_cumpridos"]
]

const questionAnswersDescription = {
    "Time está bem?": "Satisfação e saúde da equipe, motivação, etc",
    "Riscos relacionados a pessoas?": "Riscos relacionados a pessoas? Necessidade de apoio? Riscos relacionados a contrato de estágios?",
    "Mínimos de gestão estão sendo cumpridos?": "1;1, PDI, reunião semanal de time, etc"
}

const arePropsEqual = (prevProps, nextProps) => {
    return prevProps.coord === nextProps.coord
}

const FormSectionCSHelperHead = React.memo(({ coord, area, head, setModalDescriptionState }) => {
    const [currentAnswers, setCurrentAnswers] = useState({})
    const [showComments, setShowComments] = useState({ show: {} })
    const [collapsed, setCollapsed] = useState(false)

    window.addEventListener("storageChange", () => {
        if (window.localStorage.getItem("validating")) {
            setCollapsed(true)
        }
    })

    useEffect(() => {
        LocalStorage.createCoordsFormsObjectModel(head, area, coord)
        LocalStorage.createShowCoordCommentsObjectModel(coord)
        LocalStorage.createCollapsedHeadObj(coord)

        function loadLocalAnswers() {
            const answers = LocalStorage.getCoordAnswers()
            const showComments = JSON.parse(window.localStorage.getItem("show_coord_comments"))
            setCollapsed(LocalStorage.getCollapsedHead(coord))

            if (answers !== undefined) {
                setCurrentAnswers(answers[head][area][coord])
            }

            if (showComments !== null) {
                setShowComments(showComments)
            }

            return
        }

        loadLocalAnswers()
        // eslint-disable-next-line
    }, [])

    function handleCollapse() {
        const local = LocalStorage.setCollapsedHead(coord, !collapsed)
        setCollapsed(local)
    }

    function handleAnswer(event) {
        const question = event.target.getAttribute("data-question")
        const answer = Number(event.target.value)

        setCurrentAnswers(prevState => ({
            ...prevState,
            [question]: [answer, prevState[question][1]]
        }))

        LocalStorage.setNewCoordAnswer(head, area, coord, question, answer)
    }

    function handleComments(event) {
        event.preventDefault()
        const question = event.target.getAttribute("data-question")

        setCurrentAnswers(prevState => ({
            ...prevState,
            [question]: [prevState[question][0], event.target.value]
        }))

        LocalStorage.setCoordComment(head, area, coord, question, event.target.value)
    }

    function handleShowComments(event) {
        event.preventDefault()

        if (event.target.title === "Abrir comentário") {
            const show = LocalStorage.setShowCoordComments(coord, event.target.name, true)
            setShowComments(show)
            return
        }

        const show = LocalStorage.setShowCoordComments(coord, event.target.name, false)
        // LocalStorage.setHeadComment(head, area, coord, question, '')
        // setCurrentAnswers(prevState => ({
        //     ...prevState,
        //     [question]: [prevState[question][0], '']
        // }))
        setShowComments(show)
    }

    if (
        Object.keys(currentAnswers).length === 0 ||
        Object.keys(showComments.show) === 0
    ) {
        return (
            <ContainerCenter>
                <Loading />
            </ContainerCenter>
        )
    }

    return (

        <Container>
            <FormSectionTitle onClick={handleCollapse}>{coord}</FormSectionTitle>

            {
                collapsed ?
                    <ExpandLess style={{ color: "#fff", cursor: "pointer", position: "absolute", top: "8px", right: "0px" }} title={"Minimizar"} onClick={handleCollapse} />
                    :
                    <ExpandMore style={{ color: "#fff", cursor: "pointer", position: "absolute", top: "8px", right: "0px" }} title={"Expandir"} onClick={handleCollapse} />
            }

            {
                collapsed && (
                    <>
                        <FormSectionHeader>
                            <Hidden></Hidden>
                            <FormSectionLabel title={"Conforme"}>Conforme</FormSectionLabel>
                            <FormSectionLabel title={"Não conforme"}>Não Conforme</FormSectionLabel>
                            <Spacer><ButtonOpen title={'Ver descrição'} onClick={() => setModalDescriptionState(prev => !prev)}>?</ButtonOpen></Spacer>
                        </FormSectionHeader>

                        {
                            questions.map((question) => (
                                <>
                                    <FormSectionRow title={questionAnswersDescription[question[0]]} key={question[0]} id={question[0]}>
                                        <span>{question[0]}</span>
                                        <Radio style={{ accentColor: "#00aa00" }} checked={currentAnswers[question[1]][0] === 1} required data-question={question[1]} onChange={handleAnswer} value="1" name={`${coord}-${question[0]}`} type="radio" />
                                        <Radio style={{ accentColor: "#ff0000" }} checked={currentAnswers[question[1]][0] === 0} required data-question={question[1]} onChange={handleAnswer} value="0" name={`${coord}-${question[0]}`} type="radio" />

                                        {
                                            showComments.show[coord][question[1]]
                                                ?
                                                <ButtonComment data-question={question[1]} onClick={handleShowComments} name={question[1]} title="Fechar comentário">-
                                                    <Icon src={feedbackIcon} />
                                                </ButtonComment>
                                                :
                                                <ButtonComment data-question={question[1]} onClick={handleShowComments} name={question[1]} title="Abrir comentário">+
                                                    <Icon src={feedbackIcon} />
                                                </ButtonComment>
                                        }

                                        {
                                            showComments.show[coord][question[1]] && (
                                                <Comment
                                                    key={`comment-${coord}-${question}`}
                                                    data-question={question[1]}
                                                    onChange={handleComments}
                                                    name={coord}
                                                    value={currentAnswers[question[1]][1]}
                                                    placeholder="Comentario (opcional)"
                                                    rows={2}>
                                                </Comment>
                                            )
                                        }
                                    </FormSectionRow>
                                </>
                            ))
                        }
                    </>
                )
            }
        </Container>
    )
})

export default React.memo(FormSectionCSHelperHead, arePropsEqual)