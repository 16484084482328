import axios from "axios";
import * as jose from 'jose'

export default class API {
  constructor(currentUser) {
    this.currentUser = currentUser;
    this.baseUrl = process.env.REACT_APP_VAPI_URL;
  }

  async createIdToken(data) {
    const secretKey = new TextEncoder().encode(process.env.REACT_APP_JWT_SECRET,)
    const alg = 'HS256'

    const jwt = await new jose.SignJWT({ data })
      .setProtectedHeader({ alg })
      .setIssuedAt()
      .setIssuer('urn:example:issuer')
      .setAudience('urn:example:audience')
      .setExpirationTime('10 secs')
      .sign(secretKey)

    return jwt;
  }

  createDailyExtraction(requestBody) {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };

        const response = await axios.post(
          this.baseUrl + "/dailyExtraction/create",
          requestBody,
          { headers: headers }
        );
        resolve(response);
      } catch (error) {
        reject(error.response);
        console.log(error);
      }
    });
  }

  deactivateExtraction(extractionId, state) {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };

        const response = await axios.put(
          this.baseUrl +
          `/dailyExtraction/deactivate?extractionId=${extractionId}&active=${state}`,
          {},
          { headers: headers }
        );
        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  renewExtraction(extractionId, newDate) {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };

        const response = await axios.put(
          this.baseUrl +
          `/dailyExtraction/renew?extractionId=${extractionId}&date=${newDate}`,
          {},
          { headers: headers }
        );
        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  listDailyExtraction(author) {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };

        const response = await axios.get(
          this.baseUrl +
          `/dailyExtraction/list${author ? "?author=" + author : ""}`,
          { headers: headers }
        );
        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  transferDailyExtraction(extractionId, newOwner) {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };
        const response = await axios.put(
          this.baseUrl +
          `/dailyExtraction/transfer?extractionId=${extractionId}&newOwner=${newOwner}`,
          {},
          { headers: headers }
        );
        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  deleteDailyExtraction(extractionId) {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };

        const response = await axios.delete(
          this.baseUrl + `/dailyExtraction/delete?extractionId=${extractionId}`,
          { headers: headers }
        );

        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  createReport(author, extractionId, text) {
    return new Promise(async (resolve, reject) => {
      try {
        const report = {
          extractionId: `${extractionId}`,
          createdAt: new Date(),
          author: `${author}`,
          report: `${text}`,
          validReport: true,
        };
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };
        const response = await axios.post(
          this.baseUrl + `/reportExtraction/create`,
          report,
          { headers: headers }
        );
        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  listReports() {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };

        const response = await axios.get(
          this.baseUrl + `/reportExtraction/list`,
          { headers: headers }
        );

        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  createNews(requestBody) {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };

        const response = await axios.post(
          this.baseUrl + "/voldemortNews/create",
          requestBody,
          { headers: headers }
        );
        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  listNews(author) {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };

        const response = await axios.get(
          this.baseUrl +
          `/voldemortNews/list${author ? "?author=" + author : ""}`,
          { headers: headers }
        );

        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  deleteNews(newsId) {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };

        const response = await axios.delete(
          this.baseUrl + `/voldemortNews/delete/?newsId=${newsId}`,
          { headers: headers }
        );

        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  deactivateNews(extractionId, state) {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };

        const response = await axios.put(
          this.baseUrl +
          `/dailyExtraction/deactivate?extractionId=${extractionId}&active=${state}`,
          {},
          { headers: headers }
        );
        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  listClients() {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };

        const response = await axios.get(
          this.baseUrl + `/voldemortCustomer/list`,
          { headers: headers }
        );

        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  createFeedback(requestBody) {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };

        const response = await axios.post(
          this.baseUrl + "/voldemortFeedback/create",
          requestBody,
          { headers: headers }
        );
        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  listFeedbacks() {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };

        const response = await axios.get(
          this.baseUrl + `/voldemortFeedback/list`,
          { headers: headers }
        );

        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  tokenManager(accessToken) {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };

        const response = await axios.get(
          this.baseUrl + `/tokenManager/create?token=${accessToken}`,
          { headers: headers }
        );
        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  listPreDefExtractions(platform) {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };

        const response = await axios.get(
          this.baseUrl + `/preDefExtraction/list?extractorId=${platform}`,
          { headers: headers }
        );

        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  listGlossary(platform, lang, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const idToken = await this.createIdToken(this.currentUser);

        const headers = {
          Authorization: `Firebase ${idToken}`,
        };

        const response = await axios.get(
          this.baseUrl + `/glossary/list?${id ? "id=" + id + "&" : ""}lang=${lang}&platform=${platform}`,
          { headers: headers }
        );

        resolve(response);
      } catch (error) {
        reject(error.response);
      }
    });
  }
}
