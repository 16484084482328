import React, { useContext, useState } from "react";
import styled from "styled-components"
import CSHelperFormHead from "../sessions/CSHelperFormHead";
import CSHelperFormManager from "../sessions/CSHelperFormManager";
import HeaderSession from "../sessions/HeaderSession";
import Navbar from "../sessions/Navbar";
import closeIcon from "../images/closeIcon.svg";
import { AuthContext } from "../contexts/AuthProvider";

const questions = [
  "Resultados",
  "Comunicação com o cliente",
  "Comunicação com o decisor",
  "Ajuste de escopo e operação",
  "Gargalos",
  "Novas oportunidades",
  "Alinhamento entre áreas"
]

const questionAnswersDescription = {
  "Resultados": {
    1: "Abaixo da meta e sem crescimento.",
    2: "Abaixo da meta, mas com crescimento.",
    3: "Dentro da meta.",
    4: "Acima da meta."
  },
  "Comunicação com o cliente": {
    1: "Cliente não aparece em call, não se importa com o dia-a-dia.",
    2: "Baixa frequência de alinhamento, poucos canais de comunicação.",
    3: "Boa frequência de alinhamento e comunicação.",
    4: "Reunião semanal presencial, mais de um canal de comunicação, pede suporte extra (outros serviços e dúvidas), reuniões todos serviços Rocky."
  },
  "Comunicação com o decisor": {
    1: "Mudança decisor/ ponto de contato, não conhece o decisor.",
    2: "Distantes do decisor/ponto de contato.",
    3: "Contatos pontuais, de acordo com a necessidade. ",
    4: "Contato semanal gerente/diretor, relação muito próxima."
  },
  "Ajuste de escopo e operação": {
    1: "Alto volume de demandas, demandas fora do escopo.",
    2: "Demandas estão desalinhadas e sem entendimento cliente.",
    3: "Demandas estão desalinhadas mas com entendimento cliente.",
    4: "Demandas alinhadas e de acordo com o escopo."
  },
  "Gargalos": {
    1: "Gargalos do nosso lado.",
    2: "Gargalos mais do nosso lado.",
    3: "Gargalos mais do lado do cliente.",
    4: "Não tem gargalos."
  },
  "Novas oportunidades": {
    1: "Não tem oportunidades.",
    2: "Talvez, mas não agora.",
    3: "Entendendo novas oportunidades.",
    4: "Tem oportunidades [descreva no comentário]."
  },
  "Alinhamento entre áreas": {
    1: "Não tem alinhamento entre áreas.",
    2: "Alinhamentos apenas em demandas (clientes pontuais em outras áreas)",
    3: "Existe alinhamento agendado mas existe problemas.",
    4: "Alinhamento semanal e tudo ocorrendo bem."
  }
}

function CSHelper() {
  const auth = useContext(AuthContext)
  const userRole = process.env.REACT_APP_userRole || JSON.parse(auth.customClaims).role
  const [menuState, setMenuSate] = useState(true)
  const [modalDescriptionState, setModalDescriptionState] = useState(false)

  return (
    <Container>
      <Navbar
        menuState={menuState}
        setMenuState={setMenuSate}
      />

      <Main>
        <HeaderSession text={"Conformidades"} />

        {
          (userRole === "head") && <CSHelperFormHead setModalDescriptionState={setModalDescriptionState}/>
        }

        {
          (userRole === "manager") && <CSHelperFormManager />
        }
      </Main>
      {
        modalDescriptionState &&

        <ModalDescription>
          <Img
            src={closeIcon}
            onClick={() => setModalDescriptionState(false)}
          ></Img>
          <DescriptionSection>
            {
              questions.map(question => {
                return (
                  <>
                    <Bold>{question}:</Bold>
                    <ol>
                      {
                        Object.keys(questionAnswersDescription[question]).map(value => (
                          <li>{`${questionAnswersDescription[question][value]}`}</li>
                        ))
                      }
                    </ol>
                  </>
                )
              })
            }
          </DescriptionSection>
        </ModalDescription>
      }
    </Container>
  )
}

const Container = styled.div`
  height: 100%;

  background-color: #242424;

  display: flex;
`;

const Main = styled.div`
  width: ${(props) => {
    if (
      props.menuState &&
      (props.modalDescriptionState || props.modalFeedbackState)
    ) {
      return "50vw";
    }

    if (
      !props.menuState &&
      (props.modalDescriptionState || props.modalFeedbackState)
    ) {
      return "70vw";
    }

    if (
      props.menuState &&
      (!props.modalDescriptionState || !props.modalFeedbackState)
    ) {
      return "80vw";
    }

    if (
      !props.menuState &&
      (!props.modalDescriptionState || !props.modalFeedbackState)
    ) {
      return "100vw";
    }
  }};

  height: 90vh;

  background-color: #333333;

  margin: auto 2vw;

  border-radius: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ModalDescription = styled.div`
  width: 35vw;
  max-width: 455px;
  height: 90vh;

  background-color: #333333;

  margin: auto 2vw auto 0;

  border-radius: 20px;

  @media (max-width: 1300px) {
    max-width: 300px;
  } 
`


const DescriptionSection = styled.div`
    width: 85%;
    max-height: 85%;
    position: sticky;
    top: 0;
    border-radius: 20px;
    margin-top: 5%;
    margin-right: 64px;
    padding: 32px;
    overflow-y: overlay;
    overflow-x: hidden;
    color: #fff;
    margin: 5% auto 64px auto;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin-bottom: 32px;
      margin-top: 32px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #333333;
      border-radius: 12px;
    }
`;

const Bold = styled.span`
      font-weight: bold;
`;

const Img = styled.img`
  width: 30px;
  height: 30px;

  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 50;

  cursor: pointer;
`;

export default CSHelper