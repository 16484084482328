import { useContext, useEffect } from "react";
import { useForm } from 'react-hook-form'

import API from "../../../services/api";
import { AuthContext } from "../../../contexts/AuthProvider";

import { Form } from "./style";

export default function MyBusinessExtractor(props) {

    const { currentUser } = useContext(AuthContext);
    const api = new API(currentUser);

    const dateExpiration = new Date()
    dateExpiration.setMonth(dateExpiration.getMonth() + 6);

    const { register, handleSubmit, reset } = useForm({
        defaultValues: {
            customers: '',
            nameExtractions: '',
            date: '',
            description: '',
            id_loja: ''
        }
    })

    

    function completeExtraction() {
        Array.from(document.querySelectorAll("input"))
            .forEach((input) => (input.value = ""))

        Array.from(document.querySelectorAll("select"))
            .forEach((option) => (option.value = ""))

        Array.from(document.querySelectorAll("textarea"))
            .forEach((textarea) => (textarea.value = ""))

        reset()
    }

    function platformPayload(bodyGA) {
        return bodyGA;
    }

    function formatWords(word) {
        const wordFormat = word
          .normalize("NFD")
          .replace(/ /g, "_")
          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z-_])/g, "")
          .replace("-", "_")
          .toLowerCase();
        return wordFormat;
    }

    const sendExtraction = event => {
        props.setModalController("create extraction")
        const bodyMyBusiness = {
            metadata: {
                extractionName: formatWords(event.nameExtractions),
                author: currentUser.email,
                customer: formatWords(event.customers),
                creationDate: new Date().toISOString(),
                expirationDate: dateExpiration.toISOString().slice(0, 10),
                consolidationWindow: 5,
                precisionInterval: 30,
                extractorModule: props.platform,
                startDate: event.date,
            },
            queryParams: {
                id_client: event.id_loja,
            },
            destination: {
                projectId: process.env.REACT_APP_DEFAULT_PROJECT_BQ,
                datasetId: formatWords(event.customers),
                tableId: `${props.platform.toLowerCase()}-${formatWords(event.customers)}-${formatWords(event.nameExtractions)}`,
                description: event.description,
            },
        };

        api
            .createDailyExtraction(platformPayload(bodyMyBusiness))
            .then(() => {
                props.setModalController("create extraction complete")
                completeExtraction()
            })
            .catch((er) => {
                if (er.request.status === 400) {
                    props.setNewError(er.data.error);
                    props.setModalController("error campo");
                } else {
                    props.setModalController("error report");
                }
            });
    }

    useEffect(() => {
        reset({
            customers: '',
            nameExtractions: '',
            date: '',
            description: '',
            id_cliente: '',
        })
    }, [props.platform, reset])

    return (
        <Form onSubmit={(e) => handleSubmit(sendExtraction)(e)}>
            <label htmlFor="cliente">Cliente</label>
            <select id="cliente"
                {...register("customers")}
                required
                selected
            >
                <option value="">Escolha o nome do cliente.</option>
                {props.clients !== undefined && props.clients.map((option) => {
                    return (
                        <option key={option.id} value={option.name}>
                            {" "}
                            {option.name}{" "}
                        </option>
                    );
                })}
            </select>

            <label htmlFor="id_loja">ID's Lojas</label>
            <input
                required
                id="id_loja"
                placeholder={'Ex: 123456789, 987654321'}
                name="id_loja"
                {...register("id_loja")}
            />

            <label htmlFor="nameExtractions">Nome da extração</label>
            <input
                required
                id="nameExtractions"
                placeholder="Ex: Cliente_productPerformance"
                name="nameExtractions"
                {...register("nameExtractions")}
            />

            <label htmlFor="data">Início da extração:</label>
            <input
                type="date"
                id="data"
                required
                max={new Date().toISOString().split("T")[0]}
                name="date"
                {...register("date")}
            />

            <label htmlFor="descricao">Descrição da extração</label>
            <textarea
                minLength={10}
                id="description"
                name="description"
                placeholder="Descreva brevemente sua extração."
                required
                wrap="true"
                {...register("description")}
            />

            <button type="submit">Enviar Extração</button>
        </Form>
    )
}