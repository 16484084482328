import React from 'react'
//import { useContext } from "react";
import styled from 'styled-components'


import closeIcon from '../images/closeIcon.svg'

// import API from '../services/api'
// import { AuthContext } from "../contexts/AuthProvider";

function ModalNews(props) {

  /*Create News*/
  // const { currentUser } = useContext(AuthContext);

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const api = new API(currentUser)
  //   const body = {
  //     active: true,
  //     createdAt: '2022/07/22',
  //     priority: "critico",
  //     message: "Lorem ipsum dolor sit amet",
  //     title: "Noticia"
  //   }
  //   api
  //   .createNews(body)
  //   .then((response) => console.log(response))
  //   .catch((error) => console.log(error))
  // }


  if (props.modalNewsState) {
    return (
      <Container>
        <Img src={closeIcon} onClick={() => {
          props.setModalNewsState(false)
          props.setClicked('')

        }}></Img>
        <SubContainer>
          <ContentContainer>
            <Title>{props.modalNewsData.title}</Title>
            <Text dangerouslySetInnerHTML={{ __html: props.modalNewsData.message }}></Text>
            {/* <button type="submit" onClick={handleSubmit}>
              Criar Noticia
            </button> */}
          </ContentContainer>
        </SubContainer>
      </Container>
    )
  }
}


export default ModalNews

const Container = styled.div`  
  width: 35vw;
  max-width: 455px;
  height: 90vh;

  background-color: #333333;

  margin: auto 2vw auto 0;

  border-radius: 20px;

  position: relative;

  @media (max-width: 1300px) {
    max-width: 300px;
  }

`

const Img = styled.img`
  width: 30px;
  height: 30px;

  position: absolute;
  right: 15px;
  top: 15px;

  cursor: pointer;
`

const SubContainer = styled.div`
  margin: 15px;
  margin-top: 60px;  
  height: 80%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #333333;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #242424;
    border-radius: 12px;
  }

`

const ContentContainer = styled.div`

  margin: 10px;
`

const Title = styled.h1`
  margin-top: 0;

  font-family: 'HelveticaNowforMonksExtraBold', sans-serif;
  font-size: 2.5em;

  color: #F6BE27;

  @media(max-width: 1500px) {
    font-size: 1.7rem;
  }
`

const Text = styled.p`
  text-align: justify;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: white;

  @media (max-width: 1300px){
    font-size: 15px;
  }
`
