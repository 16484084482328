import styled from "styled-components";

export const Form = styled.form `
    /* info */
    animation: showMenu 1.3s;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: left;
    // gap: 10px;
    width: 100%;
    height: auto;
    background: #333333;
    border-radius: 20px;

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 95px;
    line-height: 36px;
        
    flex: none;

    #dimension {
      overflow-y: auto;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      filter: brightness(0) invert(1);
    }

    input {
      height: 40px;
      background: #242424;
      color: white;
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 36px;

      border-radius: 5px;
      border:none      
    }

    select {
      height: 40px;
      border-radius: 5px;
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 36px;
      color: white;
      background: #242424;
      width: 100%;

      border: none

    }
    label{
      font-family: 'Space Grotesk';
      font-style: normal;
      font-size: 20px;
      line-height: 40px;
      color: #FFFFFF;
      margin-bottom: 1px;
      margin-top: 20px
    }

    button {

      font-family: 'Space Grotesk';
      font-style: normal;
      font-size: 20px;

      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 14px;
      gap: 12px;
      text-align: center;
      cursor: pointer;
      width: 100%;
      justify-content:center;
      height: 40px;

      /* secondary/500 */

      background: #F6BE27;
      border-radius: 4px;

      /* Inside auto layout */

      flex: none;
      order: 0;   
      border: none;

      &:hover{
        background-color: #fdad17;
        transition: 0.5s;
      }
    }

    #description{
      border: none;
      border-radius: 5px;
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 36px;
      resize: none;
      flex-direction: row;
      height: 80px;
      color: white;
      background: #242424;
      overflow-y: auto;
      margin-bottom: 25px;

      ::-webkit-scrollbar {
        width: 15px;
        cursor: pointer;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #333333;    /* color of the scroll thumb */
        border-radius: 20px;
      }
    }
    
  }

  
    .Mui-focused {
      border: transparent !important;
      outline: none !important;
      color: white;
    }

    .MuiAutocomplete-popupIndicator {
      width: 25px;
      height: 25px;
    }

    .MuiAutocomplete-clearIndicator {
      width: 25px;
      height: 25px;
    }

    .MuiAutocomplete-tag{
      color: black;
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 36px;
      background: #F6BE27;
      padding: 0;
    }

    .MuiAutocomplete-hasPopupIcon {
      // max-height: 100px;
      outline: none;
    }

    .MuiAutocomplete-inputRoot {
      color: white;
      background: #242424;
      color: white;
      position: relative;
    }

    .MuiTextField-root {
      height:auto;
    }

    .MuiInput-multiline {
      outline: none;
    }

    .MuiAutocomplete-endAdornment {
      top: 10px;
      display: flex;
      gap: 10px;
    }

    .MuiAutocomplete-inputFocused {
      border: none !important; 
      outline: none !important;
    }

    #data {
        color: white;
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 36px;
        padding: 0;
        overflow: hidden; 
        padding: 0;
        -webkit-padding-start: 1px;
        -webkit-padding-end: 4px;
      ::-webkit-datetime-edit { padding: 1em; }
      ::-webkit-datetime-edit-text { color: red; padding: 0 0.3em; color: white; }
      ::-webkit-datetime-edit-month-field { color: white; text-transform: uppercase; }
      ::-webkit-datetime-edit-day-field { color: white; text-transform: uppercase; }
      ::-webkit-datetime-edit-year-field { color: white; text-transform: uppercase; }
      ::-webkit-inner-spin-button { display: none; }
      ::-webkit-calendar-picker-indicator {  
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        width: 20px;
        height: 20px;
        border-width: thin; 
      }
    }

    .MuiOutlinedInput-root {
      border-radius: 10px;
      // max-height: 100px;
      height: auto;
      overflow-y: auto;   
      color: white;
      
      &:focus {
          border: 1px solid green;
          outline: none;
      }

      ::-webkit-scrollbar {
          width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: black;    /* color of the scroll thumb */
        border-radius: 20px; 
      }
    
    & fieldset {
      border-color: transparent !important;
    }
`;