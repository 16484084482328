import { useContext, useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form'

import API from "../../../services/api";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthProvider";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

import { Form } from "./style";

export default function GAExtractor(props) {

    const { currentUser } = useContext(AuthContext);
    const api = new API(currentUser);

    const dateExpiration = new Date()
    dateExpiration.setMonth(dateExpiration.getMonth() + 6);

    const { register, handleSubmit, reset, control } = useForm({
        defaultValues: {
            customers: '',
            nameExtractions: '',
            date: '',
            description: '',
            idCliente: ''
        }
    })

    const [metrics, setMetrics] = useState([]);
    const [dimensions, setDimensions] = useState([]);
    const [metricsDimensionsGa, setMetricsDimensionsGa] = useState([]);
    const [typeReport, setTypeReport] = useState("")
    const [preDefReport, setPreDefReport] = useState([])
    const fixedOptions = ['date']

    const metricsga = [...metricsDimensionsGa.filter(
        (a) => a.attributes.type === "METRIC"
    ), {
        id: 'ga:goal1Completions'
    }, {
        id: 'ga:goal2Completions'
    }, {
        id: 'ga:goal3Completions'
    }, {
        id: 'ga:goal4Completions'
    }, {
        id: 'ga:goal5Completions'
    }, {
        id: 'ga:goal6Completions'
    }, {
        id: 'ga:goal7Completions'
    }, {
        id: 'ga:goal8Completions'
    }, {
        id: 'ga:goal9Completions'
    }, {
        id: 'ga:goal10Completions'
    }, {
        id: 'ga:goal11Completions'
    }, {
        id: 'ga:goal12Completions'
    }, {
        id: 'ga:goal13Completions'
    }, {
        id: 'ga:goal14Completions'
    }, {
        id: 'ga:goal15Completions'
    }, {
        id: 'ga:goal16Completions'
    }, {
        id: 'ga:goal17Completions'
    }, {
        id: 'ga:goal18Completions'
    }, {
        id: 'ga:goal19Completions'
    }, {
        id: 'ga:goal20Completions'
    }, {
        id: 'ga:goal1Starts'
    }, {
        id: 'ga:goal2Starts'
    }, {
        id: 'ga:goal3Starts'
    }, {
        id: 'ga:goal4Starts'
    }, {
        id: 'ga:goal5Starts'
    }, {
        id: 'ga:goal6Starts'
    }, {
        id: 'ga:goal7Starts'
    }, {
        id: 'ga:goal8Starts'
    }, {
        id: 'ga:goal9Starts'
    }, {
        id: 'ga:goal10Starts'
    }, {
        id: 'ga:goal11Starts'
    }, {
        id: 'ga:goal12Starts'
    }, {
        id: 'ga:goal13Starts'
    }, {
        id: 'ga:goal14Starts'
    }, {
        id: 'ga:goal15Starts'
    }, {
        id: 'ga:goal16Starts'
    }, {
        id: 'ga:goal17Starts'
    }, {
        id: 'ga:goal18Starts'
    }, {
        id: 'ga:goal19Starts'
    }, {
        id: 'ga:goal20Starts'
    }, {
        id: 'ga:goal1Value'
    }, {
        id: 'ga:goal2Value'
    }, {
        id: 'ga:goal3Value'
    }, {
        id: 'ga:goal4Value'
    }, {
        id: 'ga:goal5Value'
    }, {
        id: 'ga:goal6Value'
    }, {
        id: 'ga:goal7Value'
    }, {
        id: 'ga:goal8Value'
    }, {
        id: 'ga:goal9Value'
    }, {
        id: 'ga:goal10Value'
    }, {
        id: 'ga:goal11Value'
    }, {
        id: 'ga:goal12Value'
    }, {
        id: 'ga:goal13Value'
    }, {
        id: 'ga:goal14Value'
    }, {
        id: 'ga:goal15Value'
    }, {
        id: 'ga:goal16Value'
    }, {
        id: 'ga:goal17Value'
    }, {
        id: 'ga:goal18Value'
    }, {
        id: 'ga:goal19Value'
    }, {
        id: 'ga:goal20Value'
    }, {
        id: 'ga:goal1ConversionRate'
    }, {
        id: 'ga:goal2ConversionRate'
    }, {
        id: 'ga:goal3ConversionRate'
    }, {
        id: 'ga:goal4ConversionRate'
    }, {
        id: 'ga:goal5ConversionRate'
    }, {
        id: 'ga:goal6ConversionRate'
    }, {
        id: 'ga:goal7ConversionRate'
    }, {
        id: 'ga:goal8ConversionRate'
    }, {
        id: 'ga:goal9ConversionRate'
    }, {
        id: 'ga:goal10ConversionRate'
    }, {
        id: 'ga:goal11ConversionRate'
    }, {
        id: 'ga:goal12ConversionRate'
    }, {
        id: 'ga:goal13ConversionRate'
    }, {
        id: 'ga:goal14ConversionRate'
    }, {
        id: 'ga:goal15ConversionRate'
    }, {
        id: 'ga:goal16ConversionRate'
    }, {
        id: 'ga:goal17ConversionRate'
    }, {
        id: 'ga:goal18ConversionRate'
    }, {
        id: 'ga:goal19ConversionRate'
    }, {
        id: 'ga:goal20ConversionRate'
    }, {
        id: 'ga:goal1Abandons'
    }, {
        id: 'ga:goal2Abandons'
    }, {
        id: 'ga:goal3Abandons'
    }, {
        id: 'ga:goal4Abandons'
    }, {
        id: 'ga:goal5Abandons'
    }, {
        id: 'ga:goal6Abandons'
    }, {
        id: 'ga:goal7Abandons'
    }, {
        id: 'ga:goal8Abandons'
    }, {
        id: 'ga:goal9Abandons'
    }, {
        id: 'ga:goal10Abandons'
    }, {
        id: 'ga:goal11Abandons'
    }, {
        id: 'ga:goal12Abandons'
    }, {
        id: 'ga:goal13Abandons'
    }, {
        id: 'ga:goal14Abandons'
    }, {
        id: 'ga:goal15Abandons'
    }, {
        id: 'ga:goal16Abandons'
    }, {
        id: 'ga:goal17Abandons'
    }, {
        id: 'ga:goal18Abandons'
    }, {
        id: 'ga:goal19Abandons'
    }, {
        id: 'ga:goal20Abandons'
    }, {
        id: 'ga:goal1AbandonRate'
    }, {
        id: 'ga:goal2AbandonRate'
    }, {
        id: 'ga:goal3AbandonRate'
    }, {
        id: 'ga:goal4AbandonRate'
    }, {
        id: 'ga:goal5AbandonRate'
    }, {
        id: 'ga:goal6AbandonRate'
    }, {
        id: 'ga:goal7AbandonRate'
    }, {
        id: 'ga:goal8AbandonRate'
    }, {
        id: 'ga:goal9AbandonRate'
    }, {
        id: 'ga:goal10AbandonRate'
    }, {
        id: 'ga:goal11AbandonRate'
    }, {
        id: 'ga:goal12AbandonRate'
    }, {
        id: 'ga:goal13AbandonRate'
    }, {
        id: 'ga:goal14AbandonRate'
    }, {
        id: 'ga:goal15AbandonRate'
    }, {
        id: 'ga:goal16AbandonRate'
    }, {
        id: 'ga:goal17AbandonRate'
    }, {
        id: 'ga:goal18AbandonRate'
    }, {
        id: 'ga:goal19AbandonRate'
    }, {
        id: 'ga:goal20AbandonRate'
    }, {
        id: 'ga:searchGoal1ConversionRate'
    }, {
        id: 'ga:searchGoal2ConversionRate'
    }, {
        id: 'ga:searchGoal3ConversionRate'
    }, {
        id: 'ga:searchGoal4ConversionRate'
    }, {
        id: 'ga:searchGoal5ConversionRate'
    }, {
        id: 'ga:searchGoal6ConversionRate'
    }, {
        id: 'ga:searchGoal7ConversionRate'
    }, {
        id: 'ga:searchGoal8ConversionRate'
    }, {
        id: 'ga:searchGoal9ConversionRate'
    }, {
        id: 'ga:searchGoal10ConversionRate'
    }, {
        id: 'ga:searchGoal11ConversionRate'
    }, {
        id: 'ga:searchGoal12ConversionRate'
    }, {
        id: 'ga:searchGoal13ConversionRate'
    }, {
        id: 'ga:searchGoal14ConversionRate'
    }, {
        id: 'ga:searchGoal15ConversionRate'
    }, {
        id: 'ga:searchGoal16ConversionRate'
    }, {
        id: 'ga:searchGoal17ConversionRate'
    }, {
        id: 'ga:searchGoal18ConversionRate'
    }, {
        id: 'ga:searchGoal19ConversionRate'
    }, {
        id: 'ga:searchGoal20ConversionRate'
    }, {
        id: 'ga: contentGroupUniqueViews1'
    }, {
        id: 'ga: contentGroupUniqueViews2'
    }, {
        id: 'ga: contentGroupUniqueViews3'
    }, {
        id: 'ga:contentGroupUniqueViews4'
    }, {
        id: 'ga:contentGroupUniqueViews5'
    }, {
        id: 'ga:contentGroupUniqueViews6'
    }, {
        id: 'ga:contentGroupUniqueViews7'
    }, {
        id: 'ga:contentGroupUniqueViews8'
    }, {
        id: 'ga:contentGroupUniqueViews9'
    }, {
        id: 'ga:contentGroupUniqueViews10'
    }, {
        id: 'ga:contentGroupUniqueViews11'
    }, {
        id: 'ga:contentGroupUniqueViews12'
    }, {
        id: 'ga:contentGroupUniqueViews13'
    }, {
        id: 'ga:contentGroupUniqueViews14'
    }, {
        id: 'ga:contentGroupUniqueViews15'
    }, {
        id: 'ga:contentGroupUniqueViews16'
    }, {
        id: 'ga:contentGroupUniqueViews17'
    }, {
        id: 'ga:contentGroupUniqueViews18'
    }, {
        id: 'ga:contentGroupUniqueViews19'
    }, {
        id: 'ga:contentGroupUniqueViews20'
    }, {
        id: 'ga:metric1'
    }, {
        id: 'ga:metric2'
    }, {
        id: 'ga:metric3'
    }, {
        id: 'ga:metric4'
    }, {
        id: 'ga:metric5'
    }, {
        id: 'ga:metric6'
    }, {
        id: 'ga:metric7'
    }, {
        id: 'ga:metric8'
    }, {
        id: 'ga:metric9'
    }, {
        id: 'ga:metric10'
    }, {
        id: 'ga:metric11'
    }, {
        id: 'ga:metric12'
    }, {
        id: 'ga:metric13'
    }, {
        id: 'ga:metric14'
    }, {
        id: 'ga:metric15'
    }, {
        id: 'ga:metric16'
    }, {
        id: 'ga:metric17'
    }, {
        id: 'ga:metric18'
    }, {
        id: 'ga:metric19'
    }, {
        id: 'ga:metric20'
    }].filter((metric) => !metric.id.includes('XX') ? metric : '').sort(function (a, b) {
        return a.id - b.id;
    });

    const dimensionsga = [...metricsDimensionsGa.filter(
        (a) => a.attributes.type === "DIMENSION"
    ), {
        id: 'ga:dimension1'
    }, {
        id: 'ga:dimension2'
    }, {
        id: 'ga:dimension3'
    }, {
        id: 'ga:dimension4'
    }, {
        id: 'ga:dimension5'
    }, {
        id: 'ga:dimension6'
    }, {
        id: 'ga:dimension7'
    }, {
        id: 'ga:dimension8'
    }, {
        id: 'ga:dimension9'
    }, {
        id: 'ga:dimension10'
    }, {
        id: 'ga:dimension11'
    }, {
        id: 'ga:dimension12'
    }, {
        id: 'ga:dimension13'
    }, {
        id: 'ga:dimension14'
    }, {
        id: 'ga:dimension15'
    }, {
        id: 'ga:dimension16'
    }, {
        id: 'ga:dimension17'
    }, {
        id: 'ga:dimension18'
    }, {
        id: 'ga:dimension19'
    }, {
        id: 'ga:dimension20'
    }, {
        id: 'ga:customVarName1'
    }, {
        id: 'ga:customVarName2'
    }, {
        id: 'ga:customVarName3'
    }, {
        id: 'ga:customVarName4'
    }, {
        id: 'ga:customVarName5'
    }, {
        id: 'ga:customVarName6'
    }, {
        id: 'ga:customVarName7'
    }, {
        id: 'ga:customVarName8'
    }, {
        id: 'ga:customVarName9'
    }, {
        id: 'ga:customVarName10'
    }, {
        id: 'ga:customVarName11'
    }, {
        id: 'ga:customVarName12'
    }, {
        id: 'ga:customVarName13'
    }, {
        id: 'ga:customVarName14'
    }, {
        id: 'ga:customVarName15'
    }, {
        id: 'ga:customVarName16'
    }, {
        id: 'ga:customVarName17'
    }, {
        id: 'ga:customVarName18'
    }, {
        id: 'ga:customVarName19'
    }, {
        id: 'ga:customVarName20'
    }, {
        id: 'ga:customVarValue1'
    }, {
        id: 'ga:customVarValue2'
    }, {
        id: 'ga:customVarValue3'
    }, {
        id: 'ga:customVarValue4'
    }, {
        id: 'ga:customVarValue5'
    }, {
        id: 'ga:customVarValue6'
    }, {
        id: 'ga:customVarValue7'
    }, {
        id: 'ga:customVarValue8'
    }, {
        id: 'ga:customVarValue9'
    }, {
        id: 'ga:customVarValue10'
    }, {
        id: 'ga:customVarValue11'
    }, {
        id: 'ga:customVarValue12'
    }, {
        id: 'ga:customVarValue13'
    }, {
        id: 'ga:customVarValue14'
    }, {
        id: 'ga:customVarValue15'
    }, {
        id: 'ga:customVarValue16'
    }, {
        id: 'ga:customVarValue17'
    }, {
        id: 'ga:customVarValue18'
    }, {
        id: 'ga:customVarValue19'
    }, {
        id: 'ga:customVarValue20'
    }, {
        id: 'ga:landingContentGroup1'
    }, {
        id: 'ga:landingContentGroup2'
    }, {
        id: 'ga:landingContentGroup3'
    }, {
        id: 'ga:landingContentGroup4'
    }, {
        id: 'ga:landingContentGroup5'
    }, {
        id: 'ga:landingContentGroup6'
    }, {
        id: 'ga:landingContentGroup7'
    }, {
        id: 'ga:landingContentGroup8'
    }, {
        id: 'ga:landingContentGroup9'
    }, {
        id: 'ga:landingContentGroup10'
    }, {
        id: 'ga:landingContentGroup11'
    }, {
        id: 'ga:landingContentGroup12'
    }, {
        id: 'ga:landingContentGroup13'
    }, {
        id: 'ga:landingContentGroup14'
    }, {
        id: 'ga:landingContentGroup15'
    }, {
        id: 'ga:landingContentGroup16'
    }, {
        id: 'ga:landingContentGroup17'
    }, {
        id: 'ga:landingContentGroup18'
    }, {
        id: 'ga:landingContentGroup19'
    }, {
        id: 'ga:landingContentGroup20'
    }, {
        id: 'ga:previousContentGroup1'
    }, {
        id: 'ga:previousContentGroup2'
    }, {
        id: 'ga:previousContentGroup3'
    }, {
        id: 'ga:previousContentGroup4'
    }, {
        id: 'ga:previousContentGroup5'
    }, {
        id: 'ga:previousContentGroup6'
    }, {
        id: 'ga:previousContentGroup7'
    }, {
        id: 'ga:previousContentGroup8'
    }, {
        id: 'ga:previousContentGroup9'
    }, {
        id: 'ga:previousContentGroup10'
    }, {
        id: 'ga:previousContentGroup11'
    }, {
        id: 'ga:previousContentGroup12'
    }, {
        id: 'ga:previousContentGroup13'
    }, {
        id: 'ga:previousContentGroup14'
    }, {
        id: 'ga:previousContentGroup15'
    }, {
        id: 'ga:previousContentGroup16'
    }, {
        id: 'ga:previousContentGroup17'
    }, {
        id: 'ga:previousContentGroup18'
    }, {
        id: 'ga:previousContentGroup19'
    }, {
        id: 'ga:previousContentGroup20'
    }, {
        id: 'ga:contentGroup1'
    }, {
        id: 'ga:contentGroup2'
    }, {
        id: 'ga:contentGroup3'
    }, {
        id: 'ga:contentGroup4'
    }, {
        id: 'ga:contentGroup5'
    }, {
        id: 'ga:contentGroup6'
    }, {
        id: 'ga:contentGroup7'
    }, {
        id: 'ga:contentGroup8'
    }, {
        id: 'ga:contentGroup9'
    }, {
        id: 'ga:contentGroup10'
    }, {
        id: 'ga:contentGroup11'
    }, {
        id: 'ga:contentGroup12'
    }, {
        id: 'ga:contentGroup13'
    }, {
        id: 'ga:contentGroup14'
    }, {
        id: 'ga:contentGroup15'
    }, {
        id: 'ga:contentGroup16'
    }, {
        id: 'ga:contentGroup17'
    }, {
        id: 'ga:contentGroup18'
    }, {
        id: 'ga:contentGroup19'
    }, {
        id: 'ga:contentGroup20'
    }, {
        id: 'ga:productCategoryLevel1'
    }, {
        id: 'ga:productCategoryLevel2'
    }, {
        id: 'ga:productCategoryLevel3'
    }, {
        id: 'ga:productCategoryLevel4'
    }, {
        id: 'ga:productCategoryLevel5'
    }, {
        id: 'ga:productCategoryLevel6'
    }, {
        id: 'ga:productCategoryLevel7'
    }, {
        id: 'ga:productCategoryLevel8'
    }, {
        id: 'ga:productCategoryLevel9'
    }, {
        id: 'ga:productCategoryLevel10'
    }, {
        id: 'ga:productCategoryLevel11'
    }, {
        id: 'ga:productCategoryLevel12'
    }, {
        id: 'ga:productCategoryLevel13'
    }, {
        id: 'ga:productCategoryLevel14'
    }, {
        id: 'ga:productCategoryLevel15'
    }, {
        id: 'ga:productCategoryLevel16'
    }, {
        id: 'ga:productCategoryLevel17'
    }, {
        id: 'ga:productCategoryLevel18'
    }, {
        id: 'ga:productCategoryLevel19'
    }, {
        id: 'ga:productCategoryLevel20'
    }].filter((dimension) => !dimension.id.includes('XX') ? dimension : '').sort(function (a, b) {
        return a.id - b.id;
    });

    function completeExtraction() {
        Array.from(document.querySelectorAll("input"))
            .forEach((input) => (input.value = ""))

        Array.from(document.querySelectorAll("select"))
            .forEach((option) => (option.value = ""))

        Array.from(document.querySelectorAll("textarea"))
            .forEach((textarea) => (textarea.value = ""))

        reset()
        clearSelected()
    }

    function platformPayload(bodyGA) {
        return bodyGA;
    }

    function formatWords(word) {
        const wordFormat = word
          .normalize("NFD")
          .replace(/ /g, "_")
          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z-_])/g, "")
          .replace("-", "_")
          .toLowerCase();
        return wordFormat;
    }

    const sendExtraction = event => {
        props.setModalController("create extraction")
        const bodyGA = {
            metadata: {
                extractionName: formatWords(event.nameExtractions),
                author: currentUser.email,
                customer: formatWords(event.customers),
                creationDate: new Date().toISOString(),
                expirationDate: dateExpiration.toISOString().slice(0, 10),
                consolidationWindow: 5,
                precisionInterval: 30,
                extractorModule: props.platform,
                startDate: event.date,
            },
            queryParams: {
                ids: `ga:${event.idCliente}`,
                metrics: metrics,
                dimensions: dimensions,
            },
            destination: {
                projectId: process.env.REACT_APP_DEFAULT_PROJECT_BQ,
                datasetId: formatWords(event.customers),
                tableId: `${props.platform.toLowerCase()}-${formatWords(event.customers)}-${formatWords(event.nameExtractions)}`,
                description: event.description,
            },
        };

        api
            .createDailyExtraction(platformPayload(bodyGA))
            .then(() => {
                props.setModalController("create extraction complete")
                completeExtraction()
            })
            .catch((er) => {
                if (er.request.status === 400) {
                    props.setNewError(er.data.error);
                    props.setModalController("error campo");
                } else {
                    props.setModalController("error report");
                }
            });
    }

    useEffect(() => {
        axios
            .get("https://www.googleapis.com/analytics/v3/metadata/ga/columns")
            .then((response) => {
                return response.data;
            })
            .then(async (response) => {
                setMetricsDimensionsGa(response.items);
                setDimensions([...fixedOptions])
            })
            .catch((e) => {
                throw new Error(e);
            });
    }, []);

    useEffect(() => {
        reset({
            customers: '',
            nameExtractions: '',
            date: '',
            description: '',
            idCliente: '',
        }, [clearSelected()])
    }, [props.platform, reset])

    useEffect(() => {
        if(props.preDefExtraction !== []) {
          const extractionFiltered =  props.preDefExtraction?.filter((extraction) =>  extraction.name === typeReport)
            setPreDefReport(extractionFiltered)
        }

    }, [typeReport])
    useEffect(() => {
        if(preDefReport.length !== 0) {
            setMetrics(preDefReport[0].queryParams.metrics)
            setDimensions(preDefReport[0].queryParams.dimensions)
        }
    }, [preDefReport])

    const clearSelected = () => {
        setMetrics([]);
        setDimensions([])
    };
    function handlePreDef(e) {
        e.preventDefault();
        setTypeReport(e.target.value);
    }
    return (
        <Form onSubmit={(e) => handleSubmit(sendExtraction)(e)}>
            <label htmlFor="preDef">Relatórios Predefinidos</label>
            <select
                id="preDef"
                required
                onChange={handlePreDef}
                value={typeReport}
            >
                <option value="">
                    Selecione o tipo de relatório
                </option>
                <option value="personalizado">Relatório personalizado</option>
                {props?.preDefExtraction && props.preDefExtraction.map((option) => {
                    return (
                        <option key={option.id} value={option.name}>
                            {" "}
                            {option.name}{" "}
                        </option>
                    );
                })}
            </select>
            <label htmlFor="cliente">Cliente</label>
            <select id="cliente"
                {...register("customers")}
                required
                selected
            >
                <option value="">Escolha o nome do cliente.</option>
                {props.clients !== undefined && props.clients.map((option) => {
                    return (
                        <option key={option.id} value={option.name}>
                            {" "}
                            {option.name}{" "}
                        </option>
                    );
                })}
            </select>

            <label htmlFor="idCliente">ID Cliente</label>
            <input
                required
                id="idCliente"
                placeholder={'ex: 123456789'}
                name="idCliente"
                {...register("idCliente")}
            />

            <label htmlFor="nameExtractions">Nome da extração</label>
            <input
                required
                id="nameExtractions"
                placeholder="Ex: Cliente_productPerformance"
                name="nameExtractions"
                {...register("nameExtractions")}
            />

            <Stack spacing={2}>
                <label htmlFor="metrics">Métricas</label>
                <Controller
                    control={control}
                    name="metrics"
                    render={({ field: { onChange, value } }) => (
                        <Autocomplete
                            id="metrics"
                            required
                            multiple
                            options={metricsga.map((item) => item.id.substr(3))}
                            value={metrics}
                            onChange={(_event, newMetric) => {
                                onChange(newMetric);
                                setMetrics([...newMetric])
                                if(typeReport !== "") {
                                    setTypeReport("personalizado")
                                }
                            }}
                            isOptionEqualToValue={(option, value) => option === value}
                            getOptionDisabled={() => metrics.length === 10}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    classes={{ root: "MuiTextField-root" }}
                                    placeholder="Adicione seus Métricas"
                                    onChange={onChange}
                                />
                            )}
                        />
                    )}
                />

                <label htmlFor="dimensions"> Dimensões</label>
                <Controller
                    control={control}
                    name="dimensions"
                    render={({ field: { onChange, value } }) => (
                        <Autocomplete
                            required
                            multiple
                            id="dimensions"
                            options={dimensionsga.map((item) => item.id.substr(3))}
                            getOptionLabel={(option) => option}
                            onChange={(event, newValue) => {
                                onChange(newValue);
                                setDimensions([
                                    ...fixedOptions,
                                    ...newValue.filter((option) => fixedOptions.indexOf(option) === -1)
                                ]);
                                if(typeReport !== "") {
                                    setTypeReport("personalizado")
                                }
                            }}
                            value={dimensions}
                            getOptionDisabled={() => dimensions.length === 7}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        label={option}
                                        {...getTagProps({ index })}
                                        disabled={fixedOptions.indexOf(option) === 0}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    inputProps={{ disableUnderline: true }}
                                    classes={{ root: "MuiTextField-root" }}
                                    {...params}
                                    placeholder={"Adicione suas dimensões"}
                                />
                            )}
                        />
                    )}
                />
            </Stack>

            <label htmlFor="data">Início da extração:</label>
            <input
                type="date"
                id="data"
                required
                max={new Date().toISOString().split("T")[0]}
                name="date"
                {...register("date")}
            />

            <label htmlFor="descricao">Descrição da extração</label>
            <textarea
                minLength={10}
                id="description"
                name="description"
                placeholder="Descreva brevemente sua extração."
                required
                wrap="true"
                {...register("description")}
            />

            <button type="submit">Enviar Extração</button>
        </Form>
    )
}