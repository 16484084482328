import React from "react";

import successIcon from '../../../images/successIcon.png'

import { Container, ContainerModal, Button, Text, Img } from "./style"

const ModalFeedback = ({ showModal, setShowModal }) => {

  return (
    <>
      {showModal ?
          <Container>
            <ContainerModal>
              <Img src={successIcon} alt="Icone de sucesso" />
              <Text>
                O time de Solutions Engineering agradece seu feedback ou sugestão! <br />
                Iremos avaliar seu comentário e se necessário entraremos em contato.
              </Text>
              <Button onClick={() => setShowModal(prev => !prev)}>
                Ok
              </Button>
            </ContainerModal>
          </Container>

        : null}
    </>
  )
}

export default ModalFeedback