import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/firebase";

class DocumentationRef {
    constructor() {
        this.documentationRefs = {}
    };

    async getAll() {
        const docsRef = collection(db, 'voldemortExtractorsDocs');
        const snapshot = await getDocs(docsRef);

        snapshot.forEach(doc => {
            this.documentationRefs = doc.data();
        });

        return this.documentationRefs
    };
};

export { DocumentationRef };