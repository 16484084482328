import React, { useState, useEffect, useContext } from 'react'

import styled from 'styled-components'

import Loading from '../components/Loading';
import NewsTable from '../components/NewsTable';
import CardSession from '../sessions/CardSession'
import HeaderSession from '../sessions/HeaderSession'
import Navbar from '../sessions/Navbar'
import ModalNews from '../sessions/ModalNews'
import ModalExtractionForm from '../sessions/ModalExtractionForm'
import WarningModal from '../sessions/WarningModal'


import API from '../services/api'
import { AuthContext } from "../contexts/AuthProvider";

function HomeNews() {
  const { currentUser } = useContext(AuthContext);
  const api = new API(currentUser)

  const [menuState, setMenuState] = useState(true)
  const [modalExtractionFormState, setModalExtractionFormState] = useState(false)
  const [, setActionController] = useState("");
  const [modalController, setModalController] = useState('');
  const [modalNewsState, setModalNewsState] = useState(false)
  const [modalNewsData, setModalNewsData] = useState('')
  const [newError, setNewError] = useState();
  const [search, setSearch] = useState('');
  const [, setText] = useState("");
  const [news, setNews] = useState([]);
  const [clicked, setClicked] = useState('');
  const [extractioLoad, setExtractionLoad] = useState(true)
  const [clients, setClients] = useState([]);


  async function fetchClients() {
    await api.listClients().then((response) => {
      setClients([response.data.data])

    });
  }

  function fetchNews() {
    api
      .listNews()
      .then((response) => {
        setNews(response.data.data)
        setExtractionLoad(false)
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    fetchNews();
    fetchClients();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortData = () => {
    return [...news].sort((a, b) => {
      return (
        Date.parse(a["createdAt"]) === Date.parse(b["createdAt"])
          ? 0
          : Date.parse(a["createdAt"]) > Date.parse(b["createdAt"])
            ? -1
            : Date.parse(a["createdAt"]) < Date.parse(b["createdAt"])
              ? 1
              : 0
      )
    });
  };

  const filterNews = sortData()?.filter((news) => {
    return news.title.toLowerCase().includes(search)
  })

  return (
    <ContainerMain>
      {modalController !== "" && (
        <WarningModal
          newError={newError}
          modalController={modalController}
          setModalController={setModalController}
          setAction={setActionController}
          setText={setText}
        ></WarningModal>
      )}
      <Navbar
        menuState={menuState}
        setMenuState={setMenuState}
      />

      <Main>
        <HeaderSession
          search
          setClicked={setClicked}
          setSearch={setSearch}
          text={'Novidades'}
          setModalExtractionFormState={setModalExtractionFormState}
          setModalNewsState={setModalNewsState}
          setMenuState={setMenuState}
          enableButton
        />
        {(!extractioLoad  && news.length !== 0) &&
        <CardSession
          principalTitle={'Última novidade'}
          title={sortData()[0]?.title}
          date={sortData()[0]?.createdAt}
          text={sortData()[0]?.priority}
          backgroundColor={sortData()[0]?.priority}

          filterNews={sortData()[0]}
          setModalNewsState={setModalNewsState}
          setModalNewsData={setModalNewsData}
          setMenuState={setMenuState}
          clicked={clicked}
          setClicked={setClicked}

          setModalExtractionFormState={setModalExtractionFormState}
        />
      }

        {
          extractioLoad ?
            (
              <Loading />
            ) :
            (

              <NewsTable
                clicked={clicked}
                setClicked={setClicked}
                news={filterNews}

                setModalNewsState={setModalNewsState}
                setModalNewsData={setModalNewsData}
                setModalExtractionFormState={setModalExtractionFormState}
                setMenuState={setMenuState}
              />
            )
        }
        {!extractioLoad && news.length === 0 && (
          <BoxInfo>
            <TextInfo>Não há novidades para exibir!</TextInfo>
          </BoxInfo>
        )}
      </Main>

      <ModalExtractionForm
        clients={clients}
        setNewError={setNewError}
        modalController={modalController}
        setModalController={setModalController}
        modalExtractionFormState={modalExtractionFormState}
        setModalExtractionFormState={setModalExtractionFormState}
      />

      <ModalNews
        clicked={clicked}
        setClicked={setClicked}
        modalNewsState={modalNewsState}
        setModalNewsState={setModalNewsState}
        modalNewsData={modalNewsData}
        setMenuState={setMenuState}
      />

    </ContainerMain>
  )
}

export default HomeNews;

const ContainerMain = styled.div`
  height: 100vh;

  background-color: #242424;

  display: flex;
`

const Main = styled.div`
  width: ${(props) => {
    if (props.menuState && (props.modalNewsState || props.modalExtractionFormState)) {
      return "50vw"
    }

    if (!props.menuState && (props.modalNewsState || props.modalExtractionFormState)) {
      return "70vw"
    }

    if (props.menuState && (!props.modalNewsState || !props.modalExtractionFormState)) {
      return "80vw"
    }

    if (!props.menuState && (!props.modalNewsState || !props.modalExtractionFormState)) {
      return "100vw"
    }

  }};

  height: 90vh;

  background-color: #333333;

  margin: auto 2vw;

  border-radius: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;
`

const BoxInfo = styled.div`
    height: 200%;
    justify-content: center;
    align-items: center; 
`

const TextInfo = styled.p`
    opacity: 0.4;
    color: #FFF;
    font-size: 16px;
    font-family: HelveticaNowforMonksBold;
`