import { useForm } from 'react-hook-form'
import { useContext, useState } from "react";

import API from '../../../services/api'
import { AuthContext } from "../../../contexts/AuthProvider";

import { Container, Form, ContainerButtons, Main, Button, ButtonIconeNeutral, InputSad, ButtonIconSad, Input, ButtonIconeHappy, Radio } from "./style";

import ModalFeedback from '../../Modals/ModalFeedback';

export default function FormFeedback(props) {

    const { currentUser } = useContext(AuthContext);
    const api = new API(currentUser)

    const [showModal, setShowModal] = useState(false)

    const openModal = () => {
        setShowModal(prev => !prev)
    }

    const { register, handleSubmit, reset } = useForm({
        defaultValues: {
            option: ''
        }
    })

    function completeFeedback() {
        Array.from(document.querySelectorAll("input"))
            .forEach((input) => (input.value = ""))

        Array.from(document.querySelectorAll("textarea"))
            .forEach((textarea) => (textarea.value = ""))

        openModal()
        reset()
    }

    function platformPayload(body) {
        return body
    }

    const sendFeedback = event => {
        const body = {
            author: currentUser.email,
            message: event.message,
            active: true,
            option: event.option,
            faceFeedback: event.faceFeedback
        }
        api
            .createFeedback(platformPayload(body))
            .then(() => {
                completeFeedback()
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onClickButton = () => {
        var box = document.querySelector('.containerButton'),
            buttons = box.querySelectorAll('label');

        buttons.forEach(function (a) {
            a.addEventListener('click', addAndRemoveClassList);
        });
    }

    function addAndRemoveClassList() {
        this.parentNode
            .querySelectorAll('label')
            .forEach(function (a) {
                a.classList.remove('active');
            });

        this.classList.add('active');
    }

    return (
        <Container>
            <Form onSubmit={handleSubmit(sendFeedback)}>

                <ContainerButtons className='containerButton'>
                    <InputSad
                        type='radio'
                        value="1"
                        id='1'
                        {...register("faceFeedback")} 
                        required/>
                    <ButtonIconSad
                        onClick={onClickButton}
                        htmlFor="1"
                        id='1' required/>

                    <InputSad
                        type='radio'
                        value="2"
                        id='2'
                        {...register("faceFeedback")} 
                        required/>
                    <ButtonIconeNeutral
                        onClick={onClickButton}
                        htmlFor="2"
                        id='2'required />

                    <InputSad
                        type='radio'
                        id='3'
                        value="3"
                        {...register("faceFeedback")} 
                        required/>
                    <ButtonIconeHappy 
                        onClick={onClickButton}
                        htmlFor="3"
                        id='3' required/>
                </ContainerButtons>

                <Main>
                    <Radio>
                        <Input
                            {...register("option")}
                            required
                            id="inputRadio"
                            type="radio"
                            value="Feedback" />
                        <label
                            id="labelRadio"
                            htmlFor='Feedback'>Feedback</label>

                        <Input
                            {...register("option")}
                            required
                            id="inputRadio"
                            type="radio"
                            value="Sugestão" />
                        <label
                            id="labelRadio"
                            htmlFor='Sugestão'>Sugestão</label>
                    </Radio>

                    <label htmlFor="message">Seu Feedback ou Sugestão</label>
                    <textarea
                        minLength={10}
                        id="message"
                        name="message"
                        placeholder="Escreva seu Feedback ou Sugestão"
                        required
                        wrap="true"
                        {...register("message")}
                    />
                </Main>

                <Button type="submit">Enviar</Button>

                <ModalFeedback
                    showModal={showModal}
                    setShowModal={setShowModal}
                />

            </Form>
        </Container>
    )
}