import React, { useContext } from "react";
import styled from "styled-components";
import Logo from '../images/logo.png'

import { AuthContext } from '../contexts/AuthProvider'

function UserInput() {

  const { currentUser } = useContext(AuthContext);


  return (
    <ImageContainer>
      <Img src={currentUser.photoURL}/>
    </ImageContainer>
  )
}

export default UserInput;

const ImageContainer = styled.div`
  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
`

const Img = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;
  border-radius: 50%;

  @media(max-width:1300px){
    width: 70%;
    height: 70%;
  }
`