import React, { useState, useEffect } from "react";
import styled from "styled-components";

import CloseIcon from "../images/closeIcon.svg";
import DateCheck from "../components/DateCheck";
// import StatusAlert from "../components/StatusAlert";
import DescriptionExtraction from "../components/DescriptionExtraction";
import ActionButton from "../components/ActionButton";
import InfoButton from "../components/InfoButton";
import DisableIcon from "../images/disableIcon.svg";
import TransferIcon from "../images/transferIcon.svg";
import ReportIcon from "../images/reportIcon.svg";
import FacebookIcon from "../images/facebookIcon.svg";
import ViewIcon from "../images/viewIcon.svg";
import DeleteIcon from "../images/deleteIcon.svg";
import RenewIcon from "../images/renewIcon.svg";
import Routines from "../images/routinesIcon.png";
import errorIcon from "../images/errorIcon.png";
import warning from "../images/warning.png";
import checkMarkIcon from "../images/checkMarkIcon.png";
import disableIcon from "../images/disableIcon.png";
// import CheckIcon from "../images/checkIcon.svg";
import workerIcon from "../images/workerIcon.png";
import vtexIcon from "../images/vtexIcon.png";
import googleIcon from "../images/googleIcon.svg";
import listIcon from "../images/ListExtractionIcon.svg";
import adsIcon from "../images/adsIcon.svg";
import instagramIcon from "../images/instagramExtractor.svg";
import searchConsole from "../images/searchConsole.png";
import amazonAds from "../images/amazonAdsIcon.svg";
import ga4Icon from "../images/GAnalytics.png";
import myBusinessIcon from "../images/mybusinessLogo.png";
import metaCatalogIcon from "../images/metaIcon.png";
import tiktokIcon from "../images/tiktokIcon.png";

function ModalExtractionInfo(props) {
  const [buttonActive, setButtonActive] = useState("");
  const [textInput, setTextInput] = useState("");

  useEffect(() => {
    setTextInput("");
  }, [buttonActive]);

  function handleShowMetrics() {
    try {
      if (props.modalExtractionInfoData.queryParams.breakdowns) {
        return (
          props.modalExtractionInfoData.queryParams.breakdowns.length !== 0
        );
      } else if (props.modalExtractionInfoData.queryParams.metrics) {
        return props.modalExtractionInfoData.queryParams.metrics.length !== 0;
      } else if (
        props.modalExtractionInfoData.queryParams.configuration.columns
      ) {
        return (
          props.modalExtractionInfoData.queryParams.configuration.columns
            .length !== 0
        );
      }
    } catch (error) {
      return false;
    }
  }

  function handleShowDimension() {
    try {
      if (props.modalExtractionInfoData.queryParams.fields) {
        return props.modalExtractionInfoData.queryParams.fields.length !== 0;
      } else if (props.modalExtractionInfoData.queryParams.dimensions) {
        return (
          props.modalExtractionInfoData.queryParams.dimensions.length !== 0
        );
      }
    } catch (error) {
      return false;
    }
  }

  function handleShowSegments() {
    if (props.modalExtractionInfoData.queryParams.segments) {
      return props.modalExtractionInfoData.queryParams.segments.length !== 0;
    }
  }

  function handleIdLojas() {
    const id_lojas = props.modalExtractionInfoData.queryParams.id_client
      .replace(/\s/g, "")
      .split(",");
    return id_lojas;
  }

  function handleShowDimensionFilter() {
    const queryParams = props.modalExtractionInfoData.queryParams;
    if (queryParams.dimensionFilterGroups) {
      return queryParams.dimensionFilterGroups[0].filters.length !== 0;
    }
  }

  function handleShowLevel() {
    if (props.modalExtractionInfoData.queryParams.level) {
      return props.modalExtractionInfoData.queryParams.level.length !== 0;
    }
  }

  function handleShowActionBreakdowns() {
    if (props.modalExtractionInfoData.queryParams.action_breakdowns) {
      return (
        props.modalExtractionInfoData.queryParams.action_breakdowns.length !== 0
      );
    }
  }

  function handleShowPeriod() {
    if (props.modalExtractionInfoData.queryParams.period) {
      return props.modalExtractionInfoData.queryParams.period.length !== 0;
    }
  }

  function handleShowObservationWindow() {
    if (props.modalExtractionInfoData.queryParams.observationWindow) {
      return (
        props.modalExtractionInfoData.queryParams.observationWindow.length !== 0
      );
    }
  }

  function handleShowAdProduct() {
    if (props.modalExtractionInfoData.queryParams?.configuration?.adProduct) {
      return true;
    }
  }

  function handleShowVideosIds() {
    try {
      if (props.modalExtractionInfoData.queryParams.filters) {
        return props.modalExtractionInfoData.queryParams.filters;
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleFlag(extraction) {
    if (!extraction.metadata.active) {
      return "disabled";
    } else {
      if (extraction.precision) {
        return extraction.precision.flag;
      } else {
        return 3;
      }
    }
  }
  function textStatus(status) {
    switch (status) {
      case 0:
        return "OK";
      case 1:
        return "Atenção";
      case 2:
        return "Erro";
      case "disabled":
        return "Desativado";
      case 3:
        return "Em breve";
      default:
        break;
    }
  }
  function iconStatus(status) {
    switch (status) {
      case 0:
        return checkMarkIcon;
      case 1:
        return warning;
      case 2:
        return errorIcon;
      case 3:
        return workerIcon;
      case "disabled":
        return disableIcon;
      default:
        break;
    }
  }
  function colorIcon(status) {
    switch (status) {
      case 0:
        return "#3ac2a1";
      case 1:
        return "#fce553";
      case 2:
        return "#f55747";
      case "disabled":
        return "#3d3d3d";
      case 3:
        return "#595959";
      default:
        break;
    }
  }
  function handleLastRun() {
    if (props.modalExtractionInfoData.metrics.lastRun === "--/--") {
      return "A extração ainda não rodou.";
    } else {
      try {
        const t = new Date(
          props.modalExtractionInfoData.metrics.lastRun._seconds * 1000
        );
        return "Última Atualização: " + t.toLocaleDateString("pt-BR");
      } catch (error) {
        console.log(error);
      }
    }
  }
  function handleMyBusiness() {
    switch (props.modalExtractionInfoData.metadata.extractorModule) {
      case "myBusinessExtractor1":
        return true;
      case "myBusinessExtractor2":
        return true;
      default:
        return false;
    }
  }

  function handleTextPlatform() {
    switch (props.modalExtractionInfoData.metadata.extractorModule) {
      case "fbExtractor":
        return "Facebook";
      case "fbReachExtractor":
        return "Facebook";
      case "ga1Extractor":
        return "Analytics";
      case "ga2Extractor":
        return "Analytics";
      case "vtexOrdersExtractor":
        return "VTEX";
      case "adsExtractor":
        return "Ads";
      case "search1ConsoleExtractor":
        return "Search 1";
      case "search2ConsoleExtractor":
        return "Search 2";
      case "instagramProfileExtractor":
        return "Instagram Profile";
      case "instagramFeedExtractor":
        return "Instagram Media";
      case "amazonAdsExtractor":
        return "Amazon Ads";
      case "ga4Extractor":
        return "Analytics 4";
      case "myBusinessExtractor1":
        return "My Business";
      case "myBusinessExtractor2":
        return "My Business";
      case "metaCatalogExtractor":
        return "Meta Catalog";
      case "tiktokExtractor":
        return "TikTok";
      case "tiktokReachExtractor":
        return "TikTok";
      case "tiktokPerfilExtractor":
        return "TikTok";
      case "tiktokVideoExtractor":
        return "TikTok";
      default:
        return "Em breve";
    }
  }
  function handleIconPlatform() {
    switch (props.modalExtractionInfoData.metadata.extractorModule) {
      case "fbExtractor":
        return FacebookIcon;
      case "fbReachExtractor":
        return FacebookIcon;
      case "ga1Extractor":
        return googleIcon;
      case "ga2Extractor":
        return googleIcon;
      case "vtexOrdersExtractor":
        return vtexIcon;
      case "adsExtractor":
        return adsIcon;
      case "search1ConsoleExtractor":
        return searchConsole;
      case "search2ConsoleExtractor":
        return searchConsole;
      case "instagramProfileExtractor":
        return instagramIcon;
      case "instagramFeedExtractor":
        return instagramIcon;
      case "amazonAdsExtractor":
        return amazonAds;
      case "ga4Extractor":
        return ga4Icon;
      case "myBusinessExtractor1":
        return myBusinessIcon;
      case "myBusinessExtractor2":
        return myBusinessIcon;
      case "metaCatalogExtractor":
        return metaCatalogIcon;
      case "tiktokExtractor":
        return tiktokIcon;
      case "tiktokReachExtractor":
        return tiktokIcon;
      case "tiktokPerfilExtractor":
        return tiktokIcon;
      case "tiktokVideoExtractor":
        return tiktokIcon;
      default:
        return listIcon;
    }
  }

  // function handleAlertStatus() {
  //   if (
  //     props.modalExtractionInfoData.metadata.extractorModule === "fbExtractor"
  //   ) {
  //     return "O Alerta  de status de Facebook está em construção, logo está disponível para visualização.";
  //   } else {
  //     if (props.modalExtractionInfoData.precision) {
  //       const one_day = 1000 * 60 * 60 * 24;
  //       const dateParse =
  //         Date.parse(props.modalExtractionInfoData.precision.dateRange.until) -
  //         Date.parse(props.modalExtractionInfoData.precision.dateRange.since);
  //       const difference = dateParse / one_day;
  //       const precisionStart =
  //         props.modalExtractionInfoData.precision.dateRange.since;
  //       const precisionEnd =
  //         props.modalExtractionInfoData.precision.dateRange.until;
  //       try {
  //         return `A extração foi analisada com base nos últimos ${difference} dias, sendo o primeiro dia ${precisionStart} e o último ${precisionEnd}.`;
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   }
  // }

  // function handleMetrics() {
  //   if (props.modalExtractionInfoData.precision) {
  //     return props.modalExtractionInfoData.precision.metrics;
  //   } else {
  //     return "";
  //   }
  // }

  // function largeBoxMetrics(item){
  //  return item
  // }

  if (props.modalExtractionInfoState) {
    return (
      <Container>
        <Img
          src={CloseIcon}
          onClick={() => {
            props.setModalExtractionInfoState(false);
            props.setClicked("");
          }}
        ></Img>
        <SubContainer>
          <ContentContainer>
            <Title>
              {props.modalExtractionInfoData.metadata.customer} |{"  "}
              {props.modalExtractionInfoData.metadata.extractionName}
            </Title>
            <ButtonsHeaderContainer>
              <InfoButton text={"Daily"} icon={Routines} />
              {props.modalExtractionInfoData.metadata.extractorModule && (
                <InfoButton
                  text={handleTextPlatform()}
                  icon={handleIconPlatform()}
                  width={
                    props.modalExtractionInfoData.metadata.extractorModule ===
                    "vtexOrdersExtractor"
                      ? 30
                      : 20
                  }
                />
              )}
              <InfoButton
                text={textStatus(handleFlag(props.modalExtractionInfoData))}
                icon={iconStatus(handleFlag(props.modalExtractionInfoData))}
                id={handleFlag(props.modalExtractionInfoData)}
                backgroundColor={colorIcon(
                  handleFlag(props.modalExtractionInfoData)
                )}
                fontColor={
                  handleFlag(props.modalExtractionInfoData) === 1
                    ? "#000000"
                    : "#FFFFFF"
                }
                hidden="hidden"
              />
            </ButtonsHeaderContainer>
            <TitleClientName></TitleClientName>
            <DateCheck date={handleLastRun()}></DateCheck>
            {/* {(props.modalExtractionInfoData.precision && props.modalExtractionInfoData.metadata.active) &&<StatusAlert
              metrics={handleMetrics()}
              color={colorIcon(
                handleFlag(props.modalExtractionInfoData))}
              statusDesc={handleAlertStatus()}
            ></StatusAlert>} */}
            <DescriptionExtraction
              description={
                props.modalExtractionInfoData.destination.description
              }
            />
            <h2>{`autor: ${props.modalExtractionInfoData.metadata.author}`}</h2>
            {props.modalExtractionInfoData.queryParams.resource && (
              <>
                <LabelMetrics>Recurso</LabelMetrics>
                <BoxMetricsDimension>
                  <MetricsInput
                    value={props.modalExtractionInfoData.queryParams.resource}
                    width={23}
                    type={"text"}
                    disabled
                  ></MetricsInput>
                </BoxMetricsDimension>
              </>
            )}

            {handleShowLevel() && (
              <>
                <LabelMetrics>
                  {props.modalExtractionInfoData.queryParams.level
                    ? "Level"
                    : ""}
                </LabelMetrics>
                <BoxMetricsDimension>
                  <MetricsInput
                    width={23}
                    value={props.modalExtractionInfoData.queryParams.level}
                    disabled
                    type={"text"}
                  />
                </BoxMetricsDimension>
              </>
            )}
            {handleMyBusiness() && (
              <>
                <LabelMetrics>
                  {props.modalExtractionInfoData.queryParams.id_client
                    ? "id's Lojas"
                    : ""}
                </LabelMetrics>
                <BoxMetricsDimension>
                  {handleIdLojas().map((idLoja) => {
                    return (
                      <MetricsInput
                        width={23}
                        value={idLoja}
                        disabled
                        type={"text"}
                      />
                    );
                  })}
                </BoxMetricsDimension>
              </>
            )}

            {handleShowActionBreakdowns() && (
              <>
                <LabelMetrics>
                  {props.modalExtractionInfoData.queryParams.action_breakdowns
                    ? "Action Breakdown"
                    : ""}
                </LabelMetrics>
                <BoxMetricsDimension>
                  {props.modalExtractionInfoData.queryParams.action_breakdowns.map(
                    (item) => {
                      return (
                        <MetricsInput
                          key={item}
                          length={
                            props.modalExtractionInfoData.queryParams
                              .action_breakdowns.length
                          }
                          width={item.length}
                          type={"text"}
                          value={item}
                          disabled
                        ></MetricsInput>
                      );
                    }
                  )}
                </BoxMetricsDimension>
              </>
            )}

            {handleShowPeriod() && (
              <>
                <LabelMetrics>
                  {props.modalExtractionInfoData.queryParams.period
                    ? "Period"
                    : ""}
                </LabelMetrics>
                <BoxMetricsDimension>
                  <MetricsInput
                    width={23}
                    value={props.modalExtractionInfoData.queryParams.period}
                    disabled
                    type={"text"}
                  />
                </BoxMetricsDimension>
              </>
            )}

            {handleShowObservationWindow() && (
              <>
                <LabelMetrics>
                  {props.modalExtractionInfoData.queryParams.observationWindow
                    ? "Janela de observação"
                    : ""}
                </LabelMetrics>
                <BoxMetricsDimension>
                  <MetricsInput
                    width={23}
                    value={
                      props.modalExtractionInfoData.queryParams
                        .observationWindow +
                      `${
                        props.modalExtractionInfoData.queryParams
                          .observationWindow > 1
                          ? " Dias"
                          : " Dia"
                      }`
                    }
                    disabled
                    type={"text"}
                  />
                </BoxMetricsDimension>
              </>
            )}

            {handleShowAdProduct() && (
              <>
                <LabelMetrics>
                  {props.modalExtractionInfoData.queryParams.configuration
                    .adProduct
                    ? "Ad Product"
                    : ""}
                </LabelMetrics>
                <BoxMetricsDimension>
                  <MetricsInput
                    width={23}
                    value={
                      props.modalExtractionInfoData.queryParams.configuration
                        .adProduct
                    }
                    disabled
                    type={"text"}
                  />
                </BoxMetricsDimension>
              </>
            )}

            {handleShowVideosIds() &&
              props.modalExtractionInfoData.queryParams.filters &&
              props.modalExtractionInfoData.queryParams.filters.some(
                (item) => item !== ""
              ) && (
                <>
                  <LabelMetrics>Vídeos ID's</LabelMetrics>
                  <BoxMetricsDimension>
                    {props.modalExtractionInfoData.queryParams.filters.map(
                      (item) => {
                        return item !== "" ? (
                          <MetricsInput
                            key={item}
                            length={
                              props.modalExtractionInfoData.queryParams.filters
                                .length
                            }
                            width={item.length}
                            type={"text"}
                            value={item}
                            disabled
                          ></MetricsInput>
                        ) : null;
                      }
                    )}
                  </BoxMetricsDimension>
                </>
              )}

            {handleShowMetrics() && (
              <>
                <LabelMetrics>
                  {props.modalExtractionInfoData.queryParams.breakdowns
                    ? "breakdowns"
                    : "metrics"}
                </LabelMetrics>
                <BoxMetricsDimension>
                  {props.modalExtractionInfoData.queryParams.breakdowns
                    ? props.modalExtractionInfoData.queryParams.breakdowns.map(
                        (item) => {
                          return (
                            <MetricsInput
                              key={item}
                              length={
                                props.modalExtractionInfoData.queryParams
                                  .breakdowns.length
                              }
                              width={item.length}
                              type={"text"}
                              value={item}
                              disabled
                            ></MetricsInput>
                          );
                        }
                      )
                    : props.modalExtractionInfoData.queryParams.metrics
                    ? props.modalExtractionInfoData.queryParams.metrics.map(
                        (item) => {
                          return (
                            <MetricsInput
                              key={item}
                              length={
                                props.modalExtractionInfoData.queryParams
                                  .metrics.length
                              }
                              type={"text"}
                              width={item.length}
                              value={item}
                              disabled
                            ></MetricsInput>
                          );
                        }
                      )
                    : props.modalExtractionInfoData.queryParams.configuration
                        .columns
                    ? props.modalExtractionInfoData.queryParams.configuration.columns.map(
                        (item) => {
                          return (
                            <MetricsInput
                              key={item}
                              length={
                                props.modalExtractionInfoData.queryParams
                                  .configuration.columns.length
                              }
                              width={item.length}
                              type="text"
                              value={item}
                              disabled
                            />
                          );
                        }
                      )
                    : ""}
                </BoxMetricsDimension>
              </>
            )}

            {handleShowDimension() && (
              <>
                <LabelMetrics>
                  {props.modalExtractionInfoData.queryParams.fields
                    ? "fields"
                    : "dimensions"}
                </LabelMetrics>
                <BoxMetricsDimension>
                  {props.modalExtractionInfoData.queryParams.fields
                    ? props.modalExtractionInfoData.queryParams.fields.map(
                        (item) => {
                          return (
                            <MetricsInput
                              key={item}
                              length={
                                props.modalExtractionInfoData.queryParams.fields
                                  .length
                              }
                              width={item.length}
                              type={"text"}
                              value={item}
                              disabled
                            ></MetricsInput>
                          );
                        }
                      )
                    : props.modalExtractionInfoData.queryParams.dimensions
                    ? props.modalExtractionInfoData.queryParams.dimensions.map(
                        (item) => {
                          return (
                            <MetricsInput
                              key={item}
                              length={
                                props.modalExtractionInfoData.queryParams
                                  .dimensions.length
                              }
                              width={item.length}
                              type={"text"}
                              value={item}
                              disabled
                            ></MetricsInput>
                          );
                        }
                      )
                    : ""}
                </BoxMetricsDimension>{" "}
              </>
            )}

            {handleShowDimensionFilter() && (
              <>
                <LabelMetrics>Dimension Filter</LabelMetrics>
                {props.modalExtractionInfoData.queryParams.dimensionFilterGroups[0].filters.map(
                  (item, index) => {
                    return (
                      <>
                        <LabelMetrics>{item.dimension}</LabelMetrics>
                        <BoxMetricsDimension key={index}>
                          <MetricsInput
                            key={1}
                            length={item.dimension.length}
                            width={23}
                            type={"text"}
                            value={"Dimension: " + item.dimension}
                            disabled
                          ></MetricsInput>
                          <MetricsInput
                            key={2}
                            length={item.expression.length}
                            width={23}
                            type={"text"}
                            value={"Expression: " + item.expression}
                            disabled
                          ></MetricsInput>
                          <MetricsInput
                            key={3}
                            length={item.expression.length}
                            width={23}
                            type={"text"}
                            value={"Operator: " + item.operator}
                            disabled
                          ></MetricsInput>
                        </BoxMetricsDimension>{" "}
                      </>
                    );
                  }
                )}
              </>
            )}

            {handleShowSegments() && (
              <>
                <LabelMetrics>Segments</LabelMetrics>
                <BoxMetricsDimension>
                  {props.modalExtractionInfoData.queryParams.segments.map(
                    (item) => {
                      return (
                        <MetricsInput
                          key={item}
                          length={
                            props.modalExtractionInfoData.queryParams.segments
                              .length
                          }
                          width={item.length}
                          type={"text"}
                          value={item}
                          disabled
                        ></MetricsInput>
                      );
                    }
                  )}
                </BoxMetricsDimension>{" "}
              </>
            )}

            <BoxButtons>
              <ActionButton
                buttonId={"transfer"}
                buttonActive={buttonActive}
                setButtonActive={setButtonActive}
                text={"Transferir"}
                icon={TransferIcon}
              />

              {props.modalExtractionInfoData.metadata.active && (
                <ActionButton
                  extractionId={props.modalExtractionInfoData.id}
                  setExtractionId={props.setExtractionId}
                  setModalController={props.setModalController}
                  setButtonActive={setButtonActive}
                  buttonId={"disable"}
                  text={"Desativar"}
                  icon={DisableIcon}
                  extractionName={
                    props.modalExtractionInfoData.metadata.extractionName
                  }
                  setExtractionName={props.setExtractionName}
                />
              )}
              {props.modalExtractionInfoData.metadata.active === false && (
                <ActionButton
                  action={"open"}
                  extractionId={props.modalExtractionInfoData.id}
                  setModalController={props.setModalController}
                  setExtractionId={props.setExtractionId}
                  buttonId={"active"}
                  text={"Ativar"}
                  setButtonActive={setButtonActive}
                  icon={DisableIcon}
                  extractionName={
                    props.modalExtractionInfoData.metadata.extractionName
                  }
                  setExtractionName={props.setExtractionName}
                />
              )}
              <ActionButton
                buttonId={"report"}
                buttonActive={buttonActive}
                setButtonActive={setButtonActive}
                text={"Reportar"}
                icon={ReportIcon}
              />
              <ActionButton
                linkTo={props.modalExtractionInfoData.destination.tableUrl}
                buttonId={"view"}
                buttonActive={buttonActive}
                setButtonActive={setButtonActive}
                text={"Ver Dados"}
                icon={ViewIcon}
              />
            </BoxButtons>
            {buttonActive === "transfer" && (
              <BoxTextReport>
                <TrasferInput
                  type={"email"}
                  placeholder={"Digite o email: example@rocky.ag"}
                  onChange={(e) => setTextInput(e.target.value)}
                />
                <ActionButton
                  setButtonActive={setButtonActive}
                  author={props.modalExtractionInfoData.metadata.author}
                  setPreviousAuthor={props.setPreviousAuthor}
                  extractionName={
                    props.modalExtractionInfoData.metadata.extractionName
                  }
                  setExtractionName={props.setExtractionName}
                  setExtractionId={props.setExtractionId}
                  extractionId={props.modalExtractionInfoData.id}
                  setText={props.setText}
                  textInput={textInput}
                  setModalController={props.setModalController}
                  buttonId={"submitTransfer"}
                  text={"Transferir"}
                ></ActionButton>
              </BoxTextReport>
            )}
            {buttonActive === "report" && (
              <BoxTextReport>
                <ReportText
                  placeholder={"Escreva seu report:"}
                  onChange={(e) => setTextInput(e.target.value)}
                />
                <ActionButton
                  setButtonActive={setButtonActive}
                  setAction={props.setAction}
                  author={props.modalExtractionInfoData.metadata.author}
                  setExtractionId={props.setExtractionId}
                  extractionId={props.modalExtractionInfoData.id}
                  setText={props.setText}
                  setAuthor={props.setAuthor}
                  textInput={textInput}
                  setModalController={props.setModalController}
                  buttonId={"submitReport"}
                  text={"Reportar"}
                ></ActionButton>
              </BoxTextReport>
            )}
            <BoxFooter>
              <ActionButton
                buttonId={"renew"}
                setNewDate={props.setNewDate}
                expirationDate={
                  props.modalExtractionInfoData.metadata.expirationDate
                }
                setButtonActive={setButtonActive}
                extractionId={props.modalExtractionInfoData.id}
                setModalController={props.setModalController}
                setExtractionId={props.setExtractionId}
                text={"Renovar"}
                icon={RenewIcon}
                backgroundColor={"#FEC84B"}
                color={"#242424"}
                colorHover={"#fdad17"}
              />
              <ActionButton
                buttonId={"delete"}
                setExtractionName={props.setExtractionName}
                extractionName={
                  props.modalExtractionInfoData.metadata.extractionName
                }
                activeDeactive={props.modalExtractionInfoData.metadata.active}
                setButtonActive={setButtonActive}
                extractionId={props.modalExtractionInfoData.id}
                setModalController={props.setModalController}
                setExtractionId={props.setExtractionId}
                text={"Excluir"}
                icon={DeleteIcon}
                backgroundColor={"#F04438"}
                colorHover={"#d1141c"}
              />
            </BoxFooter>
          </ContentContainer>
        </SubContainer>
      </Container>
    );
  }
}

export default ModalExtractionInfo;

const Container = styled.div`
  width: 35vw;
  max-width: 455px;
  height: 90vh;

  background-color: #333333;

  margin: auto 2vw auto 0;

  border-radius: 20px;

  position: relative;
  h2 {
    color: #ffffff;
    font-size: 16px;
  }
  @media (max-width: 1300px) {
    max-width: 300px;
  }
`;

const Img = styled.img`
  width: 30px;
  height: 30px;

  position: absolute;
  right: 15px;
  top: 15px;

  cursor: pointer;
`;

const SubContainer = styled.div`
  margin: 15px;
  margin-top: 60px;
  height: 90%;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #333333;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #242424;
    border-radius: 12px;
  }

  @media (max-width: 1300px) {
    height: 85%;
  }
`;

const ContentContainer = styled.div`
  text-align: justify;
  margin: 10px;
`;

const Title = styled.h1`
  font-family: "HelveticaNowforMonksExtraBold";
  font-style: normal;
  font-size: 20px;

  color: #ffffff;

  word-break: break-all;

  @media (max-width: 1300px) {
    font-size: 16px;
  }
`;

const ButtonsHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 25px;
`;

const TitleClientName = styled(Title)`
  font-family: "HelveticaNowforMonksMedium";
  font-size: 25px;

  color: #ffffff;

  word-break: break-all;

  @media (max-width: 1300px) {
    font-size: 16px;
  }
`;

const BoxMetricsDimension = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(50px, auto));
  width: 100%;
  min-height: 25px;
  max-height: auto;
  background-color: #242424;
  padding: 3px;
  margin-top: 15px;
  border-radius: 10px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #333333;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #242424;
    border-radius: 12px;
  }
`;

const BoxButtons = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  margin-top: 50px;
  column-gap: 10%;
`;

const BoxFooter = styled.div`
  width: 100%;

  margin-top: 50px;
  column-gap: 10%;

  display: grid;
  grid-template-columns: 1fr 1fr;
`;
const BoxTextReport = styled.div`
  background: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
`;
const ReportText = styled.textarea`
  font-family: "HelveticaNowforMonksBold";
  font-size: 14px;
  resize: none;
  width: 100%;
  height: 100px;
  color: #ffffff;
  background-color: #242424;
  border-radius: 10px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #333333;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #242424;
    border-radius: 12px;
  }
`;

const LabelMetrics = styled.div`
  font-family: "HelveticaNowforMonksExtraBold";
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 10px;
`;
const TrasferInput = styled.input`
  width: 98%;
  height: 25px;
  color: #ffffff;
  background-color: #242424;
  border-radius: 5px;
`;

const MetricsInput = styled.input`
  font-family: "HelveticaNowforMonksBold";
  min-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  margin: 3px;
  font-style: normal;
  font-size: 14px;
  border-radius: 10px;
  width: auto;
  grid-column: ${(props) => {
    if (props.width >= 22) {
      return "1/3";
    } else if (props.length === 1) {
      return "1/3";
    } else {
      return 0;
    }
  }};
  order: ${(props) => (props.width >= 22 ? "2" : 1)};
  background-color: #333333;
  color: #ffffff;
  @media (max-width: 1300px) {
    grid-column: 1/3;
  }
`;
