import styled from "styled-components";
import {
  DataGrid,
  GridFilterPanel,
  GridPanelContent,
  GridPanelWrapper,
  GridToolbarContainer,
  GridToolbarFilterButton,
  ptBR,
} from "@mui/x-data-grid";

import vtexIcon from "../images/vtexIcon.svg";
import adsIcon from "../images/adsIcon.svg";
import instagramIcon from "../images/instagramExtractor.svg";
import searchConsoleIcon from "../images/searchConsole.png";
import amazonAdsIcon from "../images/amazonAdsIcon.svg";
import ga4Icon from "../images/GAnalytics.png";
import myBusinessIcon from "../images/mybusinessLogo.png";
import facebookIcon from "../images/facebookIcon.svg";
import universalAnalyticsIcon from "../images/googleIcon.svg";
import hourIcon from "../images/hourIcon.svg";
import merchantCenterIcon from "../images/MerchantCenterIcon.png";
import totalExpressIcon from "../images/logo-total-express-white.png";
import lettIcon from "../images/logo-lett.png";
import metaCatalogIcon from "../images/metaIcon.png";
import tiktokIcon from "../images/tiktokIcon.png";

const extractorPlataformMapIcon = {
  VTEX: vtexIcon,
  Ads: adsIcon,
  Facebook: facebookIcon,
  GA4: ga4Icon,
  "My Business 1": myBusinessIcon,
  "My Business 2": myBusinessIcon,
  "Instagram Profile": instagramIcon,
  "Instagram Media": instagramIcon,
  "Search Console 1": searchConsoleIcon,
  "Search Console 2": searchConsoleIcon,
  "Universal Analytics 1": universalAnalyticsIcon,
  "Universal Analytics 2": universalAnalyticsIcon,
  "Amazon Ads": amazonAdsIcon,
  "Merchant Center": merchantCenterIcon,
  "Lett Markets": lettIcon,
  "Lett Primary Pics": lettIcon,
  "Lett Retailer Statistics": lettIcon,
  "Lett Secondary Pics": lettIcon,
  "Lett Share if Search": lettIcon,
  "Lett Competion Retailer Statistics": lettIcon,
  "Lett Products": lettIcon,
  "Total Express": totalExpressIcon,
  "Google Merchant Center Feed GA2": merchantCenterIcon,
  "Facebook Catalogo": metaCatalogIcon,
  TikTok: tiktokIcon,
  "TikTok Video": tiktokIcon,
  "TikTok Perfil": tiktokIcon,
  "TikTok Reach": tiktokIcon,
  "Facebook Reach": facebookIcon,
};

const extractorModulePlataformMap = {
  vtexOrdersExtractor: "VTEX",
  adsExtractor: "Ads",
  fbExtractor: "Facebook",
  fbReachExtractor: "Facebook Reach",
  ga4Extractor: "GA4",
  myBusinessExtractor1: "My Business 1",
  myBusinessExtractor2: "My Business 2",
  instagramProfileExtractor: "Instagram Profile",
  instagramFeedExtractor: "Instagram Media",
  search1ConsoleExtractor: "Search Console 1",
  search2ConsoleExtractor: "Search Console 2",
  ga1Extractor: "Universal Analytics 1",
  ga2Extractor: "Universal Analytics 2",
  amazonAdsExtractor: "Amazon Ads",
  lettMarketsExtractor: "Lett Markets",
  lettPrimaryPicsExtractor: "Lett Primary Pics",
  lettRetailerStatistcsExtractor: "Lett Retailer Statistics",
  lettSecondaryPicsExtractor: "Lett Secondary Pics",
  lettShareOfSearchExtractor: "Lett Share if Search",
  lettCompetitorsRetailerStatistcsExtractor:
    "Lett Competion Retailer Statistics",
  lettProductsExtractor: "Lett Products",
  merchantFeedExtractor: "Merchant Center",
  totalExpressExtractor: "Total Express",
  merchantFeedExtractorGa2: "Google Merchant Center Feed GA2",
  metaCatalogExtractor: "Facebook Catalogo",
  tiktokExtractor: "TikTok",
  tiktokVideoExtractor: "TikTok Video",
  tiktokPerfilExtractor: "TikTok Perfil",
  tiktokReachExtractor: "TikTok Reach",
  instagramMediaExtractorV3: "Instagram Media",
  instagramProfileExtractorV3: "Instagram Profile",
};

const columns = [
  {
    field: "plataform",
    headerName: "Plataforma",
    width: 200,
    headerClassName: "uppercase",
    fontWeight: "bold",
    renderCell: (params) => {
      return (
        <img
          alt={`${params.value}-icon`}
          src={extractorPlataformMapIcon[params.value]}
          title={params.value}
          style={{ width: "32px" }}
        />
      );
    },
  },
  {
    field: "client",
    headerName: "Cliente",
    flex: 1,
    headerClassName: "uppercase",
    fontWeight: "bold",
  },
  {
    field: "extraction",
    headerName: "Extração",
    flex: 2,
    headerClassName: "uppercase",
    fontWeight: "bold",
    filterable: false,
  },
  {
    field: "lastUpdate",
    headerName: "Última atualização",
    flex: 1,
    headerClassName: "uppercase",
    fontWeight: "bold",
    headerAlign: "center",
    align: "center",
    filterable: false,
    renderCell: (params) => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <img
            alt={`hour-icon`}
            src={hourIcon}
            style={{ width: "20px", height: "20px" }}
          />
          <strong>{params.value}</strong>
        </div>
      );
    },
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton
        sx={{
          fontWeight: "bold",
          fontFamily: "Space Grotesk, sans-serif",
          paddingY: "8px",
          paddingX: "16px",
          fontSize: "16px",
        }}
      />
    </GridToolbarContainer>
  );
}

function CustomFilterPanel() {
  return (
    <GridPanelWrapper
      sx={{
        background: "#333",
        color: "#fff",
      }}
    >
      <GridPanelContent>
        <GridFilterPanel
          sx={{
            fontFamily: "Space Grotesk, sans-serif",
          }}
        />
      </GridPanelContent>
    </GridPanelWrapper>
  );
}

export default function ExtractionsTable(props, { status }) {
  const data = props.extractionList;

  const rows = data.map((extraction) => {
    return {
      id: extraction.id,
      plataform:
        extractorModulePlataformMap[extraction.metadata.extractorModule],
      client: extraction.metadata.customer,
      extraction: extraction.metadata.extractionName,
      lastUpdate: handleLastRun(extraction.metrics.lastRun),
    };
  });

  function handleLastRun(dateObject) {
    if (dateObject === "--/--") {
      return "--/--";
    } else {
      try {
        const date = new Date(dateObject._seconds * 1000);
        return date.toLocaleDateString("pt-BR");
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <ListContainer>
      <DataGrid
        sx={{
          width: "91%",
          margin: "0",
          height: "100%",
          border: "none",
          borderRadius: "20px",
          color: "#FFF",
          fontSize: "16px",
          fontFamily: "Space Grotesk, sans-serif",
          "&, [class^=MuiDataGrid]": { border: "none" },
          "& .MuiTablePagination-root": {
            color: "#fff",
            fontFamily: "Space Grotesk, sans-serif",
          },
          "& .MuiTablePagination-displayedRows": {
            color: "#fff",
            fontFamily: "Space Grotesk, sans-serif",
          },
          "& .MuiTablePagination-selectLabel": {
            color: "#fff",
            fontFamily: "Space Grotesk, sans-serif",
          },
          "& .MuiButtonBase-root": {
            color: "#fff",
          },
          "& .MuiButtonBase-root:hover": {
            background: "#242424",
          },
          "& .MuiDataGrid-row": {
            width: "calc(100% - 16px)",
            borderRadius: "8px",
            cursor: "pointer",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeadersInner": {
            borderBottom: "2px solid #fff",
          },
          "& .Mui-selected": {
            color: "#F6BE27",
            background: "#242424 !important",
          },
          "& .MuiDataGrid-toolbar": {
            color: "#FFF",
          },
          "& .MuiDataGrid-row:hover": {
            color: "#F6BE27",
            bgcolor: "#242424",
          },
          "& .MuiDataGrid-virtualScroller": {
            marginTop: "16px",
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            width: "10px",
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
            borderRadius: "20px",
            background: "none",
          },
          "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
            backgroundColor: "#242424",
            borderRadius: "8px",
          },
        }}
        rows={rows}
        columns={columns}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        onRowClick={(params) => {
          props.setModalExtractionFormState(false);
          props.setMenuState(false);
          props.setModalExtractionInfoState(true);
          props.setModalExtractionInfoData(
            data.find((extraction) => extraction.id === params.id)
          );
          props.setClicked(params.id);
        }}
        slots={{
          toolbar: CustomToolbar,
          filterPanel: CustomFilterPanel,
        }}
      />
    </ListContainer>
  );
}

const ListContainer = styled.div`
  width: 100%;
  height: 72%;

  margin: 2% auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
