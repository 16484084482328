import styled from "styled-components";

export default function InfoButton(props) {
    return (
       <Button backgroundColor={props.backgroundColor} hidden={props.hidden}>
           {props.icon ? <Img width={props.width ? props.width : 20} id={props.id} src={props.icon}></Img> : ""}
           <ButtonText fontColor={props.fontColor}  fontSize={props.fontSize}>{props.text}</ButtonText>
       </Button>
    )
}

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  align-items: center;
  gap: 12px;
  
  border: none;

  width: 100px;
  height: 35px;
  cursor: pointer;

  background: ${props => props.backgroundColor ? props.backgroundColor : '#242424'};
  border-radius: 4px;

  visibility: ${props => props.hidden ? props.hidden : 'visible'};

  @media (max-width: 1300px) {
    width: 75px;
    height: 30px;
    gap: 7px;
  }

`

const ButtonText = styled.p`
  font-family: 'HelveticaNowforMonksMedium';
  font-style: normal;
  font-size: ${props => props.fontSize ? props.fontSize : '12px'};

  color: ${props => props.fontColor ? props.fontColor : '#FFFFFF'};

  @media (max-width: 1300px) {
    font-size: 10px;
  }
`

const Img = styled.img`
  width: ${props => props.width}px;

  @media (max-width: 1300px) {
    width: 15px;
  }
`