import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  position: absolute;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  background-color: rgba(255, 255, 255, 0.4);
  background-size: contain;


  animation: init;
  animation-duration: 1s;
  @keyframes init {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

`

export const ContainerModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 25px;
  width: 550px;
  height: 270px;
  padding-bottom: 10px;
  padding-top: 5px;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: #333333;
  /* margin-left: 50px;  */
  
`

export const Button = styled.div`
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 15px;

    border-radius: 5px;
    background: #242424;

    border: none;

    cursor: pointer;

    &:hover {
      background-color: #0d5eff;
      transition: 0.5s;
    }

    font-family: "HelveticaNowforMonksMedium";
    font-style: normal;
    font-size: 16px;
    color: #ffffff;

    @media (max-width: 1300px) {
      font-size: 13px;
    }
`

export const Text = styled.p`
    font-family: "HelveticaNowforMonksMedium";
    font-style: normal;
    font-size: 16px;
    color: #f6be27;
    text-align: center;
    padding-bottom: 30px;
`

export const Img = styled.img`
   width: 30px;
`