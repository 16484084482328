import React from 'react'

import { Table, TableHead, TableBody, TableRow, TableHeadData, TableData, TableRowHeader } from './style'


function FeedbackTable(props) {

    return (
        <Table>
            <TableHead>
                <TableRowHeader>
                    <TableHeadData>EMAIL</TableHeadData>
                    <TableHeadData>DATA</TableHeadData>
                </TableRowHeader>
            </TableHead>
            <TableBody>
                {
                    props.report.map((report) => (
                        <TableRow
                            key={report.id}
                            report={report}
                            setClicked={props.setClicked}
                            clicked={props.clicked}
                            setModalReportState={props.setModalReportState}
                            setModalReportData={props.setModalReportData}

                            onClick={() => {
                                props.setMenuState(false)
                                props.setModalReportState(true)
                                props.setModalReportData(report)
                                props.setClicked(report.id)
                            }}

                            className={props.clicked === report.id ? 'active' : ''}
                        >
                            <TableData>{report.author}</TableData>
                            <TableData>
                                {
                                    new Date(report.createdAt).toLocaleDateString("pt-BR")
                                }
                            </TableData>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    )
}

export default FeedbackTable;