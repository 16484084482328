function processCSHelperHeadAnswers(currentArea) {
    const data = JSON.parse(window.localStorage.getItem("head_answers"))
    let processed = []
    const head = Object.keys(data)[0]

    for(let area in data[head]) {
        if(area === currentArea) {
            for(let coord in data[head][area]) {
                const auxObj = {
                    "date": new Date().toISOString().slice(0,10),
                    "head": head,
                    "area": area,
                    "coordenador": coord.split(" : ")[0],
                    "cliente": coord.split(" : ")[1],  
                }

                const answers = Object.keys(data[head][area][coord])
                
                answers.forEach(answer => {
                    auxObj[answer] = data[head][area][coord][answer][0]
                    auxObj[`comentario_${answer}`] = data[head][area][coord][answer][1]
                })

                if(auxObj.resultados !== "") {
                    processed.push(auxObj)
                }
            }
        }
    };

    return processed
}

export default processCSHelperHeadAnswers