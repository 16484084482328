import React from 'react'
import styled from 'styled-components'

import Search from '../components/Search'
import NotifyButton from '../components/NotifyButton'
import UserInput from '../components/UserInput'
import PageTitle from '../components/PageTitle'
import ButtonExtraction from '../components/ButtonExtraction'

function HeaderSession(props) {

  return (
    <HeaderContainer>
      <FirstRow>
        <LeftCollumn>
          {props.search &&
            <Search text={'Pesquisar por nome'} setSearch={props.setSearch}></Search>
          }
        </LeftCollumn>
        <RightCollumn>
          <NotifyButton></NotifyButton>
          <UserInput />
        </RightCollumn>
      </FirstRow>
      <SecondRow>
        <PageTitle text={props.text}></PageTitle>
        {
          props.enableButton && (
            <ButtonExtraction
              setClicked={props.setClicked}
              setModalExtractionFormState={props.setModalExtractionFormState}
              setModalExtractionInfoState={props.setModalExtractionInfoState}
              setModalNewsState={props.setModalNewsState}
              setMenuState={props.setMenuState}
              colorHover={"#fdad17"}
            />
          )
        }
      </SecondRow>
    </HeaderContainer>
  )
}

export default HeaderSession


const HeaderContainer = styled.div`
  width: 90%;
  height: 20%;

  /* background-color: green; */

  /* border-bottom: solid 1px #707070; */

  margin: 3% auto auto;

  display: grid;
  grid-template-rows: 1fr 1fr;
`
const FirstRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  width: 100%;
  margin-bottom: 5px;
  align-items: center;
`

const LeftCollumn = styled.div`
  display: flex;
  align-items: center;
  visibility: ${(props) => props.visibility ? props.visibility : 'visible'};
`

const RightCollumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  justify-items: right;
`

const SecondRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.5em;

  align-items: center;

`
