import React from 'react'

import { Table, TableHead, TableBody, TableRow, TableHeadData, TableData, TableRowHeader, IMG } from './style'

import sadIcon from '../../images/sadIcon.svg'
import neutraulIcon from '../../images/neutralIcon.svg'
import happyIcon from '../../images/happyIcon.svg'

function FeedbackTable(props) {

    function feceFeedback(faceIcon) {
        switch (faceIcon) {
            case "1":
                return sadIcon;
            case "2":
                return neutraulIcon;
            case "3":
                return happyIcon;
            default:
                break;
        }
    }

    return (
        <Table>
            <TableHead>
                <TableRowHeader>
                    <TableHeadData style={{ textAlign: 'center' }}></TableHeadData>
                    <TableHeadData style={{ textAlign: 'left' }}>TÍTULO</TableHeadData>
                    <TableHeadData style={{ textAlign: 'left' }}>EMAIL</TableHeadData>
                    <TableHeadData style={{ textAlign: 'left' }}>DATA</TableHeadData>
                </TableRowHeader>
            </TableHead>
            <TableBody>
                {
                    props.feedback.map((feedback) => (
                        <TableRow
                            key={feedback.id}
                            setMenuState={props.setMenuState}
                            setClicked={props.setClicked}
                            clicked={props.clicked}
                            feedback={feedback}
                            setModalFeedbackState={props.setModalFeedbackState}
                            setModalFeedbackData={props.setModalFeedbackData}

                            onClick={() => {
                                props.setMenuState(false)
                                props.setModalFeedbackState(true)
                                props.setModalFeedbackData(feedback)
                                props.setClicked(feedback.id)
                            }}
                            
                            className={props.clicked === feedback.id ? 'active' : ''}>
                            <TableData><IMG src={feceFeedback(feedback.faceFeedback)} /></TableData>
                            <TableData>{feedback.option}</TableData>
                            <TableData>{feedback.author}</TableData>
                            <TableData>
                                {
                                    new Date(feedback.createdAt).toLocaleDateString("pt-BR")
                                }
                            </TableData>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    )
}

export default FeedbackTable;