import React from "react";

import successIcon from '../../../images/successIcon.png'
import disableIcon from '../../../images/disableIcon.svg'

import { Container, ContainerModal, Button, Text, Img } from "./style"
import Loading from "../../Loading";
import { useNavigate } from "react-router-dom";

const ModalCsHelper = ({ showModal, setShowModal, setIsError, isSending, isError, isSuccess }) => {
  const navigate = useNavigate()

  return (
    <>
      {showModal ?
          <Container>
            <ContainerModal>
              {
                isSuccess ? 
                <>
                  <Img src={successIcon} alt="Icone de sucesso" />
                  <Text>
                    Suas respostas foram enviadas com sucesso!
                  </Text>
                  <Button onClick={() => {
                     setShowModal(prev => !prev)
                     navigate("/homeNews")
                  }}>
                    Ok
                  </Button>
                </>
                : ""
              }

              {
                isSending ? 
                <>
                  <Loading />
                  <Text>
                    Aguarde! Suas respostas estão sendo enviadas.
                  </Text>
                </>
                : ""
              }

              {
                isError ?
                <>
                  <Img src={disableIcon} alt="Icone de erro" />

                  <Text>
                    Algo deu errado 
                  </Text>

                  <Button onClick={() => {
                     setShowModal(prev => !prev)
                     navigate("/cshelper")
                     setIsError(false)
                  }}>
                    Voltar
                  </Button>
                </>
                : ""
              }
            </ContainerModal>
          </Container>

        : null}
    </>
  )
}

export default ModalCsHelper