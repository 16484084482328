import styled from "styled-components";

import sadIcon from '../../../images/sadIcon.svg'
import sadHoverIcon from '../../../images/sadHoverIcon.svg'

import happyIcon from '../../../images/happyIcon.svg'
import happyHoverIcon from '../../../images/happyHoverIcon.svg'

import neutralIcon from '../../../images/neutralIcon.svg'
import neutralHoverIcon from '../../../images/neutroHoverIcon.svg'

export const Container = styled.div`
    width: 600px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    border-radius: 8px;

    -webkit-box-shadow: 11px 22px 30px -3px rgba(36,36,36,0.85);
    -moz-box-shadow: 11px 22px 30px -3px rgba(36,36,36,0.85);
    box-shadow: 11px 22px 30px -3px rgba(36,36,36,0.85);

    @media (max-width: 1500px){
        width: 500px;
        height: 59vh;
        margin-top: 15px;
        padding: 10px;
    }
`

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


label{
  font-family: 'Space Grotesk';
  font-style: normal;
  font-size: 20px;
  line-height: 40px;
  color: #FFFFFF;

}

#message{
  border: none;
  border-radius: 5px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  resize: none;
  flex-direction: row;
  height: 8em;
  width: 30em;
  color: white;
  background: #242424;
  overflow-y: auto;
  margin-bottom: 25px;
}

@media (max-width: 1500px){
  label {
    font-size: 16px;
  }

  input, #message {
    width: 25em;
  }
}
`

export const Input = styled.input`
  height: 40px;
  width: 20em;
  background: #242424;
  color: white;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;

  border-radius: 5px;
  border:none;    

@media (max-width: 1500px){
  input, #message {
    width: 25em;
  }
}
`

export const Button = styled.button`
  font-family: 'Space Grotesk';
  font-style: normal;
  font-size: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 14px;
  gap: 12px;
  text-align: center;
  cursor: pointer;
  width: 40%;
  justify-content:center;
  height: 32px;

  /* secondary/500 */

  background: #F6BE27;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;   
  border: none;

  &:hover{
    background-color: #fdad17;
    transition: 0.5s;
  }

  @media (max-width: 1500px){ 
    width: 20%;
    height: 22px;
    font-size: 16px;
    margin-bottom: 25px;
  }
`
export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px; 
`

export const InputSad = styled.input`
  display: none;
`

export const ButtonIconSad = styled.label`
  display: block;
  background-color: transparent;
  border: none;
  margin-bottom: 20px;
  cursor: pointer;
  background-image: url(${sadIcon});
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  
  &.active {
    background-image: url(${sadHoverIcon});
    background-repeat: no-repeat;
  } 

  @media (max-width: 1500px){ 
    margin-bottom: 0px;
    margin-top: 20px;
  }
`

export const ButtonIconeNeutral = styled.label`
  background-color: transparent;
  border: none;
  margin-bottom: 20px;
  cursor: pointer;
  background-image: url(${neutralIcon});
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;

  &.active {
    background-image: url(${neutralHoverIcon});
    background-repeat: no-repeat;
  } 

  @media (max-width: 1500px){ 
    margin-bottom: 0px;
    margin-top: 20px;
  }
`

export const ButtonIconeHappy = styled.label`
  background-color: transparent;
  border: none;
  margin-bottom: 20px;
  cursor: pointer;
  background-image: url(${happyIcon});
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;

  &.active {
    background-image: url(${happyHoverIcon});
    background-repeat: no-repeat;
  } 

  @media (max-width: 1500px){ 
    margin-bottom: 0px;
    margin-top: 20px;
  }
`


export const Radio = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  gap: 15px;

  #inputRadio {
    width: 17px;
  }

  #labelRadio {
    padding-top: 5px;
  }

`

