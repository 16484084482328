import React, { useContext, useState, useEffect } from "react";

import { useForm, Controller } from "react-hook-form";
import API from "../../../services/api";
import { AuthContext } from "../../../contexts/AuthProvider";

import facebookMetrics from "../../../services/facebookMetrics.json";

import { Form } from "./style";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

export default function FBExtractor(props) {
  const { currentUser } = useContext(AuthContext);
  const api = new API(currentUser);

  const dateExpiration = new Date();
  dateExpiration.setMonth(dateExpiration.getMonth() + 6);

  const breakdownfb = facebookMetrics.breakdowns;
  const actionBreakdownfb = facebookMetrics.action_breakdown;
  const fieldsfb = facebookMetrics.fields;

  const fixedOptions = [fieldsfb[59]];
  const [fields, setFields] = useState([...fixedOptions]);
  const [clearBreakDowns, setClearBreakDowns] = useState([]);
  const [clearActionBreakdown, setClearActionBreadown] = useState([]);
  const [typeReport, setTypeReport] = useState("");
  const [preDefReport, setPreDefReport] = useState([]);
  const { register, handleSubmit, reset, control, setValue } = useForm({
    defaultValues: {
      customers: "",
      level: "",
      accountId: "",
      nomeExtraction: "",
      date: "",
      description: "",
      consolidationWindow: "",
    },
  });
  useEffect(() => {
    if (props.preDefExtraction !== []) {
      const extractionFiltered = props.preDefExtraction?.filter(
        (extraction) => extraction.name === typeReport
      );
      setPreDefReport(extractionFiltered);
    }
  }, [typeReport]);
  useEffect(() => {
    if (preDefReport.length !== 0) {
      try {
        setFields(preDefReport[0].queryParams.fields);
        setClearBreakDowns(preDefReport[0].queryParams.breakdowns);
        setValue("level", preDefReport[0].queryParams.level);
        setClearActionBreadown(preDefReport[0].queryParams.action_breakdowns);
      } catch {}
    }
  }, [preDefReport]);
  function completeExtraction() {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );

    Array.from(document.querySelectorAll("select")).forEach(
      (option) => (option.value = "")
    );

    Array.from(document.querySelectorAll("textarea")).forEach(
      (textarea) => (textarea.value = "")
    );

    reset();
    clearSelected();
  }

  function platformPayload(bodyFB) {
    return bodyFB;
  }

  function formatWords(word) {
    const wordFormat = word
      .normalize("NFD")
      .replace(/ /g, "_")
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z-_])/g, "")
      .replace("-", "_")
      .toLowerCase();
    return wordFormat;
  }

  const sendExtraction = (event) => {
    props.setModalController("create extraction");

    const bodyFB = {
      metadata: {
        extractionName: formatWords(event.nomeExtraction),
        author: currentUser.email,
        customer: formatWords(event.customers),
        creationDate: new Date().toISOString(),
        expirationDate: dateExpiration.toISOString().slice(0, 10),
        consolidationWindow: Number(event.consolidationWindow),
        precisionInterval: 30,
        extractorModule: "fbExtractor",
        startDate: event.date,
      },
      queryParams: {
        accountId: `act_${event.accountId}`,
        breakdowns: clearBreakDowns,
        action_breakdowns: clearActionBreakdown,
        fields: fields,
        level: event.level,
      },
      destination: {
        projectId: process.env.REACT_APP_DEFAULT_PROJECT_BQ,
        datasetId: formatWords(event.customers),
        tableId: `${"fbExtractor"}-${formatWords(
          event.customers
        )}-${formatWords(event.nomeExtraction)}`,
        description: event.description,
      },
    };
    api
      .createDailyExtraction(platformPayload(bodyFB))
      .then(() => {
        props.setModalController("create extraction complete");
        completeExtraction();
      })
      .catch((error) => {
        if (error.request.status === 400) {
          props.setNewError(error.data.error);
          props.setModalController("error campo");
        } else {
          props.setModalController("error report");
        }
      });
  };
  function handlePreDef(e) {
    e.preventDefault();
    setTypeReport(e.target.value);
  }
  const clearSelected = () => {
    setFields([]);
    setClearBreakDowns([]);
    setClearActionBreadown([]);
  };

  return (
    <Form onSubmit={handleSubmit(sendExtraction)}>
      <label htmlFor="preDef">Relatórios Predefinidos</label>
      <select id="preDef" required onChange={handlePreDef} value={typeReport}>
        <option value="">Selecione o tipo de relatório</option>
        <option value="personalizado">Relatório personalizado</option>
        {props?.preDefExtraction &&
          props.preDefExtraction.map((option) => {
            return (
              <option key={option.id} value={option.name}>
                {" "}
                {option.name}{" "}
              </option>
            );
          })}
      </select>
      <label htmlFor="level">Level</label>
      <select id="level" required {...register("level")}>
        <option value="">selecione ad/campaign</option>
        <option value="ad">ad</option>
        <option value="campaign">campaign</option>
      </select>

      <label htmlFor="cliente">Cliente</label>
      <select id="cliente" {...register("customers")} required selected>
        <option value="">Escolha o nome do cliente.</option>
        {props.clients !== undefined &&
          props.clients.map((option) => {
            return (
              <option key={option.id} value={option.name}>
                {" "}
                {option.name}{" "}
              </option>
            );
          })}
      </select>

      <label htmlFor="accountId">{"Account Id"}</label>
      <input
        required
        id="accountId"
        placeholder={"Ex: 123456789"}
        name="accountId"
        {...register("accountId")}
      />

      <label htmlFor="nomeExtraction">Nome da extração</label>
      <input
        required
        id="nomeExtraction"
        placeholder="Ex: Cliente_productPerformance"
        name="nomeExtraction"
        {...register("nomeExtraction")}
      />

      <label htmlFor="consolidationWindow">Janela de consolidação</label>
      <select
        id="consolidationWindow"
        required
        {...register("consolidationWindow")}
      >
        <option value="">Selecione a janela de consolidação.</option>
        <option value={45}>45 dias</option>
        <option value={30}>30 dias</option>
        <option value={15}>15 dias</option>
        <option value={7}>7 dias</option>
        <option value={4}>4 dias</option>
        <option value={2}>2 dias</option>
      </select>

      <Stack spacing={2}>
        <label htmlFor="actionBreakdown">Action Breakdown</label>
        <Controller
          control={control}
          name="actionBreakdown"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="actionBreakdown"
              multiple
              options={actionBreakdownfb ?? []}
              value={clearActionBreakdown ?? []}
              onChange={(event, values) => {
                onChange(values);
                setClearActionBreadown([...values]);
                if (typeReport !== "") {
                  setTypeReport("personalizado");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{ root: "MuiTextField-root" }}
                  placeholder="Adicione seus Actions Breakdowns"
                  onChange={onChange}
                />
              )}
            />
          )}
        />

        <label htmlFor="breakdown">Breakdown</label>
        <Controller
          control={control}
          name="breakdowns"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="breakdown"
              multiple
              options={breakdownfb}
              value={clearBreakDowns}
              onChange={(event, values) => {
                onChange(values);
                setClearBreakDowns([...values]);
                if (typeReport !== "") {
                  setTypeReport("personalizado");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{ root: "MuiTextField-root" }}
                  placeholder="Adicione seus breakdowns"
                  onChange={onChange}
                />
              )}
            />
          )}
        />

        <label htmlFor="fields">Fields</label>
        <Controller
          control={control}
          name="fields"
          render={({ field: { onChange, values } }) => (
            <Autocomplete
              multiple
              id="fields"
              required
              value={fields}
              onChange={(event, newValue) => {
                onChange(newValue);
                setFields([
                  ...fixedOptions,
                  ...newValue.filter(
                    (option) => fixedOptions.indexOf(option) === -1
                  ),
                ]);
                if (typeReport !== "") {
                  setTypeReport("personalizado");
                }
              }}
              options={fieldsfb}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option}
                    {...getTagProps({ index })}
                    disabled={fixedOptions.indexOf(option) === 0}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{ root: "MuiTextField-root" }}
                  placeholder="Adicione seus fields"
                  onChange={onChange}
                />
              )}
            />
          )}
        />
      </Stack>

      <label htmlFor="date">Início da extração:</label>
      <input
        type="date"
        id="date"
        required
        max={new Date().toISOString().split("T")[0]}
        name="date"
        {...register("date")}
      />

      <label htmlFor="description">Descrição da extração</label>
      <textarea
        minLength={10}
        id="description"
        name="description"
        placeholder="Descreva brevemente sua extração."
        required
        wrap="true"
        {...register("description")}
      />

      <button type="submit">Enviar Extração</button>
    </Form>
  );
}
