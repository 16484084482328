function processCSHelperCoordAnswers(currentArea) {
    const data = JSON.parse(window.localStorage.getItem("coords_forms_answers"))
    let processed = []
    const head = Object.keys(data)[0]

    for(let area in data[head]) {
            for(let coord in data[head][area]) {
                const auxObj = {
                    "date": new Date().toISOString().slice(0,10),
                    "head": head,
                    "area": area,
                    "coordenador": coord,
                }

                const answers = Object.keys(data[head][area][coord])
                
                answers.forEach(answer => {
                    auxObj[answer] = data[head][area][coord][answer][0]
                    auxObj[`comentario_${answer}`] = data[head][area][coord][answer][1]
                })

                if(auxObj.time_esta_bem !== "") {
                    processed.push(auxObj)
                }
            }
        }
    
    return processed
}

export default processCSHelperCoordAnswers