
import styled from 'styled-components'
import Logo from '../images/plusIcon.svg'


function ButtonExtraction(props) {
  return (
    <Button onClick={() => {
      if (props.setModalNewsState) {
        props.setModalNewsState(false)
      }
      if (props.setModalExtractionInfoState) {
        props.setModalExtractionInfoState(false)
      }
      props.setMenuState(false)
      props.setModalExtractionFormState(true)
      props.setClicked('');
    }} colorHover={props.colorHover}>
      <LogoSrc src={Logo} />
      Nova Extração
    </Button>
  );
}

ButtonExtraction.defaltProps = {
  setModalNewsState: '',
  setModalExtractionInfoState: ''
}

export default ButtonExtraction;

const Button = styled.button`
  /* Auto layout */
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 14px;
  gap: 12px;
  
  width: 170px;
  height: 40px;
  
  background: #F6BE27;
  border-radius: 4px;
  
  /* Text Button */
  font-family: 'HelveticaNowforMonksMedium', sans-serif;
  font-size: 1em;
  color: #242424;

  cursor: pointer;

  &:hover {
      background-color: ${(props) => (props.colorHover ? props.colorHover : "#0d5eff")};
      transition: 0.5s;
  }

  @media(max-width: 1300px) {
    width: 130px;
    height: 30px;

    font-size: 0.65em;
  }

`
const LogoSrc = styled.img`
  left: 0%;
  width: 15%;
`