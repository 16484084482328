import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/firebase";

class TiktokTokensRef {
  constructor() {
    this.tiktokTokensRef = {};
  }

  async getAll() {
    const docsRef = collection(db, "voldemortTiktokTokens");
    const snapshot = await getDocs(docsRef);

    snapshot.forEach((doc) => {
      this.documentationRefs = doc.data();
    });

    return this.documentationRefs;
  }
}

export { TiktokTokensRef };
